import React, { useEffect, useState } from "react";
import "./index.css";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import editDetails from "../../../../assets/Settings/Entity Details/Edit details.png";
import mapRole from "../../../../assets/Settings/Entity Details/Edit roles.svg";
import autoPassword from "../../../../assets/Settings/Entity Details/Autopassowrd.svg";
import setPassword from "../../../../assets/Settings/Entity Details/Setpassword.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificUser } from "../../../../redux/features/Settings/Management/Users";
import formatDate from "../../../../utils/formatdate";
import { getUserDetailsStateAction } from "../../../../redux/features/users/userDetails";
import { checkUsertoken } from "../../../../redux/features/login/login";
import notFoundImage from "../../../../assets/No transaction found.svg";

const UserDetails = () => {
  const navigate = useNavigate();
  const getUserDetailsState = useSelector((state) => state?.getUserDetailsinfo);
  const { id } = useParams();

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Details");

  const { specificUserData, specificUserLoading, specificUserError } =
    useSelector((state) => state?.users);

  let userDetailsData =
    specificUserData?.status === true
      ? specificUserData?.employees[0]
      : getUserDetailsState?.status &&
        id == getUserDetailsState?.userInfo[0]?.id
      ? getUserDetailsState?.userInfo[0]
      : undefined;

  useEffect(() => {
    let checkUserId = getUserDetailsState?.userInfo[0]?.id;

    if (
      userDetailsData == undefined &&
      id != checkUserId &&
      getUserDetailsState?.status
    ) {
      navigate("*");
    }
  }, [userDetailsData, specificUserData, getUserDetailsState]);

  useEffect(() => {
    const getUser = async () => {
      try {
        await dispatch(getSpecificUser(id));
      } catch (error) {
        console.error(error);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    let getUserDetailsStateStorage = JSON.parse(
      sessionStorage.getItem("userDetailsInfostorage")
    );
    dispatch(checkUsertoken());
    dispatch(getUserDetailsStateAction(getUserDetailsStateStorage));
  }, []);

  const handleTabClick = (e) => {
    setSelectedTab(e.target.innerHTML);
  };

  // const servicesMap = new Map();

  // specificUserData?.user_services?.forEach((service) => {
  //   if (!servicesMap.has(service.service_header)) {
  //     servicesMap.set(service.service_header, []);
  //   }
  //   servicesMap.get(service.service_header).push(service.service_name);
  // });

  // const featuresMap = new Map();

  // specificUserData?.user_features?.forEach((feature) => {
  //   if (!featuresMap.has(feature.feature_description)) {
  //     featuresMap.set(feature.feature_description, []);
  //   }
  //   featuresMap.get(feature.feature_description).push(feature.feature_name);
  // });

  //same as above by reduce method :P
  const servicesMap = specificUserData?.user_services?.reduce((acc, curr) => {
    if (!acc[curr?.service_header]) {
      acc[curr?.service_header] = [];
    }
    acc[curr?.service_header].push(curr?.service_name);

    return acc;
  }, {});

  const featuresMap = specificUserData?.user_features?.reduce((acc, curr) => {
    if (!acc[curr?.feature_description]) {
      acc[curr?.feature_description] = [];
    }
    acc[curr?.feature_description].push(curr?.feature_name);

    return acc;
  }, {});

  const rolesMap = specificUserData?.user_roles?.reduce((acc, curr) => {
    if (!acc[curr?.role_desc]) {
      acc[curr?.role_desc] = [];
    }
    acc[curr?.role_desc].push(curr?.role_name);

    return acc;
  }, {});

  return (
    <div className="user-main-div">
      {!userDetailsData ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <>
          <div className="user-div-fixed">
            <div className="user-container" id="container">
              <div className="user-acc-section">
                <div className="user-major-div">
                  <div className="user-individual-person-detail">
                    <div className="user-individual-name">
                      <div className="user-account-name">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={
                            userDetailsData?.first_name +
                            " " +
                            userDetailsData?.last_name
                          }
                          placement="bottom-start"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                            }}
                          >
                            {userDetailsData?.first_name +
                              " " +
                              userDetailsData?.last_name}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="user-acc-name-label">User name</div>
                    </div>
                    <div className="user-acc-detail">
                      <div className="user-acc-content">
                        <div className={`user-account-status-div`}>
                          {userDetailsData?.user_id}
                        </div>
                        <div className="user-acc-name-label">User ID</div>
                      </div>
                    </div>
                  </div>
                  <div className="user-account-holder-details">
                    <div className="user-individual-name">
                      <span className="text-white text-sm">
                        {userDetailsData?.grade}
                      </span>
                      <span className="user-acc-number-label text-xs">
                        Grade
                      </span>
                    </div>
                    <div className="user-bank-code">
                      <div className="user-acc-content">
                        <div className="user-account-name w-full">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={userDetailsData?.alias_name || ""}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "block",
                              }}
                            >
                              {userDetailsData?.alias_name || "N/A"}
                            </div>
                          </Tooltip>
                        </div>
                        <span className="user-acc-number-label text-xs">
                          Alias
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-bank-detail">
                    <div className={`user-individual-name`}>
                      <span
                        className={`text-white text-sm  ${
                          userDetailsData?.active_status == "Y"
                            ? `user-active-status`
                            : `user-inactive-status`
                        }`}
                      >
                        {userDetailsData?.active_status == "Y"
                          ? "Active"
                          : "Inactive"}
                      </span>
                      <span className="user-acc-number-label text-xs">
                        Status
                      </span>
                    </div>
                    <div className="user-acc-holder-name"></div>
                  </div>
                </div>

                <div className="user-minor-div">
                  <div className="user-recipient flex flex-row bg-[#506994]">
                    <div className="w-1/2 flex-grow bg-[#3a5686]"></div>
                    <div className="flex-grow w-1/2">
                      <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1  p-2">
                        <div className="user-recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={editDetails}
                              alt="document"
                              className="user-recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate...">
                            Edit details
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>

                  <div className="user-recipient flex flex-row bg-[#506994]">
                    <div className="w-1/2 flex-grow bg-[#3a5686]"></div>
                    <div className="flex-grow w-1/2">
                      <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1  p-2">
                        <div className="user-recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={mapRole}
                              alt="document"
                              className="user-recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate...">
                            Map roles
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>

                  <div className="user-recipient-noborder flex flex-row bg-[#506994]">
                    <div className="flex-grow w-1/2">
                      <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1  p-2">
                        <div className="user-recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={setPassword}
                              alt="document"
                              className="user-recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate...">
                            Set new password
                          </Box>
                        </div>
                      </Box>
                    </div>

                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    <div className="flex-grow w-1/2">
                      <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1  p-2">
                        <div className="user-recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img src={autoPassword} alt="document" />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate...">
                            Auto generate password
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="user-div-auto">
            <div className="user-detail-section">
              <div className="user-multi-button">
                <div>
                  <button
                    className={`user-detail-button ${
                      selectedTab === "Details" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Details
                  </button>
                </div>

                <div
                  style={{
                    display: specificUserData?.status ? "flex" : "none",
                  }}
                >
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-[#A3A3A3]"} ${
                        selectedTab === "Roles" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Roles
                    </button>
                  </div>
                  <div className="splitline-div user-detail-button"></div>
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-[#A3A3A3]"} ${
                        selectedTab === "Features" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Features
                    </button>
                  </div>
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-[#A3A3A3]"} ${
                        selectedTab === "Services" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Services
                    </button>
                  </div>
                </div>
              </div>
              {selectedTab === "Details" && (
                <div className="user-service-card-row grid grid-cols-4 gap-10">
                  <div className="user-contact-detail-card w-1/4">
                    <div>
                      <h4 className="user-title">Contact Details</h4>
                      <div>
                        <div>
                          <div className="font-sans text-sm text-[#FFFFFF]">
                            +91 {userDetailsData?.mob_no}
                          </div>
                        </div>
                        <div className="user-phone">Contact number</div>
                      </div>
                    </div>
                    <div className="user-email-section">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={userDetailsData?.email_id}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <div
                          id="email-id-firstLetter"
                          className="user-email-id-div"
                          style={{
                            // marginTop: "10px",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textTransform: "lowercase",
                          }}
                        >
                          {userDetailsData?.email_id}
                        </div>
                      </Tooltip>
                      <div className="user-email">Email ID</div>
                    </div>
                  </div>
                  <div className="user-contact-detail-card w-1/4">
                    <div>
                      <h4 className="user-title">Origin Timeline</h4>
                      <div>
                        <div>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={userDetailsData?.created_by.toLowerCase()}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <div
                              className="font-sans overflow-hidden
                              whitespace-nowrap
                              overflow-ellipsis text-sm text-[#FFFFFF] crt-by-text"
                            >
                              {userDetailsData?.created_by.toLowerCase()}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="user-phone">Created by</div>
                      </div>
                    </div>
                    <div className="user-email-section">
                      <div
                        className="user-email-id-div"
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDate(userDetailsData?.created_on)}
                      </div>
                      <div className="user-email">Created on</div>
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === "Services" && (
                <div className="services-div">
                  {Object.entries(servicesMap).length > 0 ? (
                    Object.entries(servicesMap).map(([header, values]) => (
                      <div key={header}>
                        <div className="services-tab-header">
                          <h5>{header}</h5>
                        </div>
                        <div className="services-tab-div">
                          {values.map((service) => (
                            <div className="services-tab-card" key={service}>
                              {service}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No services assigned to this user </p>
                    </div>
                  )}
                </div>
              )}

              {selectedTab === "Roles" && (
                <div className="roles-div">
                  {Object.entries(rolesMap).length > 0 ? (
                    Object.entries(rolesMap).map(([header, values]) => (
                      <div className="roles-tab-div">
                        {values.map((roles) => (
                          <div className="roles-tab-card" key={roles}>
                            {roles}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No roles assigned to this user </p>
                    </div>
                  )}
                </div>
              )}
              {selectedTab === "Features" && (
                <div className="features-div">
                  {Object.entries(featuresMap).length > 0 ? (
                    Object.entries(featuresMap).map(([header, values]) => (
                      <div key={header}>
                        <div className="features-tab-header">
                          <h5>{header}</h5>
                        </div>
                        <div className="features-tab-div">
                          {values.map((feature) => (
                            <div className="features-tab-card" key={feature}>
                              {feature}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No feature assigned to this user </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
