import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../../../components/LandingPage/Navbar";
import Footer from "../../../../components/LandingPage/Footer";
import { toast_position } from "../../../../redux/features/Settings/Authorization";

const BankStatement = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(calc(50% - ${
      currentIndex * (280 + 20) + 140
    }px))`;
    setPausedTransform(currentTransform);
    // alert("hii");
  };
  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
    // alert("hello");
  };

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: isDarkSectionActive ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <Navbar onToggleDarkSection={setIsDarkSectionActive} />
              <div className="p-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1f3969]"></div>

              <div className="p-[30px] bg-[#031f4f] flex flex-col gap-4"></div>

              <div
                className="py-5 px-[30px] sm:px-[30px]"
                style={{
                  background:
                    "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
                }}
              ></div>

              <div className="bg-[#031f4f] py-[30px]"></div>

              <Footer />
            </div>
          </div>
        ) : (
          <>
            <Navbar />
            <div className="flex relative mt-[60px] bg-[#1f3969]">
              <div className="h-[492px] max-w-[1440px] w-screen flex justify-between mx-auto relative"></div>
            </div>

            <div className="bg-[#031F4F] py-[30px] px-[171px]"></div>

            <div
              className="bg-transparent m-0 p-0 text-[28px] pt-[20px] pb-[30px] text-[#F9F9F9]"
              style={{
                background:
                  "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            ></div>

            <div
              style={{
                background:
                  "transparent linear-gradient(270deg, #031F4F 0%, #224074 50%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div className="max-w-[1024px] mx-auto py-[20px] flex flex-col gap-2.5">
                <div className="flex flex-col mt-[20px] border-[1px] border-[#31E5E5] rounded-[20px]"></div>
              </div>
            </div>

            <div className="bg-[#031F4F] pt-[35px]"></div>

            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default BankStatement;
