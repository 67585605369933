import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const DatePickerComponent = forwardRef(
  (
    {
      startDate,
      endDate,
      handleDateChange,
      handleCancel,
      handleConfirm,
      showDatePicker,
      categorydropdownRef,
      dateRef,
      setShowDatePicker,
      owner,
    },
    ref
  ) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     console.log("RUNS!!!");
    //     if (dateRef.current && !dateRef.current.contains(event.target)) {
    //       setShowDatePicker(false); // Close date picker when clicking outside
    //       event.stopPropagation();
    //     }
    //   };

    //   document.addEventListener("mousedown", handleClickOutside);

    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [showDatePicker]);
    const dayClassName = (date) => {
      if (
        startDate &&
        new Date(date).getTime() === new Date(startDate).getTime()
      ) {
        return "start-date";
      }
      if (endDate && new Date(date).getTime() === new Date(endDate).getTime()) {
        return "end-date";
      }
      return "";
    };

    if (!showDatePicker) {
      return null;
    }

    return (
      <div
        style={{
          borderRadius: owner ? "0px 0px 10px 10px" : "10px",
          width: owner ? "298px" : "100%",
          borderLeft: owner ? "1px solid #ff5860" : "",
          borderRight: owner ? "1px solid #ff5860" : "",
          borderBottom: owner ? "1px solid #ff5860" : "",
        }}
        className="date-picker-container"
      >
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            handleDateChange(date, "start");
          }}
          onMonthChange={(date) => {
            setSelectedMonth(new Date(date).getMonth());
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          showYearDropdown
          maxDate={new Date()}
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          inline
          startDateClassName="selected-start-date"
          endDateClassName="selected-end-date"
          dayClassName={dayClassName}
          // ref={dateRef} // Attach the ref here
        />

        <div className="calendar_buttons px-5">
          <button
            onClick={() => {
              handleCancel();
            }}
            className="calendar_cancel_button hover:opacity-80"
            style={{
              cursor: startDate || endDate ? "pointer" : "default",
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              if (startDate && endDate) {
                handleConfirm();
              }
            }}
            style={{
              backgroundColor: startDate && endDate ? "#f05a6a" : "#727272",
              cursor: startDate && endDate ? "pointer" : "default",
            }}
            className="calendar_confirm_button hover:opacity-80"
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
);

export default DatePickerComponent;
