import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import aprovaldenied from "../../../assets/Beneficiary Icons/reddecline.png";
import aprovalaccept from "../../../assets/Beneficiary Icons/WhiteApprove.png";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import NoAccountFound from "../../../assets/Beneficiary Icons/no_account_approved.png";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import BeneTxnDataFormat from "../../../utils/BeneTxnDataFormat.js";
import { formatTime } from "../../../utils/formatTime.js";
import ArrowLeft from "../../../assets/Approve transactions/BeneTxn_ArrowLeft.png";
import FiterNoAccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { useDispatch, useSelector } from "react-redux";
import DeclinedPopup from "../../Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import ArrowRight from "../../../assets/Beneficiary Icons/BeneTxnArrowRight.png";
import ReloadWhite from "../../../assets/Approve transactions/Reload white.png";
import closeBtnImg from "../../../assets/CategoryFilterIcons/cross.png";
import WhiteArrowRight from "../../../assets/Approve transactions/WhiteArrow_right.png";
import BeneTxn_Queued from "../../../assets/Approve transactions/Queued.png";
import DeclinedRed from "../../../assets/Approve transactions/Declined red.png";
import Approvalgreen from "../../../assets/Approve transactions/Approved green.png";
import whiteLoader from "../../../assets/Approve transactions/WhiteLoaderImages.png";
import pendingImages from "../../../assets/Beneficiary Icons/pending_yellow.png";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BeneTxnRequireApprovalPage,
  beneTxnAccountDetail,
  beneTxnWaitingApproval,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { viewAccountDetail } from "../../../redux/features/verifyPAN/verifyPAN.js";
import {
  getOwnerAccBalance,
  setshowsuccestoast,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification.js";
import BalanceLoader from "../../../components/_utils/BalanceLoader/index.jsx";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount.js";
import SelectCategoryTransaction from "../../../components/_utils/SelectCategoryTransaction/index.jsx";
import { Box, tooltipClasses, Tooltip } from "@mui/material";
import GridBlue from "../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../assets/OwnerAccount/gridview-white.png";
import ListBlue from "../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import ArrowUp4 from "../../../assets/OwnerAccount/arrowdown4png.png";
import ArrowDown from "../../../assets/OwnerAccount/arrowdown1.png";
import ArrowUp from "../../../assets/OwnerAccount/arrowup.png";
import ArrowDown8 from "../../../assets/OwnerAccount/arrowdown8.png";
import FilterBlue from "../../../assets/OwnerAccount/filterblue.png";
import Filter from "../../../assets/ViewAllTransactions/filter.png";
import SkipVerification from "../../../assets/Approve transactions/DeclinedTxn_red.png";
import RefreshBalanceToast from "../../../components/_utils/RefreshBalanceToast/index.jsx";
import BeneTxnSideDrawer from "../../../components/SideDrawer/index.jsx";
import { checkUsertoken } from "../../../redux/features/login/login.js";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper/index.js";
import AnimationUpAndDown from "../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown/index.jsx";

const BeneTxnRequireApproval = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState({});
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState([]);
  const [refreshId, SetrefreshId] = useState("");
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : 50
  );
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterCat, setFilterCat] = useState(false);
  const [PrevBalance, setPrevBalance] = useState(null);
  const [initCategory, setInitCategory] = useState("");
  const [toggle, setToggle] = useState(true);
  const [label, setlabel] = useState("Select category");

  const [currentCategory, setCurrentCategory] = useState("");
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [ShowFilterErrorPage, setShowFilterErrorPage] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const filterRef = useRef(null);

  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const navigate = useNavigate();
  const { BeneTxnRequireApprovalData } = useSelector(
    (state) => state.beneTxnViewAccData
  );
  useEffect(() => {
    if (page === 1 && filterCat === false) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    if (label === "Select category") {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: label !== "Select category" ? label : undefined,
        })
      )
        .then((response) => {
          setTotalPages(response?.payload?.title?.total_pages);
          setAllBeneTxnAccounts((prevAccounts) => {
            const newData =
              (response?.payload?.transactions &&
                response?.payload?.transactions?.map((account) => ({
                  ...account,
                  loading: false,
                  error: "false",
                  message: "",
                  last_updated_user_id_check: "false",
                  distribution_lifecycle_status: "",
                }))) ||
              [];
            return [...prevAccounts, ...newData];
          });
          setAllBeneTxnAccountsLoading(false);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    } else if (label !== "Select category" && page !== 1) {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: label !== "Select category" ? label : undefined,
        })
      )
        .then((response) => {
          setTotalPages(response?.payload?.title?.total_pages);
          setAllBeneTxnAccounts((prevAccounts) => {
            const newData =
              (response?.payload?.transactions &&
                response?.payload?.transactions?.map((account) => ({
                  ...account,
                  loading: false,
                  error: "false",
                  message: "",
                  last_updated_user_id_check: "false",
                  distribution_lifecycle_status: "",
                }))) ||
              [];
            return [...prevAccounts, ...newData];
          });
          setAllBeneTxnAccountsLoading(false);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    }
  }, [page]);
  useEffect(() => {}, [BeneTxnRequireApprovalData]);

  const handleOpenPopup = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();
      }, 150);
    }
  }, [ownerAccBalanceLoading]);

  const handleMultiViewClick = () => {
    navigate("/transaction/Beneficiary_Transaction/multi_transaction");
  };

  const handleRefresh = async (e, id, prevBalance) => {
    e.stopPropagation();
    setrefreshAnimation(id);
    setPrevBalance(prevBalance);
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));

      if (response?.payload?.status === true) {
        let data = AllBeneTxnAccounts?.map((item) => {
          if (
            response?.payload.owner_accounts[0].owner_account_id ===
            item.owner_account_id
            // &&
            // item.txn_id === txn_id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response.payload.owner_accounts[0].owner_account_balance,

              owner_account_balance_last_fetched_at:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });
        setAllBeneTxnAccounts(data);
        setTimeout(() => {
          toast.success("Balance refreshed successfully", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setshowsuccestoast());
        }, 250);
      } else {
        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const handleApprovalToastSuccess = () => {
    toast.success("Transaction approved successfully", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };

  const SideDrawerOpen = async (status, id, txnId) => {
    SetDrawerStatus(status);

    setisDrawerOpen(!isDrawerOpen);
    dispatch(checkUsertoken());
    await dispatch(viewAccountDetail({ id }));
    await dispatch(beneTxnAccountDetail(txnId));
  };
  const updateAllAccounts = (updatedAccounts) => {
    setAllBeneTxnAccounts(updatedAccounts);
    // SetstatustxnApproval("TxnReQuiredApproval");
  };
  const viewTxnNavigate = (id) => {
    navigate(
      `/transaction/beneficiary_transaction/details/${id}`
      // , {
      // state: totalPages,
      // }
    );
  };
  const loginid = JSON.parse(localStorage.getItem("response"));

  const handleTxnApproval = async (id) => {
    setLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    const imageStyle = {
      width: "40px",
      height: "40px",
      opacity: 1,
    };

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };
    toast(
      <div style={divStyle}>
        <img src={whiteLoader} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Approving transaction</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: "252px",
          height: "84px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );
    dispatch(checkUsertoken());
    const data = await dispatch(beneTxnWaitingApproval(id));

    let errorMessage = "";
    let lifecycleStatus = "";
    let distributionStatus = "";
    if (data?.payload?.status === 500) {
      let lifecycleStatus = "pending";
      setTimeout(() => {
        toast.error(data?.payload?.error, toast_position);
      }, 3000);
    }
    if (data?.payload?.errors?.user_unauthorized?.[0]) {
      setTimeout(() => {
        // toast.error(
        //   data?.payload?.errors?.user_unauthorized?.[0],
        //   toast_position
        // );
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 2000);
    } else if (data.payload.status !== 404) {
      const updatedData = AllBeneTxnAccounts?.map((item) => {
        if (item.txn_id === id) {
          if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "approved"
          ) {
            lifecycleStatus = data.payload.transactions[0].lifecycle_status;
            distributionStatus =
              data.payload.transactions[0].distribution_lifecycle_status;
          } else if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]?.reason
          ) {
            errorMessage = data.payload.errors.base[0].errors[0].reason[0];
            if (data?.payload?.errors?.base[0]?.errors[0]?.reason.length >= 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;
              msgs?.forEach((error) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error, toast_position);
                  }, 3000);
                }
              });
            }
          } else if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]?.reason
          ) {
            errorMessage =
              data.payload.errors.base[0].errors[0].reason ||
              data.payload.errors.base[0].errors[0].reason[0];
            if (data?.payload?.errors?.base[0]?.errors.length > 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;

              msgs?.forEach((error) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error, toast_position);
                  }, 3000);
                }
              });
            }
            if (data?.payload?.errors?.base[0]?.errors.length >= 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;
              errorMessage = msgs;
            }
          } else if (data?.payload?.errors?.base[0]) {
            errorMessage = data.payload.errors.base[0];
            setTimeout(() => {
              toast.error(errorMessage, toast_position);
            }, 3000);
          } else if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "pending"
          ) {
            lifecycleStatus = "pendingOne";
          }

          return {
            ...item,
            last_updated_status:
              item.last_updated_status === "Created" &&
              data?.payload.status === true &&
              data.payload.transactions[0].last_updated_status === "Approved"
                ? "Approved"
                : "Created",

            lifecycle_status:
              data?.payload.status === true &&
              item.lifecycle_status === "pending"
                ? lifecycleStatus
                : "error",
            error:
              item.error === "false" && data?.payload.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data?.payload.status === false
                ? errorMessage
                : "",

            last_updated_user_id_check:
              item.last_updated_user_id_check === "false" &&
              data.payload.status === true &&
              loginid.employees.id ===
                data.payload.transactions[0].last_updated_user_id
                ? "true"
                : "false",
            distribution_lifecycle_status:
              item.distribution_lifecycle_status === "" &&
              data?.payload.status === true
                ? distributionStatus
                : "",
          };
        }

        return item;
      });
      setAllBeneTxnAccounts(updatedData);
    }

    setTimeout(() => {
      setLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
      toast.dismiss();
    }, 2000);

    setTimeout(() => {
      if (
        data?.payload?.status === true &&
        data.payload.transactions[0].lifecycle_status === "approved"
      ) {
        handleApprovalToastSuccess();
      }
      if (data.payload.status === 404) {
        toast.error(data.payload.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }, 2000);
  };
  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleFilterCategory = () => {
    setCurrentCategory(label);
    setAllBeneTxnAccounts([]);
    if (page === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());

    dispatch(
      BeneTxnRequireApprovalPage({
        page_no: page,
        category: label !== "Select category" ? label : undefined,
      })
    )
      .then((response) => {
        const transactions = response?.payload?.transactions || [];

        const newData = transactions.map((account) => ({
          ...account,
          loading: false,
          error: "false",
          message: "",
          last_updated_user_id_check: "false",
          distribution_lifecycle_status: "",
        }));

        setAllBeneTxnAccounts(newData);
        setShowFilterErrorPage(true);
        setAllBeneTxnAccountsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAllBeneTxnAccountsLoading(false);
      });

    setInitCategory(false);
    setFilterOpen(false);
    setFilterCat(false);
  };

  const handleClearCategory = () => {
    setlabel("Select category");
    setAllBeneTxnAccountsLoading(true);
    setPage(1);
    setCurrentCategory("");
    setInitCategory("");
    if (label !== "Select category") {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: undefined,
        })
      )
        .then((response) => {
          const transactions = response?.payload?.transactions || [];

          const newData = transactions.map((account) => ({
            ...account,
            loading: false,
            error: "false",
            message: "",
            last_updated_user_id_check: "false",
            distribution_lifecycle_status: "",
          }));

          setAllBeneTxnAccounts(newData);
          setAllBeneTxnAccountsLoading(false);
          setShowFilterErrorPage(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setAllBeneTxnAccountsLoading(false);
        });
    }
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  const getMarginTop = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width === 2560 && height === 1440) {
      return "125px";
    } else if (width === 3840 && height === 1920) {
      return "120px";
    } else if (width === 1440 && height === 960) {
      return "150px";
    } else if (width === 1920 && height === 1080) {
      return "140px";
    } else {
      return "5.2rem";
    }
  };

  const style = {
    opacity: filterOpen ? 0.8 : 1,
    background: filterOpen ? "#000" : "",
    marginTop: currentCategory ? getMarginTop() : "",
    overflow: filterOpen ? "hidden" : "",
  };

  return (
    <div
      id="RequireApprovalPageTxn"
      className="w-[100%] h-screen  overflow-hidden"
      style={{
        background: filterOpen ? "#000" : "",
      }}
    >
      <div className="w-full h-[208px]">
        <div className="w-full bg-gradient-to-r from-[#3a5686] to-[#3a5686] relative z-50 opacity-100 h-[208px]">
          <div className="w-[1024px] flex flex-row-reverse items-end mx-auto h-[208px]">
            <div className="flex">
              <div className="w-[872px] h-[103px] mt-[30px]">
                <div className="w-[802px] h-[55px] mt-[15px]">
                  <p className="w-[258px] h-[19px] text-left mt-5 text-[14px] font-normal leading-normal text-white opacity-100">
                    Verification Criteria Upon Last Approval
                  </p>
                  <p className="font-normal text-[11px] mt-2 text-gray-300 opacity-100">
                    Once approved, your transactions will go through a quick
                    verification process. Post-verification, it will be queued
                    and auto settled using selected source account. In case of
                    insufficient funds, the queued transactions will settle
                    automatically as soon as source account balance is refilled.
                  </p>
                </div>
              </div>
              <div className="flex h-[103px] pt-[40px]">
                <div className="filter_div_container">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title="Filters"
                  >
                    <div
                      style={{
                        backgroundColor:
                          currentCategory !== "" ? "#F9F9F9" : "#1d3a6d ",
                        opacity:
                          ShowFilterErrorPage || AllBeneTxnAccounts.length > 0
                            ? "1"
                            : "0.5",
                        cursor:
                          ShowFilterErrorPage || AllBeneTxnAccounts.length > 0
                            ? "pointer"
                            : "auto",
                      }}
                      className={`filter-main ${
                        AllBeneTxnAccounts.length === 0
                          ? "bg-red"
                          : "bg-[#1d3a6d]"
                      }`}
                      onClick={() => {
                        // toast.info("Feature coming soon..", toast_position);
                        if (
                          ShowFilterErrorPage &&
                          AllBeneTxnAccounts.length === 0
                        ) {
                          setFilterOpen(!filterOpen);
                          setInitCategory(false);
                        } else if (AllBeneTxnAccounts.length === 0) {
                          setFilterOpen(filterOpen);
                          setInitCategory(false);
                        } else if (
                          AllBeneTxnAccounts.length === 0 ||
                          AllBeneTxnAccounts.length > 0
                        ) {
                          setFilterOpen(!filterOpen);
                          setInitCategory(false);
                        }
                      }}
                    >
                      <Box className="filters-box">
                        <img
                          src={currentCategory !== "" ? FilterBlue : Filter}
                          alt=""
                          className="filter-images-element"
                        />
                      </Box>
                      <Box className="filter-box">
                        <img
                          src={
                            filterOpen
                              ? currentCategory !== ""
                                ? ArrowUp4
                                : ArrowUp
                              : currentCategory !== ""
                              ? ArrowDown8
                              : ArrowDown
                          }
                          alt=""
                          className="filter-images-element"
                        />
                      </Box>
                    </div>
                  </Tooltip>
                </div>
                <div className="Single_toggle-containers">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title="Single Approval"
                  >
                    <Box
                      onClick={() => {
                        setToggle(true);
                      }}
                      sx={{
                        backgroundColor: toggle ? "#fff" : "",
                      }}
                      className={`toggle-box_Txn `}
                    >
                      <img
                        src={toggle ? ListBlue : ListWhite}
                        alt=""
                        className="toggle-images"
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title="Multi Approval"
                  >
                    <Box
                      onClick={() => {
                        handleMultiViewClick();
                        setToggle(false);
                      }}
                      sx={{
                        backgroundColor: toggle ? "" : "#fff",
                      }}
                      className={`toggle-box_Txn`}
                    >
                      <img
                        src={toggle ? GridWhite : GridBlue}
                        alt=""
                        className="toggle-images"
                      />
                    </Box>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`filterdropdown-transition-${
                  filterOpen ? "open" : "close"
                }`}
              >
                {filterOpen && (
                  <div className="filterdropdown-container-div">
                    <div className="filterdropdown-div">
                      <div
                        style={{
                          display: "flex",
                          width: "45%",
                        }}
                      >
                        <SelectCategoryTransaction
                          data={AllBeneTxnAccounts}
                          label={label}
                          setlabel={setlabel}
                          initCategory={initCategory}
                          setFilterOpen={setFilterOpen}
                          sendCategory={handleFilterCategory}
                          filterOpen={filterOpen}
                          setInitCategory={setInitCategory}
                          transaction={true}
                          setPage={setPage}
                          setFilterCat={setFilterCat}
                          filteredType={"singleApproveType"}
                        />
                      </div>

                      <div className="button_container_div">
                        <div
                          style={{
                            cursor:
                              currentCategory !== "" ? "pointer" : "default",
                            hover:
                              currentCategory !== "" ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if (label !== "Select category") {
                              handleClearCategory();
                            }
                          }}
                          className="clear hover:bg-[#506994]"
                        >
                          <p className="buttoncontainer-text">Clear</p>
                        </div>
                        <div
                          onClick={() => {
                            if (label !== "Select category") {
                              handleFilterCategory();
                            }
                          }}
                          className="apply hover:opacity-80"
                          style={{
                            backgroundColor:
                              label === "Select category"
                                ? "#707070"
                                : "#FB5963",
                            cursor:
                              label === "Select category"
                                ? "default"
                                : "pointer",
                            hover:
                              currentCategory !== "" ? "pointer" : "default",
                          }}
                        >
                          <p className="buttoncontainer-text">Apply</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={filterRef} id="txnViewallScrollContainerBar" style={style}>
        {currentCategory && (
          <div className="bene_txn_MultiApproval_FilterBar">
            <div className="bene_txn_MultiApprovalfilterdropdown">
              <div
                style={{
                  display: "flex",
                  // width: "45%",
                }}
              >
                <div className="bene_txn_MultiApp_filter_div_left">
                  <p className="pr-2 w-[87px]">Filtered by : </p>
                  {currentCategory !== "" && (
                    <div className="bene_txn_MultiApp_filter_body px-1 pt-1">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={currentCategory}
                      >
                        <p className="px-2 text-xs truncate w-[100px] h-[19px]">
                          {currentCategory}
                        </p>
                      </Tooltip>
                      <div
                        className="bene_txn_MultiApp_close_btn"
                        onClick={handleClearCategory}
                      >
                        {" "}
                        <img
                          src={closeBtnImg}
                          alt="close-btn"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {allBeneTxnAccountsLoading ? (
          <div className="loader-container m-auto justify-center items-center flex mt-20">
            <img src={dataLoading} alt="" />
          </div>
        ) : BeneTxnRequireApprovalData === undefined ||
          AllBeneTxnAccounts.length === 0 ? (
          <div
            className=" w-full"
            style={{
              overflow: "hidden",
            }}
            ref={filterRef}
          >
            <div
              ref={filterRef}
              className="BeneAccount_NoAccountFound "
              style={{
                opacity: filterOpen ? 0.2 : 1,
              }}
            >
              <div className="BeneAccount_RightTextContentNoAccount">
                <img
                  src={
                    ShowFilterErrorPage && AllBeneTxnAccounts.length === 0
                      ? FiterNoAccount
                      : NoAccountFound
                  }
                  alt=""
                />
              </div>
              <div className="BeneAccount_LeftTextContentNoAccount">
                {ShowFilterErrorPage && AllBeneTxnAccounts.length === 0 ? (
                  <span className="">
                    No data found for the selected filter.
                  </span>
                ) : (
                  <>
                    <p className="w-[426px]">
                      There are no more transactions that require your approval.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : AllBeneTxnAccounts?.length > 0 ? (
          <>
            <div
              ref={filterRef}
              className={`w-[100%]  ${
                filterOpen && "h-screen  bg-[#000] opacity-80 z-[9998] "
              }`}
            >
              <div
                className={`Benetxn_SubAccountApproval w-[1024px] m-auto`}
                ref={filterRef}
                style={{
                  height: filterOpen ? "100%" : "",
                  overflow: filterOpen ? "hidden" : "auto",
                }}
              >
                {showPopup && (
                  <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
                    <DeclinedPopup
                      onClose={handleClosePopup}
                      accountId={deniedId}
                      allAccounts={AllBeneTxnAccounts}
                      updateAllAccounts={updateAllAccounts}
                      status={"Transaction"}
                    />
                  </div>
                )}
                <BeneTxnSideDrawer
                  isOpen={isDrawerOpen}
                  onClose={SideDrawerOpen}
                  txnRequiredStatus={"BeneTxnRequiredApproval"}
                  setIsSidebarOpen={setisDrawerOpen}
                  BeneTxnDetailsPage={BeneTxnDetailsId}
                  BeneTxnDetailsFunc={SetBeneTxnDetailsId}
                  AllBeneTxnData={setAllBeneTxnAccounts}
                  DrawerOpenStatus={DrawerStatus}
                  disabled={sideDrawerDisabled}
                  setDisabled={setSideDrawerDisabled}
                />
                <div
                  className={`scroll flex flex-col items-center justify-center w-full`}
                >
                  <InfiniteScroll
                    style={{ width: "inherit" }}
                    dataLength={AllBeneTxnAccounts.length || []}
                    next={fetchMoreData}
                    hasMore={
                      page <
                      Math.ceil(
                        BeneTxnRequireApprovalData?.total_count / pageSize
                      )
                    }
                    loader={
                      <div className="loader-container m-auto justify-center items-center flex">
                        <img src={dataLoading} alt="" />
                      </div>
                    }
                    scrollableTarget="txnViewallScrollContainerBar"
                    scrollThreshold={0.3}
                  >
                    {AllBeneTxnAccounts?.map((account) => (
                      <>
                        <div
                          className={`BeneTxn_ApprovedAcoutDetailsPage flex w-[1024px] h-[220px] rounded-[30px] ${
                            filterOpen && "bg-[#000] opacity-10 z-[9998]"
                          }`}
                          key={account.txn_id}
                        >
                          {(account.lifecycle_status === "pending" ||
                            loading[account.txn_id]) &&
                            account.beneficiary_account_approval_status ===
                              "approved" && (
                              <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="w-[90px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>

                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                    <img
                                      className="w-[20px] h-[20px] "
                                      src={ArrowLeft}
                                      alt=""
                                    />
                                  </div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div className="">
                                      <div className="flex items-center ">
                                        <div className="flex gap-1 ">
                                          <div className="">
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="bg-white">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            SetrefreshId(account.txn_id);
                                            handleRefresh(
                                              e,
                                              account.owner_account_id,
                                              account?.owner_account_balance
                                            );
                                          }}
                                          className={`flex items-center  hover:bg-custom-cardHover ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {account.lifecycle_status === "rejected" &&
                            !loading[account.txn_id] && (
                              <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="w-[90px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>

                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 "></div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div className="">
                                      <div className="flex items-center ">
                                        <div className="flex gap-1">
                                          <div>
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            SetrefreshId(account.txn_id);
                                            handleRefresh(
                                              e,
                                              account.owner_account_id,
                                              account?.owner_account_balance
                                            );
                                          }}
                                          className={`flex items-center  hover:bg-custom-cardHover ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {(account.lifecycle_status === "pending" ||
                            loading[account.txn_id]) &&
                            account.beneficiary_account_approval_status ===
                              "pending" && (
                              <div className="BeneTxn_ApprovalAcdivFirst   z-20 bg-black">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px]  opacity-[0.4]">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100 hover:cursor-default">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px] overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100 hover:cursor-default">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="w-[90px] h-[19px] whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>
                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px] opacity-[0.4]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 "></div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div>
                                      <div className="flex items-center">
                                        <div className="flex gap-1">
                                          <div>
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            if (
                                              account.beneficiary_account_approval_status !==
                                              "pending"
                                            ) {
                                              SetrefreshId(account.txn_id);
                                              handleRefresh(
                                                e,
                                                account.owner_account_id,
                                                account?.owner_account_balance
                                              );
                                            }
                                          }}
                                          className={`flex items-cente ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {account.lifecycle_status === "approved" &&
                            !loading[account.txn_id] && (
                              <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px] overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="[w-[90px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>

                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                    <img
                                      className="w-[20px] h-[20px] "
                                      src={ArrowLeft}
                                      alt=""
                                    />
                                  </div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div className="">
                                      <div className="flex items-center ">
                                        <div className="flex gap-1">
                                          <div>
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            SetrefreshId(account.txn_id);
                                            handleRefresh(
                                              e,
                                              account.owner_account_id,
                                              account?.owner_account_balance
                                            );
                                          }}
                                          className={`flex items-center  hover:bg-custom-cardHover ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {account.lifecycle_status === "error" &&
                            account.error === "true" &&
                            !loading[account.txn_id] && (
                              <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="w-[90px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>

                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                    <img
                                      className="w-[20px] h-[20px] "
                                      src={ArrowLeft}
                                      alt=""
                                    />
                                  </div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div className="">
                                      <div className="flex items-center ">
                                        <div className="flex gap-1">
                                          <div>
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            SetrefreshId(account.txn_id);
                                            handleRefresh(
                                              e,
                                              account.owner_account_id,
                                              account?.owner_account_balance
                                            );
                                          }}
                                          className={`flex items-center  hover:bg-custom-cardHover ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {account.last_updated_user_id_check === "true" &&
                            account.lifecycle_status === "pendingOne" &&
                            account.error === "false" &&
                            !loading[account.txn_id] && (
                              <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                  <div className="flex  flex-col gap-1 ml-[20px]">
                                    <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                      <span
                                        style={{
                                          display: "inline-block",
                                          fontSize: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        ₹
                                      </span>
                                      {<FormatAmount price={account.amount} />}
                                    </p>
                                    <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Transaction amount
                                    </span>
                                  </div>
                                  <div className="ml-[40px] flex flex-col gap-1">
                                    {account?.category &&
                                    account?.category.length >= 10 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.category}
                                      >
                                        <p className="w-[94px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {account?.category}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="w-[90px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.category}
                                      </p>
                                    )}
                                    <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                      Category selected
                                    </span>
                                  </div>

                                  <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                </div>

                                <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                  <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                      <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.owner_account_bank_acc_no &&
                                          account?.owner_account_bank_acc_no.replace(
                                            /(\d{4})(?=\d)/g,
                                            "$1 "
                                          )}
                                      </p>
                                      <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        Source account
                                      </span>
                                    </div>

                                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.owner_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                    <img
                                      className="w-[20px] h-[20px] "
                                      src={ArrowLeft}
                                      alt=""
                                    />
                                  </div>

                                  <div className=" ml-[20px] flex justify-between">
                                    <div className="">
                                      <div className="flex items-center ">
                                        <div className="flex gap-1">
                                          <div>
                                            <h4
                                              style={{
                                                color:
                                                  Number(
                                                    account?.owner_account_balance
                                                  ) >= Number(account.amount)
                                                    ? "#4DDD37"
                                                    : "#FF5860",
                                              }}
                                            >
                                              {" "}
                                              ₹{" "}
                                              {
                                                <FormatAmount
                                                  price={
                                                    account?.owner_account_balance
                                                  }
                                                />
                                              }
                                            </h4>
                                          </div>
                                          <div className=" relative">
                                            <p className="">
                                              <AnimationUpAndDown
                                                loader={ownerAccBalanceLoading}
                                                currentId={
                                                  account?.owner_account_id
                                                }
                                                currentBalance={
                                                  account?.owner_account_balance
                                                }
                                                previousId={refreshAnimation}
                                                previousBalance={PrevBalance}
                                                refreshType={"single"}
                                                classType={"RequiredApproval"}
                                              />
                                            </p>
                                          </div>
                                          <div className=" ml-3">
                                            <p className="h-[26px] text-[#C9C9C9]">
                                              {" "}
                                              {"  "} |
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            SetrefreshId(account.txn_id);
                                            handleRefresh(
                                              e,
                                              account.owner_account_id,
                                              account?.owner_account_balance
                                            );
                                          }}
                                          className={`flex items-center  hover:bg-custom-cardHover ml-[4px] gap-[5px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          {ownerAccBalanceLoading &&
                                          refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              type={"createTxn"}
                                            />
                                          ) : !ownerAccBalanceLoading &&
                                            refreshId === account.txn_id ? (
                                            <BalanceLoader
                                              loader={ownerAccBalanceLoading}
                                              setAccId={SetrefreshId}
                                              type={"createTxn"}
                                            />
                                          ) : (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={"Refresh Balance"}
                                            >
                                              <div className="flex gap-1">
                                                <img
                                                  className="w-[12px] h-[12px] opacity-0.7"
                                                  src={ReloadWhite}
                                                  alt=""
                                                />

                                                <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                                                  Refresh
                                                </span>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </div>

                                      <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#C9C9C9] opacity-100">
                                        Source account balance -&nbsp;
                                        {refreshId === account.txn_id
                                          ? account?.owner_account_balance_last_fetched_at_in_words
                                          : account?.owner_account_balance_last_fetched_at_in_words}
                                      </div>
                                    </div>
                                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="BeneTxn_ApprovalAcdivSecond">
                            {account.lifecycle_status === "rejected" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <div className="flex justify-between w-[341px] h-[220px] bg-[#667CA3]">
                                  <div className="flex flex-col gap-[6px] mt-[20px] ml-[27px] overflow-hidden">
                                    <p className="w-[120px] h-[16px] font-normal text-[15px] leading-[16px] text-[#DADADA]">
                                      Rejected reason
                                    </p>

                                    {account.rejection_reason &&
                                    account.rejection_reason.length > 300 ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account.rejection_reason}
                                      >
                                        <span
                                          className="w-[280px] h-[16px]  text-left text-[13px] font-normal  font-sans tracking-normal text-[#F9F9F9] opacity-100 overflow-ellipsis"
                                          style={{
                                            letterSpacing: "0.5px",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {account.rejection_reason}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span
                                        className="w-[280px] h-[16px]  text-left text-[13px] font-normal  font-sans tracking-normal text-[#F9F9F9] opacity-100 overflow-ellipsis"
                                        style={{
                                          letterSpacing: "0.5px",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {account.rejection_reason}
                                      </span>
                                    )}
                                  </div>
                                  <div className="w-[1.3px] h-[180px] bg-[#1D3A6D] opacity-[0.6] mt-[20px]"></div>
                                </div>
                              )}

                            {(account.lifecycle_status === "pending" ||
                              loading[account.txn_id]) &&
                              account.beneficiary_account_approval_status ===
                                "approved" && (
                                <>
                                  <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                    <div className="flex  flex-col gap-1 ml-[20px]">
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.beneficiary_account_name
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                      >
                                        <p className="w-[300px] lowercase  first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </p>
                                      </Tooltip>

                                      <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                        Beneficiary name
                                      </span>
                                    </div>
                                    <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                    <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                      <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                        {account?.beneficiary_account_bank_acc_no &&
                                        account?.beneficiary_account_bank_acc_no
                                          .length >= 0 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account?.beneficiary_account_bank_acc_no
                                            }
                                          >
                                            <p className="w-[190px]  text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_bank_acc_no &&
                                                account?.beneficiary_account_bank_acc_no.replace(
                                                  /(\d{4})(?=\d)/g,
                                                  "$1 "
                                                )}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[190px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              account?.beneficiary_account_bank_acc_no.replace(
                                                /(\d{4})(?=\d)/g,
                                                "$1 "
                                              )}
                                          </p>
                                        )}
                                        <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Beneficiary account
                                        </span>
                                      </div>

                                      <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                        <img
                                          className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                          src={require("../../../assets/IfscLogo/" +
                                            IfscCodeMapper(
                                              account?.beneficiary_account_ifsc_code
                                            ))}
                                          alt="bank"
                                        />
                                      </div>
                                      <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                    </div>

                                    <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                      <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.beneficiary_account_ifsc_code}
                                      </p>
                                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        IFSC code
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                            {(account.lifecycle_status === "pending" ||
                              loading[account.txn_id]) &&
                              account.beneficiary_account_approval_status ===
                                "pending" && (
                                <div className="bg-[#314D7E]">
                                  <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                    <div className="flex  flex-col gap-1 ml-[20px]">
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                            },
                                          },
                                        }}
                                        title={account?.beneficiary_account_name
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                      >
                                        <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </p>
                                      </Tooltip>

                                      <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                        Beneficiary name
                                      </span>
                                    </div>
                                    <div className="w-[1.5px] h-[180px] bg-[#1D3A6D] relative  top-[72px] opacity-[0.6]"></div>
                                  </div>

                                  <div className=" w-[341px]  h-[140px] bg-[#314D7E]">
                                    <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                      <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                        {account?.beneficiary_account_bank_acc_no &&
                                        account?.beneficiary_account_bank_acc_no
                                          .length >= 0 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account?.beneficiary_account_bank_acc_no
                                            }
                                          >
                                            <p className="w-[190px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_bank_acc_no &&
                                                account?.beneficiary_account_bank_acc_no.replace(
                                                  /(\d{4})(?=\d)/g,
                                                  "$1 "
                                                )}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[190px]   text-left  font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              account?.beneficiary_account_bank_acc_no.replace(
                                                /(\d{4})(?=\d)/g,
                                                "$1 "
                                              )}
                                          </p>
                                        )}
                                        <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Beneficiary account
                                        </span>
                                      </div>

                                      <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa]  relative left-[-20px] rounded-[22px] opacity-100">
                                        <img
                                          className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                          src={require("../../../assets/IfscLogo/" +
                                            IfscCodeMapper(
                                              account?.beneficiary_account_ifsc_code
                                            ))}
                                          alt="bank"
                                        />
                                      </div>
                                    </div>

                                    <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                      <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.beneficiary_account_ifsc_code}
                                      </p>
                                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        IFSC code
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {account.lifecycle_status === "error" &&
                              account.error === "true" &&
                              !loading[account.txn_id] && (
                                <>
                                  <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                    <div className="flex  flex-col gap-1 ml-[20px]">
                                      {account?.beneficiary_account_name &&
                                      account?.beneficiary_account_name.length >
                                        24 ? (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        >
                                          <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </p>
                                      )}

                                      <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                        Beneficiary name
                                      </span>
                                    </div>
                                    <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                    <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                      <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                        {account?.beneficiary_account_bank_acc_no &&
                                        account?.beneficiary_account_bank_acc_no
                                          .length >= 0 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account?.beneficiary_account_bank_acc_no
                                            }
                                          >
                                            <p className="w-[190px]  text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_bank_acc_no &&
                                                account?.beneficiary_account_bank_acc_no.replace(
                                                  /(\d{4})(?=\d)/g,
                                                  "$1 "
                                                )}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[190px]  text-left  font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              account?.beneficiary_account_bank_acc_no.replace(
                                                /(\d{4})(?=\d)/g,
                                                "$1 "
                                              )}
                                          </p>
                                        )}
                                        <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Beneficiary account
                                        </span>
                                      </div>

                                      <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                        <img
                                          className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                          src={require("../../../assets/IfscLogo/" +
                                            IfscCodeMapper(
                                              account?.beneficiary_account_ifsc_code
                                            ))}
                                          alt="bank"
                                        />
                                      </div>
                                      <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                    </div>

                                    <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                      <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.beneficiary_account_ifsc_code}
                                      </p>
                                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        IFSC code
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                            {account.lifecycle_status === "approved" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <>
                                  <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                    <div className="flex  flex-col gap-1 ml-[20px]">
                                      {account?.beneficiary_account_name &&
                                      account?.beneficiary_account_name.length >
                                        24 ? (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        >
                                          <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </p>
                                      )}

                                      <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                        Beneficiary name
                                      </span>
                                    </div>
                                    <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                    <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                      <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                        {account?.beneficiary_account_bank_acc_no &&
                                        account?.beneficiary_account_bank_acc_no
                                          .length > 0 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account?.beneficiary_account_bank_acc_no
                                            }
                                          >
                                            <p className="w-[190px]  first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_bank_acc_no &&
                                                account?.beneficiary_account_bank_acc_no.replace(
                                                  /(\d{4})(?=\d)/g,
                                                  "$1 "
                                                )}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[190px]   first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              account?.beneficiary_account_bank_acc_no.replace(
                                                /(\d{4})(?=\d)/g,
                                                "$1 "
                                              )}
                                          </p>
                                        )}
                                        <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Beneficiary account
                                        </span>
                                      </div>

                                      <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                        <img
                                          className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                          src={require("../../../assets/IfscLogo/" +
                                            IfscCodeMapper(
                                              account?.beneficiary_account_ifsc_code
                                            ))}
                                          alt="bank"
                                        />
                                      </div>
                                      <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                    </div>

                                    <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                      <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.beneficiary_account_ifsc_code}
                                      </p>
                                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        IFSC code
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                            {account.last_updated_user_id_check === "true" &&
                              account.lifecycle_status === "pendingOne" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <>
                                  <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                    <div className="flex  flex-col gap-1 ml-[20px]">
                                      {account?.beneficiary_account_name &&
                                      account?.beneficiary_account_name.length >
                                        24 ? (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        >
                                          <p className="w-[198px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_name
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </p>
                                      )}

                                      <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#C9C9C9] opacity-100">
                                        Beneficiary name
                                      </span>
                                    </div>
                                    <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                  </div>

                                  <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                    <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                      <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                        {account?.beneficiary_account_bank_acc_no &&
                                        account?.beneficiary_account_bank_acc_no
                                          .length > 0 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account?.beneficiary_account_bank_acc_no
                                            }
                                          >
                                            <p className="w-[190px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_bank_acc_no &&
                                                account?.beneficiary_account_bank_acc_no.replace(
                                                  /(\d{4})(?=\d)/g,
                                                  "$1 "
                                                )}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[190px] lowercase  first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              account?.beneficiary_account_bank_acc_no.replace(
                                                /(\d{4})(?=\d)/g,
                                                "$1 "
                                              )}
                                          </p>
                                        )}
                                        <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Beneficiary account
                                        </span>
                                      </div>

                                      <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                        <img
                                          className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                          src={require("../../../assets/IfscLogo/" +
                                            IfscCodeMapper(
                                              account?.beneficiary_account_ifsc_code
                                            ))}
                                          alt="bank"
                                        />
                                      </div>
                                      <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                    </div>

                                    <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                      <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                        {account?.beneficiary_account_ifsc_code}
                                      </p>
                                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                        IFSC code
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>

                          <div className="BeneTxn_ApprovalAcdivThird">
                            {(account.lifecycle_status === "pending" ||
                              loading[account.txn_id]) &&
                              account.beneficiary_account_approval_status ===
                                "approved" && (
                                <>
                                  <div className="flex  w-[341px] justify-between bg-[#314D7E] text-white h-[80px] rounded-tr-[30px] ">
                                    <div className="flex  justify-between w-[341px]">
                                      <div className="pt-[18px] ml-[20px]">
                                        <p className="w-[199px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100">
                                          {BeneTxnDataFormat(
                                            account?.created_on
                                          )}
                                          ,
                                          {formatTime(
                                            account?.last_updated_timestamp
                                          )}
                                        </p>

                                        <span className="w-[79px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#C9C9C9] opacity-100">
                                          Date and time
                                        </span>
                                      </div>

                                      <div
                                        onClick={() => {
                                          setSideDrawerDisabled(false);
                                          if (!filterOpen) {
                                            SideDrawerOpen(
                                              "BeneTxnRequiredApprovalMoreDetails",
                                              account?.beneficiary_account_id,
                                              account.txn_id
                                            );
                                          }
                                        }}
                                        className={`w-[132px] flex items-center rounded-tr-[30px] rounded-bl-[30px] justify-center h-[49px] bg-[#F9F9F9] bg-no-repeat bg-cover  opacity-100 ${
                                          filterOpen
                                            ? "cursor-default"
                                            : " cursor-pointer"
                                        }`}
                                      >
                                        <div className="flex items-center gap-[2px] justify-center">
                                          <p className="w-[auto] h-[17px] text-left font-semibold text-[12px] leading-17 text-[#1D3A6D] opacity-100">
                                            More Details
                                          </p>
                                          <img
                                            className="w-[12px] h-[12px] opacity-100"
                                            src={ArrowRight}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-end bg-[#506994]  w-[341px]   h-[137px] rounded-br-[30px] ">
                                    <div className="flex ml-[68px] bg-[#667CA3] items-center relative top-[3px] justify-between  w-[273px] rounded-br-[30px] h-[100px]  bg-cover rounded-tl-[30px]  opacity-100">
                                      {account?.lifecycle_status ===
                                        "pending" &&
                                        !loading[account.txn_id] && (
                                          <>
                                            <button
                                              className={`flex flex-col justify-center items-center ml-[5px] mb-[1px] gap-[4px] w-[45%] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : " cursor-pointer"
                                              } `}
                                              onClick={() => {
                                                if (!filterOpen) {
                                                  handleOpenPopup(
                                                    account?.txn_id
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                className="w-[40px] h-[40px] shadow-[5px -5px 11px #71717140]"
                                                src={aprovaldenied}
                                                alt=""
                                              />
                                              <p className="w-[34px] h-[14px] text-center text-[10px] leading-14 text-[#FFFFFF]">
                                                Reject
                                              </p>
                                            </button>

                                            <div className="w-0 h-[80px] border  border-[#1D3A6D] opacity-[0.6]"></div>
                                            <button
                                              ref={filterRef}
                                              className={`flex flex-col justify-center  items-center mr-[5px] w-[45%] gap-[4px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : " cursor-pointer"
                                              }`}
                                              onClick={() => {
                                                if (!filterOpen) {
                                                  handleTxnApproval(
                                                    account?.txn_id
                                                  );
                                                }
                                              }} // 1
                                            >
                                              <img
                                                className="w-[40px] h-[40px] shadow-[5px -5px 11px #71717140"
                                                src={aprovalaccept}
                                                alt=""
                                              />
                                              <p className="w-[40px] h-[14px] text-center text-[10px] leading-14 text-[#FFFFFF]">
                                                Approve
                                              </p>
                                            </button>
                                          </>
                                        )}

                                      {loading[account.txn_id] && (
                                        <div className="flex justify-between items-center w-[50px] h-[29px]  ml-[110px] opacity-100">
                                          <img
                                            src={xpentraloader}
                                            alt="Loading..."
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                            {account.lifecycle_status === "rejected" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <div className=" flex w-[341px]  pl-[20px] h-[220px] gap-[20px] bg-[#667CA3] rounded-tr-[30px] rounded-br-[30px] ">
                                  <div className="w-[40px] h-[40px]">
                                    <img
                                      className=" mt-[58px]"
                                      src={DeclinedRed}
                                      alt=""
                                    />
                                  </div>
                                  <div className="w-[230px]  h-[131px] mt-[44px]">
                                    <div className="">
                                      <p className="w-[72px]  h-[22px] text-left font-semibold text-[#FFFFFF] text-[16px] leading-[22px]">
                                        Rejected!
                                      </p>
                                      <span className="w-[230px] h-[19px] text-left font-normal text-[14px] leading-[19px] text-[#FAFAFA]">
                                        This transaction has been Rejected!
                                      </span>
                                    </div>
                                    <div
                                      className="BeneTxn_ViewTransction"
                                      onClick={() =>
                                        viewTxnNavigate(account.txn_id)
                                      }
                                    >
                                      <p>View transaction</p>
                                      <img src={WhiteArrowRight} alt="" />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {account.last_updated_user_id_check === "true" &&
                              account.lifecycle_status === "pendingOne" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <>
                                  <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                    <div className="BeneTxn_AwatigVefiyImgaes">
                                      <img src={pendingImages} alt="" />
                                    </div>
                                    <div className="BeneTxn_AwaitingVrfSecondDiv">
                                      <div className="BeneTxn_AwaitingVrfTxt">
                                        <p>Awaiting approval</p>
                                        <span>
                                          This transaction has been approved and
                                          requires another user's approval
                                          before distribution.
                                        </span>
                                      </div>
                                      <div
                                        className="BeneTxn_ViewTransction"
                                        onClick={() =>
                                          viewTxnNavigate(account.txn_id)
                                        }
                                      >
                                        <p>View Transaction</p>
                                        <img src={WhiteArrowRight} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {(account.lifecycle_status === "pending" ||
                              loading[account.txn_id]) &&
                              account.beneficiary_account_approval_status ===
                                "pending" && (
                                <div className="flex flex-col w-[342px] h-[220px] bg-[#314D7E] shadow-[5px 5px 11px #00000033] rounded-tr-[30px] rounded-br-[30px]">
                                  <div className="ml-[20px] mt-[34px]">
                                    <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] text-[#DADADA]">
                                      Account Approval Pending
                                    </p>
                                  </div>
                                  <div className="ml-[20px] mt-[30px]">
                                    <div className="">
                                      <p className="w-[269px] h-[38px] text-left font-normal text-[13px] leading-16 text-[#F05A6A] opacity-100">
                                        Account is not approved, please approve
                                        account before approving transaction.
                                      </p>
                                    </div>
                                    <div
                                      onClick={() => {
                                        if (
                                          !sessionStorage.getItem("q2p_token")
                                        ) {
                                          setisDrawerOpen(false);
                                          dispatch(checkUsertoken());
                                          return;
                                        }
                                        if (filterOpen) {
                                          return;
                                        } else {
                                          setSideDrawerDisabled(false);

                                          SideDrawerOpen(
                                            "BeneTxnRequiredApproval",
                                            account?.beneficiary_account_id,
                                            account?.txn_id
                                          );
                                        }
                                      }}
                                      className={`flex justify-between items-center gap-[4px]  w-[213px] h-[31px] bg-[#1d3a6d] rounded-[10px] opacity-100 mt-[20px] ${
                                        filterOpen
                                          ? "cursor-default"
                                          : " cursor-pointer"
                                      }`}
                                    >
                                      <p className="w-[105px] h-[19px] font-normal text-[13px] text-[#ffffff] opacity-100 ml-[10px]">
                                        Approve account
                                      </p>
                                      <img
                                        className="w-[12px] h-[16px] opacity-100 mr-[10px]"
                                        src={WhiteArrowRight}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {account.lifecycle_status === "approved" &&
                              account.distribution_lifecycle_status ===
                                "created" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                  <div className="BeneTxn_AwatigVefiyImgaes">
                                    <img src={BeneTxn_Queued} alt="" />
                                  </div>
                                  <div className="BeneTxn_AwaitingVrfSecondDiv">
                                    <div className="BeneTxn_DistributionTxt">
                                      <p>Distribution created</p>
                                      <span>
                                        This transaction has been approved and
                                        is set for distribution.
                                      </span>
                                    </div>
                                    <div
                                      className="BeneTxn_ViewTransction"
                                      onClick={() =>
                                        viewTxnNavigate(account.txn_id)
                                      }
                                    >
                                      <p>View Transaction</p>
                                      <img src={WhiteArrowRight} alt="" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            {account.lifecycle_status === "approved" &&
                              account.distribution_lifecycle_status ===
                                "distribution_complete" &&
                              account.error === "false" &&
                              !loading[account.txn_id] && (
                                <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                  <div className="BeneTxn_AwatigVefiyImgaes">
                                    <img src={Approvalgreen} alt="" />
                                  </div>
                                  <div className="BeneTxn_AwaitingVrfSecondDiv">
                                    <div className="BeneTxn_DistributionTxt">
                                      <p>Distribution completed!</p>
                                      <span>
                                        This transaction has been verified and
                                        settled.
                                      </span>
                                    </div>
                                    <div
                                      className="BeneTxn_ViewTransction"
                                      onClick={() =>
                                        viewTxnNavigate(account.txn_id)
                                      }
                                    >
                                      <p>View Transaction</p>
                                      <img src={WhiteArrowRight} alt="" />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {account.lifecycle_status === "error" &&
                              account.error === "true" &&
                              !loading[account.txn_id] && (
                                <div className="flex  w-[342px] h-[220px] bg-[#314D7E] pl-[15px]  shadow-[5px 5px 11px #00000033] rounded-tr-[30px] rounded-br-[30px] gap-[15px]">
                                  <div className="w-[40px] h-[40px] ml-[20px] mt-[49px]">
                                    <img src={SkipVerification} alt="" />
                                  </div>
                                  <div className="w-[223px] h-[131px]">
                                    <div className="BeneTxn_Settledtext ">
                                      <p className="w-[227px] h-[22px] text-left font-semibold text-[#FFFFFF] text-[16px] leading-[22px]">
                                        Failed to take action!
                                      </p>
                                      <div className="w-[242px] h-[50px] line-clamp-2   text-[#FFFFFF]   whitespace-wrap overflow-ellipsis">
                                        <span className="     font-normal     text-[#FFFFFF] text-[14px] leading-[19px]">
                                          {account.message && (
                                            // account.message.length >= 10 ? (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                        marginRight: "4rem",
                                                      },
                                                  },
                                                },
                                              }}
                                              title={account?.message}
                                            >
                                              <p className="w-[227px]   line-clamp-2   whitespace-wrap overflow-ellipsis   text-[14px] text-[#FCFCFC] opacity-100">
                                                {account?.message}
                                              </p>
                                            </Tooltip>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="BeneTxn_ViewTransction"
                                      onClick={() =>
                                        viewTxnNavigate(account.txn_id)
                                      }
                                    >
                                      <p>View Transaction</p>
                                      <img src={WhiteArrowRight} alt="" />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default BeneTxnRequireApproval;
