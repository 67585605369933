import React, { useEffect, useState } from "react";
import "./index.css";
import BeneAssociateAccountPopUp from "../BeneAssociatedPop-Up";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import User from "../../../assets/Beneficiary Icons/user.png";
import Contact from "../../../assets/Beneficiary Icons/confirmContact.png";
import Gmail from "../../../assets/Beneficiary Icons/gmail.png";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import BeneIcon from "../../../assets/CreateTransactionIcons/Beneficiary.png";

const BeneConfirmAccount = ({
  payload,
  selectedFields,
  bankAccountName,
  setShowModalPopup,
  showModalPopup,
  bankUserInfo,
}) => {
  const [bankName, setbankName] = useState(null);

  useEffect(() => {
    if (payload && payload.ifsc_code) {
      setbankName(payload.ifsc_code.substring(0, 4));
    }
  }, [payload]);

  useEffect(() => {
    payload.bank_name = bankName;
  }, [bankName]);

  return (
    <>
      {showModalPopup && (
        <div className="fixed top-0 left-0 w-full h-full z-[20000]">
          <BeneAssociateAccountPopUp
            setShowModalPopup={setShowModalPopup}
            showModalPopup={showModalPopup}
            payload={payload}
            selectedFields={selectedFields}
            onClose={() => setShowModalPopup(false)}
          />
        </div>
      )}
      <div className="beneConfirm_Main">
        <div className="dividecon flex flex-col gap-5">
          <div className="flex flex-col bg-[#29487E] rounded-[30px] gap-4 px-8">
            <div>
              <div className="py-5">
                <div className="text-[#DADADA] self-center font-semibold text-base">
                  Beneficiary's PAN Details
                </div>
              </div>
              <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                <div className="drop-shadow-xl h-full rounded-b-large flex flex-col pb-5">
                  <div className="flex flex-col gap-2.5">
                    <div className="space-y-1">
                      <div className="w-full antaliased text-[#F9F9F9] capitalize self-center font-normal text-base">
                        <p className="w-72 overflow-hidden truncate">
                          {payload?.panNumber}
                        </p>
                      </div>
                      <div className="antaliased text-[#DADADA] text-xs opacity-100">
                        PAN number
                      </div>
                    </div>
                    <>
                      <div className="h-[2px] bg-[#1D3A6D] opacity-100"></div>
                      <div className="space-y-1">
                        <div className="w-full antaliased text-[#F9F9F9] capitalize self-center font-normal text-base">
                          <p className="overflow-hidden text-wrap">
                            <Tooltip
                              title={payload?.legalName}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {payload?.legalName?.toLowerCase()}
                            </Tooltip>
                          </p>
                        </div>
                        <div className="antaliased text-[#DADADA] text-xs opacity-100">
                          Legal name
                        </div>
                      </div>

                      {/* <div className="rounded-l-large flex w-full flex-row justify-between">
                        <div className="w-[70%] flex flex-row gap-2">
                          <div className="flex flex-row h-full w-[90%]">
                            <div className="w-full antaliased text-[#DADADA] capitalize self-center text-base">
                              <p className="w-72 overflow-hidden truncate">
                                <Tooltip
                                  title={payload?.legalName}
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  arrow
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  {payload?.legalName?.toLowerCase()}
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-[30%] flex flex-row justify-end">
                          <div className="self-center antaliased text-[#DADADA] text-xs ">
                            Legal name
                          </div>
                        </div>
                      </div> */}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-[#29487E] rounded-[30px] gap-4 px-8">
            <div>
              <div className="py-5">
                <div className="text-[#DADADA] self-center font-semibold text-base">
                  Beneficiary's GST Details
                </div>
              </div>
              <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                <div className="drop-shadow-xl h-full rounded-b-large flex flex-col pb-5">
                  <div className="flex flex-col gap-2.5">
                    <div className="space-y-1">
                      <div className="w-full antaliased text-[#F9F9F9] capitalize self-center font-normal text-base">
                        <p className="w-72 overflow-hidden truncate">
                          {payload.gstin ? (
                            <span>{payload.gstin}</span>
                          ) : (
                            <span>Not Given</span>
                          )}
                        </p>
                      </div>
                      <div className="antaliased text-[#DADADA] text-xs opacity-100">
                        GSTIN (Optional)
                      </div>
                    </div>
                    <>
                      {payload.gstin ? (
                        <>
                          <div className="h-[2px] bg-[#1D3A6D] opacity-100"></div>
                        </>
                      ) : null}
                      {/* <div className="rounded-l-large flex w-full flex-row justify-between">
                        <div className="w-[70%] flex flex-row gap-2">
                          <div className="flex flex-row h-full w-[90%]">
                            <div className="w-full antaliased text-[#DADADA] capitalize self-center text-base">
                              <p className="w-72 overflow-hidden truncate">
                                <Tooltip
                                  title={payload?.gst_org_name}
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  arrow
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  {payload.gstin
                                    ? payload?.gst_org_name?.toLowerCase()
                                    : null}
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-[30%] flex flex-row justify-end">
                          <div className="self-center antaliased text-[#DADADA] text-xs ">
                            {payload.gstin ? "Company name" : null}
                          </div>
                        </div>
                      </div> */}

                      <div className="space-y-1">
                        <div className="w-full antaliased text-[#DADADA] capitalize self-center font-normal text-base">
                          <p className="overflow-hidden text-wrap">
                            <Tooltip
                              title={payload?.gst_org_name}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {payload.gstin
                                ? payload?.gst_org_name?.toLowerCase()
                                : null}
                            </Tooltip>
                          </p>
                        </div>
                        <div className="antaliased text-[#D5D5D5] text-xs opacity-100">
                          {payload.gstin ? "Company name" : null}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-[#29487E] rounded-[30px] gap-4 px-8">
            <div>
              <div className="py-5">
                <div className="text-[#DADADA] self-center font-semibold text-base">
                  Beneficiary's Bank Details
                </div>
              </div>
              <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                <div className="h-full rounded-b-large flex flex-col pb-5">
                  <div className="flex flex-col gap-2.5">
                    <div className="space-y-5">
                      <div className="space-y-1">
                        <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                          {formatAccountNumber(payload?.bank_account)}
                        </div>
                        <div className="antaliased text-[#DADADA] text-xs opacity-100">
                          Bank account number
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div className="space-y-1 w-4/6">
                          <div className="antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                            {payload?.ifsc_code}
                          </div>
                          <div className="antaliased text-[#DADADA] text-xs opacity-100">
                            IFSC code
                          </div>
                        </div>
                        <div className="bank-logo-img-crt">
                          <img
                            src={require("../../../assets/IfscLogo/" +
                              IfscCodeMapper(payload?.ifsc_code))}
                            alt="bank"
                            className="bank-logo-img-size"
                          />
                        </div>
                      </div>
                    </div>
                    <>
                      <div className="h-[2px] bg-[#1D3A6D] opacity-100"></div>
                      <div className="space-y-1 mt-2">
                        <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                          {bankAccountName?.toLowerCase()}
                        </div>
                        <div className="antaliased text-[#DADADA] text-xs opacity-100">
                          Account holder's name
                        </div>
                      </div>
                      {bankUserInfo.length >= 1 ? (
                        <>
                          <div className="space-y-1 mt-2">
                            <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                              {bankUserInfo[0].branch
                                ? bankUserInfo[0].branch
                                : "NA"}
                            </div>
                            <div className="antaliased text-[#DADADA] text-xs opacity-100">
                              Branch
                            </div>
                          </div>
                          <div className="space-y-1 mt-2">
                            <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                              {bankUserInfo[0].city
                                ? bankUserInfo[0].city
                                : "NA"}
                            </div>
                            <div className="antaliased text-[#DADADA] text-xs opacity-100">
                              City
                            </div>
                          </div>
                          <div className="space-y-1 mt-2">
                            <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base truncate ...">
                              {bankUserInfo[0].district
                                ? bankUserInfo[0].district
                                : "NA"}
                            </div>
                            <div className="antaliased text-[#DADADA] text-xs opacity-100">
                              District
                            </div>
                          </div>
                          <div className="space-y-1 mt-2">
                            <div
                              className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base"
                              style={{
                                textWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                            >
                              {bankUserInfo[0].address
                                ? bankUserInfo[0].address
                                : "NA"}
                            </div>
                            <div className="antaliased text-[#DADADA] text-xs opacity-100">
                              Address
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dividecon flex flex-col gap-5">
          <div className="flex flex-col bg-[#29487E] py-5 rounded-[30px] gap-4 px-8">
            <div>
              <div>
                <div className="text-[#DADADA] self-center font-semibold text-base">
                  Beneficiary's Contact Details
                </div>
              </div>
              <div className="rounded-b-large flex flex-col drop-shadow-xl gap-5 pt-5 pb-5">
                <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-x-7">
                      <div className="w-5 mt-2">
                        <img src={User} alt="User" />
                      </div>
                      <div className="w-full antaliased text-[#F9F9F9] capitalize pb-0.5 self-center font-normal text-base">
                        <p className="w-[350px] overflow-hidden ">
                          <Tooltip
                            title={payload?.legalName}
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            arrow
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            {payload?.legalName?.toLowerCase()}
                          </Tooltip>
                        </p>
                      </div>
                    </div>
                    <div className="antaliased text-[#DADADA] pt-0.5 pl-11 text-xs opacity-100">
                      Beneficiary name - Using legal name
                    </div>
                  </div>
                </div>
                <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-x-7">
                      <div className="w-5 mt-2">
                        <img src={Contact} alt="Contact" />
                      </div>
                      <div className="w-full antaliased text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base">
                        <p className="w-72 overflow-hidden truncate">
                          {"+91-"}
                          {payload?.contact_no}
                        </p>
                      </div>
                    </div>
                    <div className="antaliased text-[#DADADA] pt-0.5 pl-11 text-xs opacity-100">
                      Contact Number
                    </div>
                  </div>
                </div>

                <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-x-7">
                      <div className="w-5 mt-2">
                        <img src={Gmail} alt="Gmail" />
                      </div>
                      <div className="w-full antaliased text-[#F9F9F9] font-normal pb-0.5 self-center text-base">
                        <p
                          id="crt-bene-acc-email-id-firstLetter"
                          className="w-72 overflow-hidden truncate"
                        >
                          <Tooltip
                            title={payload?.email_id}
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            arrow
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            {payload?.email_id.toLowerCase()}
                          </Tooltip>
                        </p>
                      </div>
                    </div>
                    <div className="antaliased text-[#DADADA] pt-0.5 pl-11 text-xs opacity-100">
                      Email ID
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-80 overflow-hidden scrollbar-thumb-gray-400 scrollbar-track-gray-100 bg-[#29487E] rounded-[30px] gap-4">
            <div>
              <div className="py-5">
                <div className="text-[#DADADA] self-center font-semibold text-base px-8">
                  Assigned Beneficiary Categories
                </div>
              </div>
              <div className="h-56 overflow-auto py-5 px-8">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-5">
                    {selectedFields.map((categoryName, index) => (
                      <div
                        key={index}
                        className="bencategory bg-[#506994] rounded-[15px] opacity-100 flex flex-row"
                      >
                        <div className="categories-label-iocn">
                          <img
                            className="w-9 h-9 cateEditImage"
                            src={BeneIcon}
                            alt="bene"
                          />
                        </div>
                        <p className="text-[#DADADA] text-base py-2.5 px-3 confirmCategoryName">
                          <Tooltip
                            title={categoryName}
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            arrow
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            {categoryName}
                          </Tooltip>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneConfirmAccount;
