import React, { useState, useEffect } from "react";
import CumulativeStatements from "../../../../assets/landingPage/CumulativeStatements.svg";
import AutomatedStatementRetrieval from "../../../../assets/landingPage/AutomatedStatementRetrieval.svg";
import CentralisedAccess from "../../../../assets/landingPage/CentralisedAccess.svg";
import CustomisableReporting from "../../../../assets/landingPage/CustomisableReporting.svg";
import EnhancedFinancialClarity from "../../../../assets/landingPage/EnhancedFinancialClarity.svg";
import EnhancedFinancialClarityMob from "../../../../assets/landingPage/EnhancedFinancialClarityMob.svg";
import IncreasedEfficiency from "../../../../assets/landingPage/IncreasedEfficiency.svg";
import IncreasedEfficiencyMob from "../../../../assets/landingPage/IncreasedEfficiencyMob.svg";
import ImprovedCustomisation from "../../../../assets/landingPage/ImprovedCustomisation.svg";
import ImprovedCustomisationMob from "../../../../assets/landingPage/ImprovedCustomisationMob.svg";
import BetterDataControl from "../../../../assets/landingPage/BetterDataControl.svg";
import BetterDataControlMob from "../../../../assets/landingPage/BetterDataControlMob.svg";
import TimeResourceSavings from "../../../../assets/landingPage/TimeResourceSavings.svg";
import TimeResourceSavingsMob from "../../../../assets/landingPage/TimeResourceSavingsMob.svg";
import EnhancedDecisionMaking from "../../../../assets/landingPage/EnhancedDecisionMaking.svg";
import EnhancedDecisionMakingMob from "../../../../assets/landingPage/EnhancedDecisionMakingMob.svg";
import ReducedRiskOfErrors from "../../../../assets/landingPage/ReducedRiskOfErrors.svg";
import ReducedRiskOfErrorsMob from "../../../../assets/landingPage/ReducedRiskOfErrorsMob.svg";
import BankIntegration from "../../../../assets/landingPage/BankIntegration.svg";
import IntegrationBank from "../../../../assets/landingPage/IntegrationBank.svg";
import UnifiedDashboard from "../../../../assets/landingPage/UnifiedDashboard.svg";
import InstantAccess from "../../../../assets/landingPage/InstantAccess.svg";
import Navbar from "../../../../components/LandingPage/Navbar";
import Footer from "../../../../components/LandingPage/Footer";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Product = () => {
  const [activeTab, setActiveTab] = useState("Bank Statements");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const keyFeaturesSections = [
    {
      img: EnhancedFinancialClarityMob,
      title: "Improved financial control",
      description: `With a single dashboard consolidating all balances,
        you gain better control over your cash flow and
        liquidity management, allowing you to allocate
        resources more efficiently.`,
    },
    {
      img: TimeResourceSavingsMob,
      title: "Time and resource savings",
      description:
        "No more jumping between multiple banking portals—Xpentra centralises all your accounts,saving time and reducing administrative workload.",
    },
    {
      img: EnhancedDecisionMakingMob,
      title: "Enhanced decision-making",
      description:
        "Real-time balance visibility on a single platform empowers your team to make quick, informed financial decisions, whether it’s approving payments, managing liquidity, or planning investments.",
    },
    {
      img: ReducedRiskOfErrorsMob,
      title: "Reduced risk of errors",
      description:
        "Automation and integration eliminate the risks associated with manual processes, ensuring that your financial data is always accurate and up-to-date.",
    },
  ];

  const keyFeaturesSections2 = [
    {
      img: EnhancedFinancialClarityMob,
      title: "Enhanced financial clarity",
      description: `With cumulative statements and custom narration, you can get a clearer, more organised view of your financial activities, making it easier to track and analyse transactions across multiple accounts.`,
    },
    {
      img: IncreasedEfficiencyMob,
      title: "Increased efficiency",
      description:
        "Centralised access to bank statements across different banks saves time and reduces the administrative burden of logging into multiple portals.",
    },
    {
      img: ImprovedCustomisationMob,
      title: "Improved customisation",
      description:
        "Tailoring bank statements to your specific needs through custom narration provides more relevant data, making reporting and reconciliation more efficient.",
    },
    {
      img: BetterDataControlMob,
      title: "Better data control",
      description:
        "With automated statement retrieval and consolidated downloads, you ensure that your financial data is always up-to-date and ready for analysis or audits.",
    },
  ];

  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(calc(50% - ${
      currentIndex * (240 + 20) + 120
    }px))`;
    setPausedTransform(currentTransform);
    // alert("hii");
  };

  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
    // alert("hello");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) =>
          prevIndex === keyFeaturesSections.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isPaused, keyFeaturesSections.length, intervalTime]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: isDarkSectionActive ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <Navbar onToggleDarkSection={setIsDarkSectionActive} />
              <div className="p-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1d3a6d]">
                <div className="text-lg font-semibold">
                  Unified Financial Insights
                </div>
                <div className="text-sm font-normal">
                  Xpentra is a technology-driven platform designed to provide
                  CFOs, COOs, and other CXOs with a unified interface that
                  aggregates essential financial data and insights. By
                  consolidating information across banking functions, Xpentra
                  enables faster, more informed decision-making. With real-time
                  data, customizable reporting, and seamless bank integrations,
                  Xpentra offers a comprehensive view that simplifies complex
                  workflows and enhances operational control—empowering
                  leadership to drive strategy with greater accuracy and
                  agility.
                </div>
              </div>

              <div
                className="py-5 px-[30px] sm:px-[30px]"
                style={{
                  background:
                    "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
                }}
              >
                <p className="text-[#F9F9F9] text-[16px] font-semibold mb-[20px] text-left sm:text-center">
                  Product <span className="text-[#31E5E5]">features</span>
                </p>
                <div className="relative overflow-hidden h-auto w-full gap-0 sm:gap-5">
                  <div className="flex justify-center gap-4 sm:justify-center sm:gap-4 sm:py-2 mb-[20px]">
                    <button
                      className={`px-6 py-3 rounded-[21px] text-xs font-semibold ${
                        activeTab === "Bank Statements"
                          ? "bg-[#506994] text-[#F9F9F9]"
                          : "bg-[#314D7E] text-[#f9f9f9]"
                      } hover:bg-[#506994]`}
                      onClick={() => setActiveTab("Bank Statements")}
                    >
                      Bank Statements
                    </button>
                    <button
                      className={`px-6 py-3 rounded-[21px] text-xs font-semibold ${
                        activeTab === "View All Balances"
                          ? "bg-[#506994] text-[#F9F9F9]"
                          : "bg-[#314D7E] text-[#f9f9f9]"
                      } hover:bg-[#506994]`}
                      onClick={() => setActiveTab("View All Balances")}
                    >
                      View All Balances
                    </button>
                  </div>

                  {activeTab === "Bank Statements" ? (
                    <>
                      <div className="flex gap-5 flex-col">
                        <div className="flex flex-col gap-[4px]">
                          <p className="font-semibold text-[16px] leading-[21.79px] text-left text-[#F9F9F9] sm:text-center sm:text-[28px] sm:leading-[normal]">
                            <span className="text-[#31E5E5]">
                              Bank statements: &nbsp;
                            </span>
                            Comprehensive financial insights in one place
                          </p>
                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Managing bank statements across multiple accounts
                            and banks can be tedious and time-consuming for
                            businesses. Xpentra’s Download Statements feature
                            solves this by providing consolidated, customisable,
                            and easily accessible statements that simplify
                            financial tracking and analysis. With advanced
                            options like custom narration and cumulative
                            statements, businesses can gain better control over
                            their finances.
                          </p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            The problems
                          </div>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Fragmented statements across banks
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Businesses often have multiple accounts with
                            different banks, making it difficult to consolidate
                            statements and gain a clear financial overview.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Time-consuming manual reconciliation
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Gathering and reconciling statements manually from
                            various bank portals can slow down operations and
                            increase the risk of errors.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Lack of customisation in statements
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Traditional bank statements are often rigid,
                            offering little flexibility in terms of
                            personalisation, making it harder to extract
                            meaningful insights.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Multiple logins for different bank portals
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Accessing and managing statements from multiple
                            banks requires logging into each bank’s portal,
                            which is inefficient and prone to errors.
                          </p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            The Xpentra solution
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Xpentra’s Bank Statements feature offers a unified
                            platform to access and manage your banks statements
                            from multiple accounts and banks. With custom
                            narration options and the ability to download
                            cumulative statements, Xpentra makes financial
                            oversight and reconciliation easier than ever.
                          </p>
                        </div>

                        <div className="flex gap-4 flex-wrap justify-center mx-auto">
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={CustomisableReporting}
                                alt="CustomisableReporting"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Custom narration
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Personalise your bank statements with custom
                                narration, allowing you to tailor the data for
                                specific reporting or analysis purposes. These
                                customised statements are also viewable directly
                                within each bank’s e-net portal.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={CumulativeStatements}
                                alt="CumulativeStatements"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Cumulative statements
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Download statements that consolidate data across
                                multiple banks and accounts, providing a
                                holistic view of your financial activities in
                                one document.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={AutomatedStatementRetrieval}
                                alt="AutomatedStatementRetrieval"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Automated statement retrieval
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Get statements delivered automatically, ensuring
                                you have up-to-date financial records without
                                the hassle of manual downloads.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={CentralisedAccess}
                                alt="CentralisedAccess"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Centralised access
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Xpentra integrates with multiple banks, allowing
                                you to access all your statements from a single
                                platform, eliminating the need to log into
                                different banking portals.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="text-[#F9F9F9] text-[16px] font-semibold leading-5 text-left sm:text-center">
                          Why this is{" "}
                          <span className="text-[#31E5E5]">useful</span>
                        </div>

                        <div>
                          <div className="relative overflow-hidden w-full">
                            <div
                              className="flex transition-transform duration-700 ease-in-out gap-[20px]"
                              style={{
                                transform: isPaused
                                  ? pausedTransform
                                  : `translateX(calc(50% - ${
                                      currentIndex * (240 + 20) + 120
                                    }px))`,
                                transition: isPaused
                                  ? "none"
                                  : "transform 700ms ease-in-out",

                                transform: `translateX(calc(50% - ${
                                  currentIndex * (240 + 20) + 120
                                }px))`,
                              }}
                              onMouseOver={handleMouseOver}
                              onMouseOut={handleMouseOut}
                            >
                              {keyFeaturesSections2.map((section, index) => (
                                <div
                                  key={index}
                                  className="max-w-[300px] flex-shrink-0 rounded-[10px] mt-[30px]"
                                >
                                  <div
                                    className="relative text-[#F9F9F9] text-center"
                                    style={{
                                      backgroundImage: `url(${section.img})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      width: "240px",
                                      height: "230px",
                                    }}
                                  >
                                    <div className="card-content text-[#F9F9F9] absolute bottom-[10px]">
                                      <h2 className="text-[16px] font-semibold">
                                        {section.title}
                                      </h2>
                                      <p className="text-[12px] font-normal h-[130px]">
                                        {section.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                            {keyFeaturesSections2.map((_, index) => (
                              <div
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                className={`rounded-[18px] cursor-pointer bg-white h-[4px] transition-all duration-500 ease-in-out ${
                                  isPaused
                                    ? index === currentIndex
                                      ? "w-[80px] opacity-100" // Active indicator when paused
                                      : "w-[40px] opacity-50" // Inactive indicators
                                    : index === currentIndex
                                    ? "w-[80px] opacity-100" // Active indicator when active
                                    : "w-[40px] opacity-50" // Inactive indicators
                                }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex gap-5 flex-col">
                        <div className="flex flex-col gap-[4px]">
                          <p className="font-semibold text-[16px] leading-[21.79px] text-left text-[#F9F9F9] sm:text-center sm:text-[28px] sm:leading-[normal]">
                            <span className="text-[#31E5E5]">
                              View all balances: &nbsp;
                            </span>
                            Simplifying financial oversight
                          </p>
                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Managing multiple accounts across various banks can
                            be a daunting task for businesses. Ensuring
                            real-time visibility into each account’s balance is
                            crucial for making informed decisions, yet many
                            companies struggle with fragmented financial
                            information. Xpentra’s View All Balances feature
                            addresses these challenges by providing a
                            centralised, real-time view of all your balances on
                            a single dashboard, enabling seamless financial
                            management.
                          </p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            The problems
                          </div>
                        </div>
                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Fragmented account visibility
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Businesses often have multiple accounts with
                            different banks, making it difficult to consolidate
                            statements and gain a clear financial overview.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Manual and time-consuming processes
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Logging into multiple banking portals to retrieve
                            balance information is inefficient, consuming time
                            and resources that could be better spent elsewhere.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Delayed decision- making
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Without up-to-date balance visibility, businesses
                            may face delays in making crucial financial
                            decisions, affecting liquidity management and
                            operational efficiency.
                          </p>
                        </div>

                        <div className="border border-solid border-[#31E5E5]"></div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            Errors and oversights
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Relying on manual tracking across multiple banks
                            increases the risk of errors, leading to inaccurate
                            financial data, which can disrupt planning.
                          </p>
                        </div>

                        <div className="flex flex-col gap-[4px]">
                          <div className="text-[#F9F9F9] text-[16px] leading-[21.79px] text-left sm:text-center font-semibold">
                            The Xpentra solution
                          </div>

                          <p className="text-xs text-left text-[#F9F9F9] sm:text-center">
                            Xpentra’s View All Balances feature delivers a
                            consolidated, real-time view of all your accounts
                            across multiple banks, accessible through a single,
                            unified dashboard. This powerful solution
                            streamlines the account management process and
                            ensures critical financial data is always at your
                            fingertips.
                          </p>
                        </div>

                        <div className="flex gap-4 flex-wrap justify-center mx-auto">
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={IntegrationBank}
                                alt="IntegrationBank"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Bank integration
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Through secure API connections, Xpentra
                                integrates with your banks, pulling in real-time
                                balance data from each account.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={UnifiedDashboard}
                                alt="UnifiedDashboard"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Unified dashboard
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                View all your accounts across different banks on
                                a single, centralised dashboard, eliminating the
                                need for manual checks or multiple logins.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img src={InstantAccess} alt="InstantAccess" />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Instant access
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Xpentra offers immediate balance updates,
                                ensuring that your financial data is always
                                current and readily available for
                                decision-making.
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col gap-4 h-auto w-full xs:max-w-[300px] rounded-[20px] p-[20px]"
                            style={{ border: "1px solid #31E5E5" }}
                          >
                            <div className="h-[40px] w-[40px] rounded-[50%]">
                              <img
                                src={CustomisableReporting}
                                alt="CustomisableReporting"
                              />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                              <p className="text-[#F9F9F9] text-[16px] font-semibold">
                                Customisable reporting
                              </p>
                              <p className="text-[#F9F9F9] text-[12px]">
                                Easily generate reports that provide a breakdown
                                of balances by account, bank, or other filters,
                                offering deeper insights into your financial
                                position.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="text-[#F9F9F9] text-[16px] font-semibold leading-5 text-left sm:text-center">
                          Why this is{" "}
                          <span className="text-[#31E5E5]">useful</span>
                        </div>

                        <div>
                          <div className="relative overflow-hidden w-full">
                            <div
                              className="flex transition-transform duration-700 ease-in-out gap-[20px]"
                              style={{
                                transform: isPaused
                                  ? pausedTransform
                                  : `translateX(calc(50% - ${
                                      currentIndex * (240 + 20) + 120
                                    }px))`,
                                transition: isPaused
                                  ? "none"
                                  : "transform 700ms ease-in-out",

                                transform: `translateX(calc(50% - ${
                                  currentIndex * (240 + 20) + 120
                                }px))`,
                              }}
                              onMouseOver={handleMouseOver}
                              onMouseOut={handleMouseOut}
                            >
                              {keyFeaturesSections.map((section, index) => (
                                <div
                                  key={index}
                                  className="max-w-[300px] flex-shrink-0 rounded-[10px] mt-[30px]"
                                >
                                  <div
                                    className="relative text-[#F9F9F9] text-center"
                                    style={{
                                      backgroundImage: `url(${section.img})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      width: "240px",
                                      height: "230px",
                                    }}
                                  >
                                    <div className="card-content text-[#F9F9F9] absolute bottom-[10px]">
                                      <h2 className="text-[16px] font-semibold">
                                        {section.title}
                                      </h2>
                                      <p className="text-[12px] font-normal h-[130px]">
                                        {section.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                            {keyFeaturesSections.map((_, index) => (
                              <div
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                className={`rounded-[18px] cursor-pointer bg-white h-[4px] transition-all duration-500 ease-in-out ${
                                  isPaused
                                    ? index === currentIndex
                                      ? "w-[80px] opacity-100" // Active indicator when paused
                                      : "w-[40px] opacity-50" // Inactive indicators
                                    : index === currentIndex
                                    ? "w-[80px] opacity-100" // Active indicator when active
                                    : "w-[40px] opacity-50" // Inactive indicators
                                }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        ) : (
          <>
            <Navbar />
            <div className="flex relative mt-[60px]">
              <div className="h-[492px] max-w-[1440px] w-screen flex justify-between mx-auto relative">
                <div className="w-[50%] pl-[125px] flex flex-col justify-center">
                  <p
                    className="text-[#F9F9F9] text-[32px] font-semibold"
                    style={{ lineHeight: "1.2" }}
                  >
                    Unified Financial Insights
                  </p>
                  <p className="text-[#F9F9F9] text-[14px] mt-[10px]">
                    Xpentra is a technology-driven platform designed to provide
                    CFOs, COOs, and other CXOs with a unified interface that
                    aggregates essential financial data and insights. By
                    consolidating information across banking functions, Xpentra
                    enables faster, more informed decision-making. With
                    real-time data, customizable reporting, and seamless bank
                    integrations, Xpentra offers a comprehensive view that
                    simplifies complex workflows and enhances operational
                    control—empowering leadership to drive strategy with greater
                    accuracy and agility.
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                background:
                  "transparent linear-gradient(270deg, #031F4F 0%,#224074 25%, #224074 75%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div className="max-w-[1024px] mx-auto py-[20px] flex flex-col gap-5">
                <div>
                  <p className="text-[28px] text-[#F9F9F9] text-center font-semibold">
                    Product &nbsp;
                    <span className="text-[#31E5E5]">features</span>
                  </p>

                  <div className="flex justify-center gap-4 mt-[30px]">
                    <button
                      className={`px-7 py-3 rounded-[21px] text-xs font-semibold ${
                        activeTab === "Bank Statements"
                          ? "bg-[#506994] text-[#F9F9F9]"
                          : "bg-[#314D7E] text-[#f9f9f9]"
                      } hover:bg-[#506994]`}
                      onClick={() => setActiveTab("Bank Statements")}
                    >
                      Bank Statements
                    </button>
                    <button
                      className={`px-7 py-3 rounded-[21px] text-xs font-semibold ${
                        activeTab === "View All Balances"
                          ? "bg-[#506994] text-[#F9F9F9]"
                          : "bg-[#314D7E] text-[#f9f9f9]"
                      } hover:bg-[#506994]`}
                      onClick={() => setActiveTab("View All Balances")}
                    >
                      View All Balances
                    </button>
                  </div>
                </div>

                {activeTab === "Bank Statements" ? (
                  <>
                    <p className="text-[28px] text-center font-semibold text-[#F9F9F9]">
                      <span className="text-[#31E5E5]">
                        Bank statements: &nbsp;
                      </span>
                      Comprehensive financial insights in one place
                    </p>
                    <p className="text-xs text-center text-[#F9F9F9]">
                      Managing bank statements across multiple accounts and
                      banks can be tedious and time-consuming for businesses.
                      Xpentra’s Download Statements feature solves this by
                      providing consolidated, customisable, and easily
                      accessible statements that simplify financial tracking and
                      analysis. With advanced options like custom narration and
                      cumulative statements, businesses can gain better control
                      over their finances.
                    </p>
                    <div className="text-[#F9F9F9] text-center font-semibold text-[28px] mt-5">
                      The problems
                    </div>
                    <div className="flex p-5">
                      <div className="flex-1 pr-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] text-base font-semibold">
                          Fragmented statements across banks
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Businesses often have multiple accounts with different
                          banks, making it difficult to consolidate statements
                          and gain a clear financial overview.
                        </p>
                      </div>

                      <div className="flex-1 px-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] text-base font-semibold">
                          Time-consuming manual reconciliation
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Gathering and reconciling statements manually from
                          various bank portals can slow down operations and
                          increase the risk of errors.
                        </p>
                      </div>

                      <div className="flex-1 px-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] text-base font-semibold">
                          Lack of customisation in statements
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Traditional bank statements are often rigid, offering
                          little flexibility in terms of personalisation, making
                          it harder to extract meaningful insights.
                        </p>
                      </div>

                      <div className="flex-1 pl-5">
                        <p className="text-[#F9F9F9] text-base font-semibold">
                          Multiple logins for different bank portals
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Accessing and managing statements from multiple banks
                          requires logging into each bank’s portal, which is
                          inefficient and prone to errors.
                        </p>
                      </div>
                    </div>
                    <div className="text-[#F9F9F9] text-center font-semibold text-[28px]">
                      The Xpentra solution
                    </div>
                    <p className="text-[#f9f9f9] text-xs text-center">
                      Xpentra’s Bank Statements feature offers a unified
                      platform to access and manage your banks statements from
                      multiple accounts and banks. With custom narration options
                      and the ability to download cumulative statements, Xpentra
                      makes financial oversight and reconciliation easier than
                      ever.
                    </p>
                    <div className="flex flex-col gap-4">
                      <div className="flex gap-4 max-w-[1024px] mx-auto">
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={CustomisableReporting}
                              alt="CustomisableReporting"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Custom narration
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Personalise your bank statements with custom
                              narration, allowing you to tailor the data for
                              specific reporting or analysis purposes. These
                              customised statements are also viewable directly
                              within each bank’s e-net portal.
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={CumulativeStatements}
                              alt="CumulativeStatements"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Cumulative statements
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Download statements that consolidate data across
                              multiple banks and accounts, providing a holistic
                              view of your financial activities in one document.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-4 max-w-[1024px] mx-auto">
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={AutomatedStatementRetrieval}
                              alt="AutomatedStatementRetrieval"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Automated statement retrieval
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Get statements delivered automatically, ensuring
                              you have up-to-date financial records without the
                              hassle of manual downloads.
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={CentralisedAccess}
                              alt="CentralisedAccess"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Centralised access
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Xpentra integrates with multiple banks, allowing
                              you to access all your statements from a single
                              platform, eliminating the need to log into
                              different banking portals.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-[#F9F9F9] text-center font-semibold text-[28px] mt-[20px] mb-[40px]">
                      Why this is useful
                    </div>
                    <div className="flex justify-between flex-row">
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${EnhancedFinancialClarity})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Enhanced financial clarity</h2>
                          <p className="h-[130px]">
                            With cumulative statements and custom narration, you
                            can get a clearer, more organised view of your
                            financial activities, making it easier to track and
                            analyse transactions across multiple accounts.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${IncreasedEfficiency})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Increased efficiency</h2>
                          <p className="h-[130px]">
                            Centralised access to bank statements across
                            different banks saves time and reduces the
                            administrative burden of logging into multiple
                            portals.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${ImprovedCustomisation})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Improved customisation</h2>
                          <p className="h-[130px]">
                            Tailoring bank statements to your specific needs
                            through custom narration provides more relevant
                            data, making reporting and reconciliation more
                            efficient.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${BetterDataControl})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Better data control</h2>
                          <p className="h-[130px]">
                            With automated statement retrieval and consolidated
                            downloads, you ensure that your financial data is
                            always up-to-date and ready for analysis or audits.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-[28px] font-semibold text-center text-[#F9F9F9]">
                      <span className="text-[#31E5E5]">
                        View all balances: &nbsp;
                      </span>
                      Simplifying financial oversight
                    </p>
                    <p className="text-xs text-center text-[#F9F9F9]">
                      Managing multiple accounts across various banks can be a
                      daunting task for businesses. Ensuring real-time
                      visibility into each account’s balance is crucial for
                      making informed decisions, yet many companies struggle
                      with fragmented financial information. Xpentra’s View All
                      Balances feature addresses these challenges by providing a
                      centralised, real-time view of all your balances on a
                      single dashboard, enabling seamless financial management.
                    </p>

                    <div className="text-[#F9F9F9] text-center font-semibold text-[28px] mt-5">
                      The problems
                    </div>
                    <div className="flex p-5">
                      <div className="flex-1 pr-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] text-base font-semibold">
                          Fragmented account visibility
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Businesses often have multiple accounts with different
                          banks, making it difficult to consolidate statements
                          and gain a clear financial overview.
                        </p>
                      </div>

                      <div className="flex-1 px-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] font-semibold">
                          Manual and time-consuming processes
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Logging into multiple banking portals to retrieve
                          balance information is inefficient, consuming time and
                          resources that could be better spent elsewhere.
                        </p>
                      </div>

                      <div className="flex-1 px-5 border-r-[1px] border-[#31E5E5]">
                        <p className="text-[#F9F9F9] font-semibold">
                          Delayed decision- <br /> making
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Without up-to-date balance visibility, businesses may
                          face delays in making crucial financial decisions,
                          affecting liquidity management and operational
                          efficiency.
                        </p>
                      </div>

                      <div className="flex-1 pl-5">
                        <p className="text-[#F9F9F9] font-semibold">
                          Errors and <br /> oversights
                        </p>
                        <p className="text-[#f9f9f9] text-xs pt-4">
                          Relying on manual tracking across multiple banks
                          increases the risk of errors, leading to inaccurate
                          financial data, which can disrupt planning.
                        </p>
                      </div>
                    </div>

                    <div className="text-[#F9F9F9] font-semibold text-[28px] text-center">
                      The Xpentra solution
                    </div>
                    <p className="text-[#f9f9f9] text-xs text-center">
                      Xpentra’s View All Balances feature delivers a
                      consolidated, real-time view of all your accounts across
                      multiple banks, accessible through a single, unified
                      dashboard. This powerful solution streamlines the account
                      management process and ensures critical financial data is
                      always at your fingertips.
                    </p>

                    <div className="flex flex-col gap-4">
                      <div className="flex gap-4 max-w-[1024px] mx-auto">
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={IntegrationBank}
                              alt="IntegrationBank"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Bank integration
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Through secure API connections, Xpentra integrates
                              with your banks, pulling in real-time balance data
                              from each account.
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={UnifiedDashboard}
                              alt="UnifiedDashboard"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Unified dashboard
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              View all your accounts across different banks on a
                              single, centralised dashboard, eliminating the
                              need for manual checks or multiple logins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-4 max-w-[1024px] mx-auto">
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={InstantAccess}
                              alt="InstantAccess"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Instant access
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Xpentra offers immediate balance updates, ensuring
                              that your financial data is always current and
                              readily available for decision-making.
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                          style={{ border: "1px solid #31E5E5" }}
                        >
                          <div className="h-[60px] w-[60px] rounded-[50%]">
                            <img
                              className="h-[60px] w-[60px]"
                              src={CustomisableReporting}
                              alt="CustomisableReporting"
                            />
                          </div>
                          <div className="flex flex-col gap-[5px]">
                            <p className="text-[#F9F9F9] text-[16px] font-semibold">
                              Customisable reporting
                            </p>
                            <p className="text-[#F9F9F9] text-[12px]">
                              Easily generate reports that provide a breakdown
                              of balances by account, bank, or other filters,
                              offering deeper insights into your financial
                              position.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-[#F9F9F9] text-center font-semibold text-[28px] mt-[20px] mb-[40px]">
                      Why this is useful
                    </div>
                    <div className="flex justify-between flex-row">
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${EnhancedFinancialClarity})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Improved financial control</h2>
                          <p className="h-[130px]">
                            With a single dashboard consolidating all balances,
                            you gain better control over your cash flow and
                            liquidity management, allowing you to allocate
                            resources more efficiently.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${TimeResourceSavings})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Time and resource savings</h2>
                          <p className="h-[130px]">
                            No more jumping between multiple banking
                            portals—Xpentra centralises all your accounts,
                            saving time and reducing administrative workload.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${EnhancedDecisionMaking})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Enhanced decision-making</h2>
                          <p className="h-[130px]">
                            Real-time balance visibility on a single platform
                            empowers your team to make quick, informed financial
                            decisions, whether it’s approving payments, managing
                            liquidity, or planning investments.
                          </p>
                        </div>
                      </div>
                      <div
                        className="relative text-[#F9F9F9] text-center rounded-[10px] border-b-[#31E5E5]"
                        style={{
                          backgroundImage: `url(${ReducedRiskOfErrors})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "240px",
                          height: "230px",
                        }}
                      >
                        <div className="card-content absolute bottom-0">
                          <h2>Reduced risk of errors</h2>
                          <p className="h-[130px]">
                            Automation and integration eliminate the risks
                            associated with manual processes, ensuring that your
                            financial data is always accurate and up-to-date.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default Product;
