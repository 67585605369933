import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Box, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import FilterBlue from "../../../assets/OwnerAccount/filterblue.png";
import Selected from "../../../assets/Approve transactions/selected.png";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowUp4 from "../../../assets/OwnerAccount/arrowdown4png.png";
import GridBlue from "../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../assets/OwnerAccount/gridview-white.png";
import ArrowDown from "../../../assets/OwnerAccount/arrowdown1.png";
import ArrowUp from "../../../assets/OwnerAccount/arrowup.png";
import ArrowDown8 from "../../../assets/OwnerAccount/arrowdown8.png";
import ListBlue from "../../../assets/OwnerAccount/listview-blue.png";
import closeBtnImg from "../../../assets/CategoryFilterIcons/cross.png";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import NextIcon from "../../../assets/Beneficiary Icons/NextIcon.png";
import BeneficiaryMultiApprovalTxn from "../../../components/BeneficiaryMultiApprovalTxn";
import { BeneTxnRequireApprovalPage } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { useDispatch, useSelector } from "react-redux";
import NoAccountFound from "../../../assets/Beneficiary Icons/no_account_approved.png";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import Filter from "../../../assets/Approve transactions/filter.png";
import FiterNoAccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import SelectWhite from "../../../assets/Approve transactions/SelectWhite.png";
import { useNavigate } from "react-router-dom";
import SelectCategoryTransaction from "../../../components/_utils/SelectCategoryTransaction";
import { checkUsertoken } from "../../../redux/features/login/login";

const BeneTxnMultiApproval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initCategory, setInitCategory] = useState("");
  const [toggle, setToggle] = useState(true);
  const [label, setlabel] = useState("Select category");
  const [filterOpen, setFilterOpen] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [page, setPage] = useState(1);
  const [selectAllCard, setselectAllCard] = useState(false);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 250 : window.innerWidth >= 2560 ? 102 : 50
  );
  const [approvedAccounts, setApprovedAccounts] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedSendTransactions, setSelectedSendTransactions] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [ShowFilterErrorPage, setShowFilterErrorPage] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const filterRef = useRef(null);

  const { BeneTxnRequireApprovalData } = useSelector(
    (state) => state.beneTxnViewAccData
  );

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    if (label === "Select category") {
      if (page === 1) {
        setAllBeneTxnAccountsLoading(true);
      }
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          page_size: pageSize,
        })
      )
        .then((response) => {
          const newData =
            response?.payload?.transactions?.map((account) => ({
              ...account,
              loading: false,
              error: "false",
              message: "",
            })) || [];

          setAllAccounts((prevAccounts) => {
            if (newData.length > 0) {
              return [...prevAccounts, ...newData];
            } else {
              console.log("no data found");
              return prevAccounts;
            }
          });

          setAllBeneTxnAccountsLoading(false);

          const approved = newData.filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved"
          );
          setApprovedAccounts((prevApproved) => [...prevApproved, ...approved]);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    } else if (label !== "Select category" && page !== 1) {
      // if (page !== 1) {
      //   setAllBeneTxnAccountsLoading(true);
      // }
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: label !== "Select category" ? label : undefined,
          page_size: pageSize,
        })
      )
        .then((response) => {
          const newData =
            response?.payload?.transactions?.map((account) => ({
              ...account,
              loading: false,
              error: "false",
              message: "",
            })) || [];

          setAllAccounts((prevAccounts) => {
            if (newData.length > 0) {
              return [...prevAccounts, ...newData];
            } else {
              console.log("no data found");
              return prevAccounts;
            }
          });

          setAllBeneTxnAccountsLoading(false);

          const approved = newData.filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved"
          );
          setApprovedAccounts((prevApproved) => [...prevApproved, ...approved]);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    }
  }, [page]);

  const handleSingleApprovalClick = () => {
    navigate(
      "/transaction/beneficiary_transaction/transaction_single_approval"
    );
  };
  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(250);
    }
  }

  const handleFilterCategory = () => {
    setCurrentCategory(label);
    setAllAccounts([]);

    if (page === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    dispatch(
      BeneTxnRequireApprovalPage({
        page_no: page,
        page_size: pageSize,
        category: label !== "Select category" ? label : undefined,
      })
    ).then((response) => {
      setAllAccounts(response?.payload?.transactions);

      setShowFilterErrorPage(true);
      setAllBeneTxnAccountsLoading(false);
      const approved = response?.payload?.transactions.filter(
        (account) => account.beneficiary_account_approval_status === "approved"
      );
      setApprovedAccounts(approved);
    });
    setInitCategory(false);
    setFilterOpen(false);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleClearCategory = () => {
    setlabel("Select category");
    setCurrentCategory("");
    setInitCategory("");
    setAllBeneTxnAccountsLoading(true);
    setPage(1);
    if (label !== "Select category") {
      dispatch(checkUsertoken());
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          page_size: pageSize,
          category: undefined,
        })
      ).then((response) => {
        setAllAccounts(response?.payload?.transactions);
        setAllBeneTxnAccountsLoading(false);
        setShowFilterErrorPage(false);
        const approved = response?.payload?.transactions.filter(
          (account) =>
            account.beneficiary_account_approval_status === "approved"
        );
        setApprovedAccounts(approved);
      });
    }
  };

  const handleSelectAll = (tt) => {
    const alreadySelectedCount = selectedTransactions?.length;
    const remainingSelections = 20 - alreadySelectedCount;
    if (tt) {
      let x = [];
      approvedAccounts.map((itm) => {
        if (selectedTransactions.includes(itm.txn_id)) {
          x.push(itm.txn_id);
        }
      });
      let y = selectedTransactions.filter((it) => !x.includes(it));
      let z = selectedSendTransactions.filter((itm) => !x.includes(itm.txn_id));
      setSelectedTransactions(y);
      setSelectedSendTransactions(z);
      let amt = z.reduce((sum, account) => sum + Number(account.amount), 0);
      setTotalAmount(amt);
    } else if (label === "Select category" && remainingSelections === 0) {
      setSelectedTransactions([]);
      setSelectedSendTransactions([]);
      setTotalAmount(0);
    } else {
      if (remainingSelections > 0) {
        const newSelections = allAccounts
          .filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved" &&
              !selectedTransactions.includes(account.txn_id)
          )
          .slice(0, remainingSelections)
          .map((account) => account.txn_id);

        const newSelectionsSend = allAccounts
          .filter(
            (account) =>
              account.beneficiary_account_approval_status === "approved" &&
              !selectedTransactions.includes(account.txn_id)
          )
          .slice(0, remainingSelections)
          .map((account) => account);

        setSelectedTransactions((prevSelected) => [
          ...prevSelected,
          ...newSelections,
        ]);

        setSelectedSendTransactions((prevSelected) => [
          ...prevSelected,
          ...newSelectionsSend,
        ]);

        const newAmounts = allAccounts
          .filter((account) => newSelections.includes(account.txn_id))
          .reduce((sum, account) => sum + Number(account.amount), 0);

        setTotalAmount((prevAmount) => prevAmount + newAmounts);
      } else {
        let x = [];
        approvedAccounts.map((itm) => {
          if (selectedTransactions.includes(itm.txn_id)) {
            x.push(itm.txn_id);
          }
        });
        let y = selectedTransactions.filter((it) => !x.includes(it));
        let z = selectedSendTransactions.filter(
          (itm) => !x.includes(itm.txn_id)
        );
        setSelectedTransactions(y);
        setSelectedSendTransactions(z);
        let amt = z.reduce((sum, account) => sum + Number(account.amount), 0);
        setTotalAmount(amt);
      }
    }
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  return (
    <div
      className={`${
        filterOpen ? "opacity-100" : "opacity-100"
      }shadow_MultiApproval_screen bg-custom-appBack flex flex-col h-screen`}
      id=" benetxnmain "
      style={{
        background: filterOpen ? "black" : "",
      }}
    >
      <div className="BenetxnMultiApprovalNavbar">
        <div className="beneTxnMultiApprovalSec1">
          <div className="beneTxnMultiApprovalSec2">
            <div className="w-[1024px] h-[210px] flex justify-between  ">
              <div className="flex    w-[236px] ">
                <div className="flex  items-center  flex-col  w-[102px] h-[210px] bg-[#506994] ">
                  <div className=" mt-[125px] bg-[#7286A7]  text-[#FFFFFF] rounded-[50%] text-[14px] w-[40px] h-[40px]">
                    <p className="flex justify-center items-center text-[26px]">
                      {selectedTransactions.length}
                    </p>
                  </div>
                  <span className=" flex items-center mt-[6px] w-[40px]  text-[11px] font-normal leading-[14px] text-[#FFFFFF]">
                    Selected
                  </span>
                </div>
                <div className="min-w-0 flex w-auto justify-center flex-col gap-[4px] ml-[20px]">
                  <p className=" inline-flex mt-[110px]    h-[22px] text-left text-[16px] font-semibold leading-[22px] text-[#4DE659] whitespace-nowrap">
                    ₹ {<FormatAmount price={totalAmount} />}
                  </p>
                  <span className="w-[114px] h-[17px] text-left text-[12px] font-normal leading-[17px] text-[#DADADA]">
                    Transaction Amount
                  </span>
                </div>
              </div>
              <div className="flex justify-between  w-[251px]">
                <div className=" flex w-[156px]   justify-center items-center mt-[80px] pr-[40px]">
                  <div className="Multifilter_div_container">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Filters"
                    >
                      <div
                        style={{
                          backgroundColor:
                            currentCategory !== "" ? "#F9F9F9" : "#1d3a6d ",
                          opacity:
                            ShowFilterErrorPage || allAccounts?.length >= 1
                              ? "1"
                              : "0.5",
                          cursor:
                            allAccounts?.length === 0 ? "auto" : "pointer",
                        }}
                        className="filter-main"
                        onClick={() => {
                          if (ShowFilterErrorPage && allAccounts.length === 0) {
                            setFilterOpen(!filterOpen);
                            setInitCategory(false);
                          } else if (allAccounts.length === 0) {
                            setFilterOpen(filterOpen);
                            setInitCategory(false);
                          } else if (
                            allAccounts?.length === 0 ||
                            allAccounts?.length > 0
                          ) {
                            setFilterOpen(!filterOpen);
                            setInitCategory(false);
                          }
                        }}
                      >
                        <Box className="filters-box">
                          <img
                            src={currentCategory !== "" ? FilterBlue : Filter}
                            alt=""
                            className="filter-images-element"
                          />
                        </Box>
                        <Box className="filter-box ">
                          <img
                            src={
                              filterOpen
                                ? currentCategory !== ""
                                  ? ArrowUp4
                                  : ArrowUp
                                : currentCategory !== ""
                                ? ArrowDown8
                                : ArrowDown
                            }
                            alt=""
                            className="filter-images-element"
                          />
                        </Box>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="Multi_toggle-containers">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Single Approval"
                    >
                      <Box
                        onClick={() => {
                          handleSingleApprovalClick();
                          setToggle(false);
                        }}
                        sx={{
                          backgroundColor: toggle ? "" : "#fff",
                        }}
                        className={`toggle-box_Txn `}
                      >
                        <img
                          src={toggle ? ListWhite : ListBlue}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Multi Approval"
                    >
                      <Box
                        onClick={() => {
                          setToggle(true);
                        }}
                        sx={{
                          backgroundColor: toggle ? "#fff" : "",
                        }}
                        className={`toggle-box_Txn`}
                      >
                        <img
                          src={toggle ? GridBlue : GridWhite}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className=" bg-[#506994] w-[95px] h-[210px] cursor-pointer flex flex-col gap-[4px] justify-center items-center"
                  style={{
                    opacity: selectedTransactions?.length >= 1 ? 1 : 0.6,
                    cursor:
                      selectedTransactions?.length >= 1 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (selectedTransactions?.length >= 1) {
                      const selectedData = {
                        selectedSendTransactions,
                        selectedTransactions,
                        timestamp: Date.now(),
                      };
                      sessionStorage.setItem(
                        "selectedData",
                        JSON.stringify(selectedData)
                      );

                      navigate(
                        "/transaction/Beneficiary_Transaction/multi_transaction_approval"
                      );
                    }
                  }}
                >
                  <div className="w-[30px] h-[30px] mt-[105px]">
                    <img src={NextIcon} alt="" />
                    <p className="w-[22px] text-center font-normal text-[11px] leading-14 tracking-normal text-white mt-[2px] ml-[2px]">
                      Next
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {filterOpen && (
          <div
            className={`filteredClass-transition-${
              filterOpen ? "open" : "close"
            }  `}
          >
            <div className="filterdropdown-container">
              <div className="filterdropdown">
                <div
                  style={{
                    display: "flex",
                    width: "45%",
                  }}
                >
                  <SelectCategoryTransaction
                    data={allAccounts}
                    label={label}
                    setlabel={setlabel}
                    initCategory={initCategory}
                    setFilterOpen={setFilterOpen}
                    sendCategory={handleFilterCategory}
                    filterOpen={filterOpen}
                    setPage={setPage}
                    setInitCategory={setInitCategory}
                    transaction={true}
                  />
                </div>
                <div className="button_container_div">
                  <div
                    style={{
                      cursor: currentCategory !== "" ? "pointer" : "default",
                      hover: currentCategory !== "" ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (label !== "Select category") {
                        handleClearCategory();
                      }
                    }}
                    className="MultiApprovalclear"
                  >
                    <p className="buttoncontainer-text">Clear</p>
                  </div>
                  <div
                    onClick={() => {
                      if (label !== "Select category") {
                        handleFilterCategory();
                        setselectAllCard(false);
                      }
                    }}
                    className="multi-apply apply  hover:opacity-80"
                    style={{
                      backgroundColor:
                        label === "Select category" ? "#707070" : "#FB5963",

                      cursor:
                        label === "Select category" ? "default" : "pointer",
                      hover: currentCategory !== "" ? "pointer" : "default",
                    }}
                  >
                    <p className="buttoncontainer-text">Apply</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {currentCategory && (
        <div className="bene_txn_MultiApproval_FilterBar">
          <div className="bene_txn_MultiApprovalfilterdropdown">
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="bene_txn_MultiApp_filter_div_left">
                <p className="pr-2 w-[87px]">Filtered by : </p>
                {currentCategory !== "" && (
                  <div className="bene_txn_MultiApp_filter_body px-1 pt-1">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={currentCategory}
                    >
                      <p className="px-2 text-xs truncate w-[100px] h-[19px]">
                        {currentCategory}
                      </p>
                    </Tooltip>
                    <div
                      className="bene_txn_MultiApp_close_btn"
                      onClick={handleClearCategory}
                    >
                      <img
                        src={closeBtnImg}
                        alt="close-btn"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          marginTop:
            currentCategory === ""
              ? "0px"
              : BeneTxnRequireApprovalData !== undefined &&
                allAccounts?.length > 0
              ? "80px"
              : "50px",
        }}
      >
        <div className="beneTxnMultiApproval_middle_section_container">
          {BeneTxnRequireApprovalData !== undefined &&
            allAccounts?.length > 0 && (
              <>
                <div className="text-[16px] text-[#FFFFFF] font-normal leading-[22px]">
                  <p>Select Transaction To Proceed</p>
                </div>
                <div className="flex">
                  <p className="text-[12px] mt-1 text-[#DDDDDD] underline font-normal leading-[17px]">
                    Select all (Maximum 20)
                  </p>
                  <IconButton
                    style={{
                      backgroundColor: "#314D7E",
                      borderRadius: "10px",
                      height: "30px",
                      width: "30px",
                      marginLeft: "10px",
                      marginTop: "-3px",
                    }}
                    onClick={() => {
                      setselectAllCard(
                        approvedAccounts.every((itm) =>
                          selectedTransactions.includes(itm.txn_id)
                        )
                          ? true
                          : selectAllCard
                          ? false
                          : true
                      );
                      handleSelectAll(
                        approvedAccounts.every((itm) =>
                          selectedTransactions.includes(itm.txn_id)
                        )
                      );
                    }}
                  >
                    <img
                      className=""
                      src={
                        allAccounts?.length < 20 &&
                        approvedAccounts.length !== 0 &&
                        approvedAccounts.every((itm) =>
                          selectedTransactions.includes(itm.txn_id)
                        )
                          ? //   &&
                            // approvedAccounts.length === selectedTransactions.length
                            Selected
                          : selectedTransactions?.length === 20
                          ? Selected
                          : SelectWhite
                      }
                      alt="Select White"
                    />
                  </IconButton>
                </div>
              </>
            )}
        </div>
      </div>

      <div
        id="txnViewallScrollContainer"
        style={{ overflow: filterOpen ? "hidden" : "auto" }}
      >
        {allBeneTxnAccountsLoading ? (
          <div className="loader-container m-auto justify-center items-center flex mt-20">
            <img src={dataLoading} alt="" />
          </div>
        ) : BeneTxnRequireApprovalData === undefined ||
          allAccounts?.length === 0 ? (
          <div
            ref={filterRef}
            className="BeneAccountMultiApproval_NoAccountFound"
            style={{
              opacity: filterOpen ? 0.2 : 1,
            }}
          >
            <div className="BeneAccount_RightTextContentNoAccount">
              <img
                src={
                  ShowFilterErrorPage && allAccounts?.length === 0
                    ? FiterNoAccount
                    : NoAccountFound
                }
                alt=""
              />
            </div>
            <div className="BeneAccount_LeftTextContentNoAccount">
              {ShowFilterErrorPage && allAccounts?.length === 0 ? (
                <span className="">No data found for the selected filter.</span>
              ) : (
                <>
                  <span>
                    There are no more transactions that require your approval.
                  </span>
                  <p className="w-[426px]"></p>
                </>
              )}
            </div>
          </div>
        ) : allAccounts?.length > 0 ? (
          <div ref={filterRef}>
            <div
              className={`${
                filterOpen
                  ? "disable_pointer"
                  : "flex flex-col items-center justify-center m-auto"
              }`}
            >
              <InfiniteScroll
                style={{ width: "inherit" }}
                dataLength={allAccounts?.length || []}
                next={fetchMoreData}
                hasMore={
                  page <
                  Math.ceil(BeneTxnRequireApprovalData?.total_count / pageSize)
                }
                loader={
                  <div className="loader-container m-auto justify-center items-center flex">
                    <img src={dataLoading} alt="" />
                  </div>
                }
                scrollableTarget="txnViewallScrollContainer"
                scrollThreshold={0.5}
              >
                <BeneficiaryMultiApprovalTxn
                  filterOpen={filterOpen}
                  setAllAccounts={setAllAccounts}
                  data={allAccounts}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  totalAmount={totalAmount}
                  setselectAllCard={setselectAllCard}
                  setTotalAmount={setTotalAmount}
                  selectedSendTransactions={selectedSendTransactions}
                  setSelectedSendTransactions={setSelectedSendTransactions}
                />
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default BeneTxnMultiApproval;
