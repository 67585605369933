import React, { useEffect, useState } from "react";
import "./index.css";
import Reconcillation from "../../../../../src/assets/landingPage/Reconcile.svg";
import DataManagement from "../../../../../src/assets/landingPage/DataManagement.svg";
import CyberSecurity from "../../../../../src/assets/landingPage/SecurityCyber.svg";
import Scalability from "../../../../../src/assets/landingPage/Scalable.svg";
import AccordionIcon from "../../../../assets/landingPage/ArrowDownWhiteCircle.svg";
import Navbar from "../../../../components/LandingPage/Navbar";
import Footer from "../../../../components/LandingPage/Footer";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const AMC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [selectedAMCData, setSelectedAMCData] = useState([6]);
  const [activeBox, setActiveBox] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const AMCSolutions = [
    {
      id: 1,
      heading: "• Security and Fraud Prevention: ",
      data: "Xpentra ensures that your sensitive data is protected at every step with multi-layered encryption, top-tier security protocols, and fraud detection systems. This level of security safeguards your operations, protecting your reputation and investor trust.",
    },
    {
      id: 2,
      heading: "• End-to-End Reconciliation:",
      data: "Efficient reconciliation is at the core of Xpentra’s solution, ensuring every transaction is accurately matched from the point of request initiation to receipt. This automation reduces errors, accelerates processing, and provides full control over your operations.",
    },
    {
      id: 3,
      heading: "• Flexible ERP Integration:",
      data: "Xpentra simplifies ERP integrations with cost-effective, seamless connectivity, reducing the time and expenses associated with traditional setups. This ensures smooth scalability and adapts easily to the growing needs of your business.",
    },
    {
      id: 4,
      heading: "• Advanced Analytics:",
      data: "Unlock the power of your financial data. Xpentra offers advanced analytics that help you track trends, forecast cash flows, and generate insightful reports, giving you the tools to fine-tune your financial strategies with ease.",
    },
    {
      id: 5,
      heading: "• Authorization and Authentication: ",
      data: "Xpentra provides robust authorization and authentication systems to ensure that only the appropriate personnel can access or approve transactions. Customizable access controls minimize risks associated with unauthorized activity, enhancing operational security.",
    },
    {
      id: 6,
      heading: "• Seamless Report Generation: ",
      data: "Xpentra offers flexible, real-time report generation with customizable options. Generate reports quickly and efficiently, providing faster access to critical data without the delays and limitations of traditional reporting systems.",
    },
    {
      id: 7,
      heading: "• Integrated Banking Solution: ",
      data: "Xpentra connects seamlessly with multiple banks, providing a unified platform for managing all your accounts and transactions. This reduces complexity and ensures operational efficiency by centralizing your banking operations in one place.",
    },
    {
      id: 8,
      heading: "• Adaptable RTA Management: ",
      data: "Xpentra offers smooth transitions between RTAs like CAMS and KFin, ensuring uninterrupted operations while maintaining strong partnerships. The flexibility allows your business to adapt as needed without the rigidity of a fixed system.",
    },
  ];

  const boxData = {
    1: [6],
    2: [2, 6, 7],
    3: [3],
    4: [8],
    5: [1, 5],
    6: [4, 6],
    7: [4],
    8: [2],
    9: [7],
    10: [2],
  };

  const handleBoxClick = (id) => {
    setActiveBox(id);
    setSelectedAMCData(boxData[id] || []);
  };

  const isBoxActive = (id) => activeBox === id;

  const reconciliationSections = [
    {
      img: Reconcillation,
      title: "Reconciliation challenges",
      description:
        "Inaccurate or delayed transaction matching can disrupt operations and drive up costs.",
    },
    {
      img: DataManagement,
      title: "Data management complexity",
      description:
        "Handling multiple accounts and large volumes of data can be overwhelming and error-prone.",
    },
    {
      img: CyberSecurity,
      title: "Cybersecurity risks",
      description:
        "Protecting sensitive financial data is crucial in an increasingly vulnerable digital environment.",
    },
    {
      img: Scalability,
      title: "Scalability issues",
      description:
        "As your operations expand, so do pressures on your systems, making growth difficult without the right infrastructure.",
    },
  ];

  const sections = [
    {
      title: "Regulatory compliance",
      description:
        "Ensuring subscription processes meet regulatory standards to avoid penalties.",
      solution: [6],
    },
    {
      title: "Operational efficiency",
      description:
        "High transaction volumes make minimising errors and delays essential.",
      solution: [2, 6, 7],
    },
    {
      title: "Technology integration",
      description:
        "Integrating new technology into existing systems can be complex and time-consuming.",
      solution: [3],
    },
    {
      title: "Customer experience",
      description:
        "A seamless process is crucial for investor satisfaction in a competitive market.",
      solution: [8],
    },
    {
      title: "Fraud prevention",
      description:
        "Protecting sensitive financial data and preventing fraud is a top priority.",
      solution: [1, 5],
    },
    {
      title: "Liquidity management",
      description:
        "Managing liquidity to meet redemption requests without disrupting strategies.",
      solution: [4, 6],
    },
    {
      title: "Market impact",
      description:
        "Large redemptions can negatively impact market performance and fund value.",
      solution: [4],
    },
    {
      title: "Timely processing",
      description:
        "Prompt redemption handling is essential to maintain trust and meet regulatory deadlines.",
      solution: [2],
    },
    {
      title: "Valuation accuracy",
      description:
        "Correct fund valuation ensures fair payouts during redemptions.",
      solution: [7],
    },
    {
      title: "Operational risk",
      description:
        "Processing errors and system failures can disrupt operations.",
      solution: [2],
    },
  ];

  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);

  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(calc(50% - ${
      currentIndex * (230 + 20) + 120
    }px))`;
    setPausedTransform(currentTransform);
    // alert("hii");
  };

  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
    // alert("hello");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) =>
          prevIndex === reconciliationSections.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isPaused, reconciliationSections.length, intervalTime]);

  const handleAccordionClick = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Scroll to top logic
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
        >
          <CircularProgressWithLabel value={scrollPercent} />
        </div>
      )}
      {isMobile ? (
        <div
          style={{
            overflowY: isDarkSectionActive ? "hidden" : "",
            height: "100vh",
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE 10+
            WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
          }}
          className="bg-[#031f4f] isMob"
        >
          <Navbar onToggleDarkSection={setIsDarkSectionActive} />
          <div className="p-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1d3a6d]">
            <div className="text-lg font-semibold">
              Precision in Transactions, <br />
              Confidence in Growth.
            </div>
            <div className="text-sm font-normal">
              <span className="font-bold">
                Is Your AMC Facing Challenges in Reconciliation, Data
                Management, and Cybersecurity?
              </span>
              <br />
              <span>
                In today’s dynamic financial landscape, smooth reconciliation,
                precise account tracking, and efficient data flow are critical
                for Asset Management Companies (AMCs) to succeed. Yet, many AMCs
                encounter common hurdles.
              </span>
            </div>
          </div>

          <div className="bg-[#031F4F] p-[30px]">
            <div className="relative overflow-hidden w-full">
              <div
                className="flex transition-transform duration-700 ease-in-out gap-[20px]"
                style={{
                  transform: isPaused
                    ? pausedTransform
                    : `translateX(calc(50% - ${
                        currentIndex * (230 + 20) + 120
                      }px))`,
                  transition: isPaused ? "none" : "transform 700ms ease-in-out",
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                {reconciliationSections.map((section, index) => (
                  <div
                    key={index}
                    className="max-w-[300px] flex-shrink-0 rounded-[10px] mt-[30px]"
                  >
                    <div
                      className="card"
                      style={{
                        backgroundImage: `url(${section.img})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "230px",
                        height: "188px",
                      }}
                    >
                      <div className="card-content text-[#F9F9F9] absolute bottom-0">
                        <h2 className="text-[16px] font-semibold">
                          {section.title}
                        </h2>
                        <p className="text-[12px] font-normal h-[90px]">
                          {section.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
              {reconciliationSections.map((_, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`rounded-[18px] bg-white h-[4px] transition-all duration-500 ease-in-out ${
                    isPaused
                      ? index === currentIndex
                        ? "w-[80px] opacity-100" // Active indicator when paused
                        : "w-[40px] opacity-50" // Inactive indicators
                      : index === currentIndex
                      ? "w-[80px] opacity-100" // Active indicator when active
                      : "w-[40px] opacity-50" // Inactive indicators
                  }`}
                ></div>
              ))}
            </div>
          </div>

          <div className="bg-[#031F4F] px-[30px]">
            <p className="text-[#F9F9F9] text-[16px] font-semibold mb-[20px] text-left sm:text-center">
              <span className="text-[#31E5E5]">Challenges</span> faced by AMCs
            </p>
            <p className="text-[#F9F9F9] text-xs font-normal">
              <span>
                Asset Management Companies (AMCs) face numerous challenges that
                affect operational efficiency, data accuracy, and overall
                customer satisfaction.
              </span>
              <span className="mt-[15px]">
                <br /> Below are the key hurdles faced by AMCs during their
                redemption and subscription processes
              </span>
            </p>
          </div>

          <div className="p-[30px] flex flex-col gap-[20px] bg-[#031F4F] mb-[-16px]">
            {sections.map((section, index) => (
              <div key={index}>
                <div
                  className={`${
                    expandedIndex === index
                      ? "rounded-t-[10px] bg-[#314D7E]"
                      : "rounded-[10px] bg-[#102C5D]"
                  } justify-between h-[95px] mx-auto p-[20px] flex items-center cursor-pointer gap-[10px]`}
                  onClick={() => handleAccordionClick(index)}
                >
                  <div className="text-[#F9F9F9]">
                    <p className="font-semibold text-[14px]">{section.title}</p>
                    <p className="font-normal text-[12px]">
                      {section.description}
                    </p>
                  </div>
                  <div
                    className={`transition-transform duration-500 ease-in-out ${
                      expandedIndex === index ? "-rotate-180" : ""
                    }`}
                    style={{ flexShrink: "0" }}
                  >
                    <img src={AccordionIcon} alt="AccordionIcon" />
                  </div>
                </div>

                <div
                  className={`h-[1px] bg-[#F9F9F9] w-[95%] mx-auto relative z-[1] ${
                    expandedIndex === index ? "visible" : "invisible"
                  }`}
                ></div>

                <div
                  className={`rounded-b-[10px] bg-[#314D7E] mt-[-1px] mx-auto overflow-hidden transition-all duration-500 ease-in-out ${
                    expandedIndex === index
                      ? "h-[max-content] py-[20px]"
                      : "h-0"
                  } px-[20px] flex flex-col gap-[4px]`}
                >
                  <p className="text-[12px] text-[#31E5E5] font-semibold">
                    Solutions
                  </p>
                  {section.solution.map((solutionId) => {
                    const matchedSolution = AMCSolutions.find(
                      (sol) => sol.id === solutionId
                    );
                    return (
                      matchedSolution && (
                        <div key={matchedSolution.id}>
                          <p className="text-[14px] text-[#F9F9F9] font-semibold">
                            {matchedSolution.heading}
                          </p>
                          <p className="text-[12px] text-[#F9F9F9] font-normal">
                            {matchedSolution.data}
                          </p>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </div>
      ) : (
        <>
          <Navbar />
          <div className="h-[462px] max-w-[1440px] mx-auto w-screen flex bg-no-repeat bg-center bg-cover bg-[#1D3A6D]">
            <div className="w-auto pl-[120px] flex flex-col justify-center">
              <p
                className="text-[#F9F9F9] text-[32px] font-semibold p-0 tracking-wider"
                style={{ lineHeight: "1.2" }}
              >
                Precision in Transactions, <br /> Confidence in Growth.
              </p>
              <p className="text-[#F9F9F9] text-[14px] mt-[10px] p-0">
                <span className="text-[16px] font-semibold">
                  Is Your AMC Facing Challenges in Reconcillation, Data
                  Management, and Cybersecurity?
                </span>
                <br />
                In today's dynamic financial landscape, smooth reconcillation,
                precise account tracking, and efficient data
                <br /> flow are critical for Asset Management Companies (AMCs)
                to succeed. Yet, many AMCs encounter common
                <br /> hurdles.
              </p>
            </div>
          </div>

          <div className="card-section">
            <div className="mx-auto max-w-[1024px]">
              <div className="card-div">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${Reconcillation})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "230px",
                    height: "188px",
                  }}
                >
                  <div className="card-content absolute bottom-0">
                    <h2>Reconcillation challenges</h2>
                    <p className="h-[90px]">
                      Inaccurate or delayed transaction matching can disrupt
                      operations and drive up costs.
                    </p>
                  </div>
                </div>
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${DataManagement})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "230px",
                    height: "188px",
                  }}
                >
                  <div className="card-content absolute bottom-0">
                    <h2>Data management complexity</h2>
                    <p className="h-[90px]">
                      Handling multiple accounts and large volumes of data can
                      be overwhelming and error-prone.
                    </p>
                  </div>
                </div>
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${CyberSecurity})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "230px",
                    height: "188px",
                  }}
                >
                  <div className="card-content absolute bottom-0">
                    <h2>Cybersecurity risks</h2>
                    <p className="h-[90px]">
                      Protecting sensitive financial data is crucial in an
                      increasingly vulnerable digital environment.
                    </p>
                  </div>
                </div>
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${Scalability})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "230px",
                    height: "188px",
                  }}
                >
                  <div className="card-content absolute bottom-0">
                    <h2>Scalability issues</h2>
                    <p className="h-[90px]">
                      As your operations expand, so do pressures on your
                      systems,making growth difficult without the right
                      infrastructure.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="header-content flex flex-col items-center">
                  <h2>
                    <span className="text-[#31E5E5]">Challenges</span> faced by
                    AMC's
                  </h2>
                  <p className="text-xs text-[#fff] px-[100px] py-[20px] text-center">
                    Asset Management Companies (AMCs) face numerous challenges
                    that affect operational efficiency, data accuracy, and
                    overall customer satisfaction. Below are the key hurdles
                    faced by AMCs during their redemption and subscription
                    process
                  </p>
                </div>
                <div className="grid-container">
                  <div
                    className={`box text-right rounded-tl-lg cursor-pointer ${
                      isBoxActive(1)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(1)}
                  >
                    <span className="title-sec">Regulatory compliance</span>{" "}
                    <br />
                    <p className="p-0">
                      Ensuring subscription processes meet regulatory standards
                      to avoid penalties.
                    </p>
                  </div>

                  <div className="box text-center text-[28px] font-semibold bg-[#102C5D]">
                    Xpentra’s <span className="text-[#31E5E5]">solutions</span>
                    <br /> for AMC’s
                  </div>

                  <div
                    className={`box rounded-tr-lg cursor-pointer ${
                      isBoxActive(6)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(6)}
                  >
                    <span className="title-sec">Liquidity management</span>{" "}
                    <br />
                    <p className="p-0">
                      Managing liquidity to meet redemption requests without
                      disrupting strategies.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(2)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(2)}
                  >
                    <span className="title-sec">Operational efficiency</span>{" "}
                    <br />
                    <p className="p-0">
                      High transaction volumes make minimizing errors and delays
                      essential.
                    </p>
                  </div>

                  <div className="box row-span-4 bg-gradient-to-b from-[#314d7e] to-[#082455]">
                    <ul>
                      {selectedAMCData.map((fe) => {
                        const matchedData = AMCSolutions.find(
                          (data) => data?.id === fe
                        );
                        if (matchedData) {
                          return (
                            <li key={matchedData.id} className="mb-[20px]">
                              <span className="title-sec">
                                {matchedData.heading}
                              </span>
                              <p className="p-0">{matchedData.data}</p>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(7)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(7)}
                  >
                    <span className="title-sec">Market impact</span> <br />
                    <p className="p-0">
                      Large redemptions can negatively impact market performance
                      and fund value.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(3)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(3)}
                  >
                    <span className="title-sec">Technology integration</span>{" "}
                    <br />
                    <p className="p-0">
                      Integrating new technology into existing systems can be
                      complex and time-consuming.
                    </p>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(8)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(8)}
                  >
                    <span className="title-sec">Timely processing</span> <br />
                    <p className="p-0">
                      Prompt redemption handling is essential to maintain trust
                      and meet regulatory deadlines.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(4)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(4)}
                  >
                    <span className="title-sec">Customer experience</span>{" "}
                    <br />
                    <p className="p-0">
                      A seamless process is crucial for investor satisfaction in
                      a competitive market.
                    </p>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(9)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(9)}
                  >
                    <span className="title-sec">Valuation accuracy</span> <br />
                    <p className="p-0">
                      Correct fund valuation ensures fair payouts during
                      redemptions.
                    </p>
                  </div>

                  <div
                    className={`box text-right rounded-bl-lg cursor-pointer ${
                      isBoxActive(5)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(5)}
                  >
                    <span className="title-sec">Fraud prevention</span> <br />
                    <p className="p-0">
                      Protecting sensitive financial data and preventing fraud
                      is a top priority.
                    </p>
                  </div>

                  <div
                    className={`box rounded-br-lg cursor-pointer ${
                      isBoxActive(10)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(10)}
                  >
                    <span className="title-sec">Operational risk</span> <br />
                    <p className="p-0">
                      Processing errors and system failures can disrupt
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default AMC;
