import React, { useEffect, useState } from "react";
import NotAssigned from "../../assets/HomeDashboard/feature_not_assigned.svg";
import Disc from "../../assets/Beneficiary Icons/discEdit.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoleFeatures } from "../../redux/features/login/login";
import "./index.css";

const FeatureNotAssigned = () => {
  const navigate = useNavigate();
  const { roleFeatures, isLoading, noUsersFound } = useSelector(
    (state) => state.login
  );
  const refreshData = useSelector((state) => state.refreshToken.data);
  const services = useSelector((state) => state.refreshToken.services);
  const entityId = localStorage.getItem("entity_id");

  const location = useLocation();
  const { unavailableService } = location.state || {};

  const featureList = unavailableService ? [unavailableService] : [];

  const adminUsers = [
    "Bhaskar Divakar Chaudhary",
    "Kapil Meghnani",
    "Tanvi Shah",
    "Rajesh Kumar",
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllRoleFeatures());
  }, [dispatch]);

  return (
    <>
      <div id="webviewd">
        <div className="flex w-full h-screen">
          <div className="max-w-[1024px] w-full mx-auto flex">
            <div className="w-2/5 flex flex-col justify-center items-center text-center">
              <div className="mt-20">
                <img src={NotAssigned} alt="" className="h-56 mb-4 " />
              </div>
              <h3 className="text-xl text-[#fafafa] font-semibold mt-8">
                Feature not assigned
              </h3>
              <p className="text-[#fafafa] text-sm mt-3">
                The services required for this feature
                <br />
                are not assigned to your user.
              </p>
              <div className="mt-8" onClick={() => navigate("/home")}>
                <button className="text-black bg-[#FFFFFF] w-auto rounded-4xl px-10 py-2 text-lg font-semibold">
                  Home
                </button>
              </div>
            </div>

            <div className="w-3/5 flex justify-center items-center mt-28">
              <div
                className="bg-[#29487E] w-full h-auto px-12 py-9 rounded-4xl"
                style={{ boxShadow: "5px 5px 11px #00000033" }}
              >
                <div>
                  <h3 className="mb-4 text-xl text-[#FAFAFA] font-semibold">
                    To resolve this issue -
                  </h3>
                  <p className="mb-4 text-sm text-[#DADADA] font-normal">
                    Contact your admin and request them to assign the feature
                    required to your User ID&nbsp;:
                    <i className="font-bold">
                      &nbsp;{refreshData?.employees?.user_id}
                    </i>
                  </p>
                  <span className="text-xs text-[#d3d3d3]">
                    Feature must include the following services -
                  </span>
                  <div className="mt-2 w-full mb-4">
                    <ul
                      style={{
                        listStylePosition: "inside",
                        color: "#C9C9C9",
                        fontSize: "12px",
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "8px",
                      }}
                    >
                      {featureList.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "8px",
                            fontSize: "16px",
                          }}
                        >
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <span
                            style={{ marginLeft: "8px", fontStyle: "italic" }}
                          >
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr className="text-[#D3D3D3]" />

                <div className="mt-6">
                  <p className="text-xs text-[#FAFAFA]">
                    Users with admin rights -
                  </p>
                  <div className="text-[#C9C9C9] mt-2 text-xs">
                    No user found
                  </div>
                  {/* {noUsersFound ? (
                    <div className="text-[#C9C9C9] mt-2 text-xs">
                      No user found
                    </div>
                  ) : // <div className="mt-5 w-full">
                  //   <ul
                  //     style={{
                  //       listStylePosition: "inside",
                  //       color: "#C9C9C9",
                  //       fontSize: "12px",
                  //       display: "grid",
                  //       gridTemplateColumns: "repeat(2, 1fr)",
                  //       gap: "8px",
                  //     }}
                  //   >
                  //     {adminUsers.map((user, index) => (
                  //       <li
                  //         key={index}
                  //         style={{
                  //           display: "flex",
                  //           alignItems: "center",
                  //           marginLeft: "8px",
                  //           fontSize: "16px",
                  //         }}
                  //       >
                  //         <img
                  //           src={Disc}
                  //           alt="editCategoryMode"
                  //           className="w-2 h-2"
                  //         />
                  //         <span
                  //           style={{ marginLeft: "8px", fontStyle: "italic" }}
                  //         >
                  //           {user}
                  //         </span>
                  //       </li>
                  //     ))}
                  //   </ul>
                  // </div>
                  null} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobileviewd">
        <div className="flex w-full">
          <div className="w-[100%] mx-auto">
            <div className="w-[100%] justify-center items-center text-center">
              <div className="mt-10">
                <img
                  src={NotAssigned}
                  alt=""
                  className="h-56 mb-4 "
                  style={{ width: "80%", margin: "0px auto" }}
                />
              </div>
              <h3 className="text-xl text-[#fafafa] font-semibold mt-8">
                Feature not assigned
              </h3>
              <p className="text-[#fafafa] text-sm mt-3">
                The services required for this feature
                <br />
                are not assigned to your user.
              </p>
            </div>
            <div className="w-[100%] justify-center items-center mt-8">
              <div
                className="bg-[#29487E] w-full h-auto px-12 py-9 rounded-4xl remove-buttom-border-mobile-view"
                style={{ boxShadow: "5px 5px 11px #00000033" }}
              >
                <div>
                  <h3 className="mb-4 text-xl text-[#FAFAFA] font-semibold">
                    To resolve this issue -
                  </h3>
                  <p className="mb-4 text-sm text-[#DADADA] font-normal">
                    Contact your admin and request them to assign the feature
                    required to your User ID:{" "}
                    <i className="font-bold">
                      &nbsp;{refreshData?.employees?.user_id}
                    </i>
                  </p>
                  <span className="text-xs text-[#d3d3d3]">
                    Feature must include the following services -
                  </span>
                  <div className="flex flex-wrap mb-4">
                    <div className="mt-2 w-full">
                      <ul
                        style={{
                          listStylePosition: "inside",
                          color: "#C9C9C9",
                          fontSize: "12px",
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "8px",
                        }}
                      >
                        {featureList.map((item, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "8px",
                              fontSize: "16px",
                            }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <span
                              style={{ marginLeft: "8px", fontStyle: "italic" }}
                            >
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <hr className="text-[#D3D3D3]" />

                <div className="mt-6">
                  <p className="text-xs text-[#FAFAFA]">
                    Users with admin rights -
                  </p>
                  {/* {
                    noUsersFound ? (
                      <div className="text-[#C9C9C9] mt-2 text-xs">
                        No user found
                      </div>
                    ) : 
                    <div className="mt-5 w-full">
                      <ul
                        style={{
                          listStylePosition: "inside",
                          color: "#C9C9C9",
                          fontSize: "12px",
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "8px",
                        }}
                      >
                        {adminUsers.map((user, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "8px",
                              fontSize: "16px",
                            }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <span
                              style={{ marginLeft: "8px", fontStyle: "italic" }}
                            >
                              {user}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  } */}
                  <div className="text-[#C9C9C9] mt-2 text-xs">
                    No user found
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-[100%] justify-center items-center mt-8"
              onClick={() => navigate("/home")}
            >
              <button
                className="text-black bg-[#FFFFFF] w-[100%] px-10 py-2"
                style={{ position: "fixed", bottom: "0px" }}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureNotAssigned;
