import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../../../components/LandingPage/Navbar";
import Footer from "../../../../components/LandingPage/Footer";
import Mission from "../../../../assets/landingPage/Mission.svg";
import Vision from "../../../../assets/landingPage/Vision.svg";
import Values from "../../../../assets/landingPage/Values.svg";
import FinancialManagement from "../../../../assets/landingPage/FinancialManagement.svg";
import Consolidation from "../../../../assets/landingPage/Consolidation.svg";
import BirthOfXpentra from "../../../../assets/landingPage/BirthOfXpentra.svg";
import FinancialOperations from "../../../../assets/landingPage/FinancialOperations.svg";
import ConnectArrowLeft from "../../../../assets/landingPage/ConnectArrowLeft.svg";
import ConnectArrowRight from "../../../../assets/landingPage/ConnectArrowRight.svg";
import VisionBegins from "../../../../assets/landingPage/VisionBegins.svg";
import ExpandFunctionality from "../../../../assets/landingPage/ExpandingFunctionality.svg";
import EnhanceConnectivity from "../../../../assets/landingPage/EnhancingConnectivity.svg";
import ReliableVerifications from "../../../../assets/landingPage/ReliableVerifications.svg";
import NACHCollections from "../../../../assets/landingPage/NACHCollections.svg";
import SecureManagement from "../../../../assets/landingPage/SecureManagement.svg";
import OptimisingBusiness from "../../../../assets/landingPage/OptimisingBusiness.svg";
import BharatConnect from "../../../../assets/landingPage/BharatConnect.svg";
import SpecialisedSolutions from "../../../../assets/landingPage/SpecialisedSolutions.svg";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandingCards from "../../../../components/_utils/ExpandingCards/ExpandingCards";
import TeamPicture from "../../../../assets/landingPage/TeamPicture.jpg";
import AkshaySirWeb from "../../../../assets/landingPage/AkshaySirWeb.jpg";
import AnkurSirWeb from "../../../../assets/landingPage/AnkurSirWeb.jpg";
import AkshaySirMob1 from "../../../../assets/landingPage/AkshaySirMob1.png";
import AnkurSirMob1 from "../../../../assets/landingPage/AnkurSirMob1.png";
import AshishSirWeb from "../../../../assets/landingPage/AshishSirWeb.jpg";
import AshishSirMob1 from "../../../../assets/landingPage/AshishSirMob1.png";

const AboutUs = () => {
  const aboutSections = [
    {
      id: 1,
      profileWeb: AkshaySirWeb,
      profileMob: AkshaySirMob1,
      name: "Mr. Akshay Rangnekar",
      title: "Founder and Chief Executive Officer",
      description: `Born and raised in Mumbai, Akshay has always been passionate about technology, with an innate inclination to innovate and automate. After earning his bachelor’s degree in computer engineering at Cal Poly, he worked at a Silicon Valley startup for 2 years. He returned to India in 2016, recognizing India’s push towards digitization and technologival transformation. There, he contributed to Quest2Pay (a pioneer in vendor payments via virtual corporate cards) and built GST and expense management solutions for Quest2Travel before founding Xpentra to create a universal banking solution for enterprise customers. He designs core functionality, database structures, business logic, user interfaces, and process flows for both web and mobile platforms.`,
    },
    {
      id: 2,
      profileWeb: AnkurSirWeb,
      profileMob: AnkurSirMob1,
      name: "Mr. Ankur Rangnekar",
      title: "Co-founder and Chief Operating Officer",
      description: `Ankur, based out of Mumbai, co-founded Xpentra after heading to Bay Area and Singapore for advanced studies. The "Make in India" initiative and the possibility offered by the Indian economy inspired him to return to the country. He takes care of operations, manages customer and partner relationships, and helps drive business development and strategic initiatives within the organization.`,
    },
    {
      id: 3,
      profileWeb: AshishSirWeb,
      profileMob: AshishSirMob1,
      name: "Mr. Ashish Wadekar",
      title: "Chief Technology Officer",
      description: `Ashish is a seasoned tech leader with a diverse international career. He began his journey in Pune and later moved to Zürich, where he led a successful ERP implementation for RitikaWood, demonstrating his capability in managing complex software projects. Known for his innovative approach, Ashish consistently develops creative solutions to meet evolving business needs. Since joining Q2Pay in 2022, he has been instrumental in driving the company's technological vision, inspiring and leading teams with his expertise and passion.`,
    },
  ];

  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % aboutSections.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const cardWidth = 290;
      const gap = 15;
      const containerWidth = containerRef.current.offsetWidth;
      const middleCardPosition =
        cardWidth + gap - containerWidth / 2 + cardWidth / 2;
      containerRef.current.scrollLeft = middleCardPosition;
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div
            style={{
              overflowY: isDarkSectionActive ? "hidden" : "",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
            className="bg-[#031f4f]"
          >
            <Navbar onToggleDarkSection={setIsDarkSectionActive} />

            <div className="p-[30px] bg-[#102C5D] flex flex-col gap-[15px] mt-[60px]">
              <div className="text-[16px] font-semibold text-[#F9F9F9]">
                <span className="text-[#31E5E5]">Leadership</span> team
              </div>
              <div
                ref={containerRef}
                className="px-[10px] mx-auto flex gap-[20px] max-w-full overflow-x-auto"
                style={{
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                {aboutSections.map((section, index) => (
                  <div
                    key={index}
                    className="rounded-[10px] bg-[#244277] px-[10px] py-[10px] w-[290px] flex-shrink-0 h-auto flex flex-col gap-[10px]"
                  >
                    <div>
                      <img
                        className="mx-auto w-[280px] rounded-t-[10px]"
                        src={section.profileMob}
                        alt="leader"
                      />
                    </div>
                    <div className="flex flex-col gap-[5px] text-[#F9F9F9] px-[10px] pt-[10px]">
                      <div className="text-[18px] font-semibold">
                        {section.name}
                      </div>
                      <div className="text-[12px] font-normal pb-[10px] border-b-[1px]">
                        {section.title}
                      </div>
                    </div>
                    <div className="px-[10px] text-[#F9F9F9]">
                      <div
                        className="text-[12px] font-normal overflow-y-auto"
                        // style={{ maxHeight: "300px" }}
                      >
                        {section.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-[#031F4F] w-full p-[30px]">
              <div
                className="flex flex-col text-white mx-auto"
                style={{ maxWidth: "1024px" }}
              >
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                  <div className="mb-5">
                    <img
                      src={Mission}
                      alt="mission"
                      className="w-[50] h-[50px]"
                    />
                  </div>
                  <div className="title-div text-base font-semibold mb-[5px]">
                    Our <span className="text-[#31E5E5]">mission</span>
                  </div>
                  <div className="text-white text-xs">
                    At Xpentra, our mission is to empower businesses by
                    providing seamless, integrated solutions that optimise
                    financial management and treasury operations. We aim to give
                    companies complete visibility and control over their
                    finances through a unified platform that simplifies complex
                    processes, improves decision-making, and drives efficiency.
                    By bridging the gap between multiple banking systems and
                    financial workflows, we help businesses stay agile,
                    efficient, and ready for the future.
                  </div>
                </div>
                <div className="flex items-center mx-auto rotate-90">
                  <img
                    src={ConnectArrowLeft}
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                  <div className="mb-5">
                    <img
                      src={Vision}
                      alt="vission"
                      className="w-[50] h-[50px]"
                    />
                  </div>
                  <div className="title-div text-base font-semibold mb-[5px]">
                    Our <span className="text-[#31E5E5]">vision</span>
                  </div>
                  <div className="text-white text-xs">
                    Our vision is to transform how businesses make decisions by
                    providing a platform that simplifies and automates financial
                    operations, giving CXOs the real-time insights they need to
                    act quickly and confidently. We aim to empower organisations
                    with smarter tools that streamline processes and enhance
                    decision-making across all areas of the business. We
                    envision a future where businesses operate with greater
                    agility, efficiency, and clarity, enabling them to achieve
                    lasting success.
                  </div>
                </div>
                <div className="flex items-center mx-auto rotate-90">
                  <img
                    src={ConnectArrowLeft}
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full p-[20px]">
                  <div className="mb-[20px]">
                    <img
                      src={Values}
                      alt="values"
                      className="w-[50] h-[50px]"
                    />
                  </div>
                  <div className="title-div text-base font-semibold mb-[5px]">
                    Our <span className="text-[#31E5E5]">values</span>
                  </div>
                  <div className="text-white text-xs">
                    <div className="mb-[5px]">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Transparency
                      </span>
                      : We believe in open and clear communication, offering
                      complete visibility into every transaction and financial
                      process.
                    </div>
                    <div className="mb-[5px]">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Reliability
                      </span>
                      : Our platform is built to deliver consistent, secure, and
                      dependable performance for businesses managing their
                      financial operations.
                    </div>
                    <div className="mb-[5px]">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        User-Centric
                      </span>
                      : We prioritise intuitive, easy-to-use solutions that are
                      tailored to meet the specific needs of each business,
                      ensuring a smooth experience.
                    </div>
                    <div className="mb-[5px]">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Security
                      </span>
                      : Xpentra places a strong emphasis on safeguarding all
                      data and transactions with industry- leading protocols and
                      fraud protection.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-[#f9f9f9] py-[20px] px-[30px]"
              style={{
                background:
                  "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                  Our <span className="text-[#31E5E5]">journey</span>
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l"
                    style={{ height: "75%", top: "37px", left: "25px" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[20px]">
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={FinancialManagement}
                          alt=""
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          Identifying gaps in corporate financial management
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • At Quest2Pay, we noticed that corporate cards only
                          worked for vendors with POS terminals or payment
                          gateways.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • We introduced virtual cards to enable payments for
                          vendors without card acceptance infrastructure.
                        </div>
                        <div className="text-[12px]">
                          • Despite this, businesses with multiple bank accounts
                          struggled with fragmented systems and lacked
                          visibility over their finances.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={Consolidation}
                          alt=""
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          The need for consolidation
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Businesses required a unified platform to manage
                          multiple bank accounts and financial activities.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • They needed real-time visibility, control, and
                          optimisation across all accounts.
                        </div>
                        <div className="text-[12px]">
                          • This realisation laid the foundation for Xpentra.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={BirthOfXpentra}
                          alt=""
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          The birth of Xpentra
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Xpentra was developed to provide a single portal for
                          managing bank accounts and streamlining financial
                          operations.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • It offers a bird’s-eye view of financial activities,
                          enabling smarter and faster decisions.
                        </div>
                        <div className="text-[12px]">
                          • Our mission: To empower businesses with better
                          control, real-time insights, and solutions for modern
                          financial challenges.
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div className="w-[50px] h-[50px] flex-shrink-0">
                        <img
                          src={FinancialOperations}
                          alt=""
                          className="relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-[5px]">
                          Xpentra today: Empowering financial operations
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • Xpentra offers real-time transaction visibility,
                          multi-bank integration, and advanced analytics.
                        </div>
                        <div className="text-[12px] mb-[5px]">
                          • The platform is designed for scalability,
                          flexibility, and security, catering to modern business
                          needs.
                        </div>
                        <div className="text-[12px]">
                          • It helps businesses optimise resources, reduce
                          inefficiencies, and drive growth.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-[#f9f9f9] bg-[#031F4F] p-[30px]">
              <div
                className="bg-[#102C5D] rounded-[15px] mx-auto flex flex-col w-full gap-[20px]"
                style={{ maxWidth: "1024px" }}
              >
                <div className="py-[20px] px-[30px] flex flex-col">
                  <div className="text-[16px] font-semibold mb-3">
                    About
                    <span className="text-[#31E5E5]"> Xpentra</span>
                  </div>
                  <div className="text-[12px] mb-2.5">
                    Xpentra is an innovative fintech venture founded by the
                    Rangnekar family. They bring a legacy of industry success,
                    drawing on their significant contributions to Quest2Travel—a
                    Corporate Travel Management Company acquired by
                    NASDAQ-listed MakeMyTrip in 2018.
                  </div>
                  <div className="text-[12px]">
                    With over 2 decades of experience and taking Quest2travel to
                    greater heights, the founders have now ventured into the
                    Fintech Space with an understanding of the complexities of
                    the industry.
                  </div>
                </div>
                <div className="bg-grey px-[30px]">
                  <img
                    className="rounded-[10px]"
                    src={TeamPicture}
                    alt="TeamPicture"
                  />
                </div>
                <div className="px-[30px] py-[20px]">
                  <div className="text-[12px] mb-[10px]">
                    Founded in 2018, Xpentra is built to deliver a comprehensive
                    banking solution for enterprises, offering superior
                    transaction and account visibility and streamlining complex
                    financial processes like payments, collections, reporting,
                    and reconciliation.
                  </div>
                  <div className="text-[12px] mb-[10px]">
                    Xpentra prioritizes usability and protection, featuring
                    automated transactions, With Xpentra, businesses can reduce
                    operational errors, providing CFOs and CXOs with a powerful
                    enabler that eliminates time consuming administrative tasks
                    and allowing leaders to focus on strategic initiatives and
                    high-impact decision making.
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-[#f9f9f9] py-[20px] px-[30px]"
              style={{
                background:
                  "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[16px] font-semibold text-[#f9f9f9] pb-[20px]">
                  <span className="text-[#31E5E5]">Our evolution:</span> Key
                  feature additions over the years
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l"
                    style={{ height: "86%", top: "18px", left: "25px" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[20px]">
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={VisionBegins}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2016</span> – The
                          vision begins
                        </div>
                        <div className="text-[12px]">
                          • Xpentra was conceptualised to address the growing
                          need for an all-in-one financial management platform.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={ExpandFunctionality}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2017</span> –
                          Expanding functionality
                        </div>
                        <div className="text-[12px]">
                          • Added refunds and reimbursements features for
                          smoother financial operations.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={EnhanceConnectivity}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2018</span> –
                          Enhancing connectivity
                        </div>
                        <div className="text-[12px] mb-1">
                          • Integrated API connectivity for seamless data
                          exchange with third-party platforms.
                        </div>
                        <div className="text-[12px]">
                          • Introduced virtual accounts for more efficient
                          transaction management.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={ReliableVerifications}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2019</span> –
                          Reliable verifications
                        </div>
                        <div className="text-[12px]">
                          • Introduced real-time verifications to ensure secure
                          and accurate transactions.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={NACHCollections}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2020</span> NACH
                          collections
                        </div>
                        <div className="text-[12px]">
                          • Added functionality for NACH collections,
                          simplifying automated payment collections.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={SecureManagement}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2021</span> – Secure
                          management
                        </div>
                        <div className="text-[12px]">
                          • Introduced easy management and addition of
                          beneficiaries to streamline payments.
                        </div>
                        <div className="text-[12px]">
                          • Added real-time balances and statement view for
                          faster financial insights.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={OptimisingBusiness}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2022</span> –
                          Optimising for business
                        </div>
                        <div className="text-[12px]">
                          • Introduced a customisable authorisation matrix for
                          secure, flexible user permissions.
                        </div>
                        <div className="text-[12px]">
                          • Added transaction categories to help businesses
                          manage and classify financial activities more
                          effectively.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={BharatConnect}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2023</span> –
                          Expanding with Bharat Connect
                        </div>
                        <div className="text-[12px]">
                          • Integrated with the Bharat Bill Payment System (now
                          Bharat Connect) to offer broader payment solutions.
                        </div>
                        <div className="text-[12px]">
                          • Launched clean-up of bank narration, improving
                          financial clarity for businesses.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-5">
                      <div className="flex justify-center flex-shrink-0">
                        <img
                          src={SpecialisedSolutions}
                          alt=""
                          className="w-[50px] h-[50px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-[18px] mb-1">
                          <span className="text-[#31E5E5]">2024</span> –
                          Specialised solutions
                        </div>
                        <div className="text-[12px]">
                          • Delivered BFSI-specific solutions, tailoring our
                          platform for the banking, financial services, and
                          insurance industries.
                        </div>
                        <div className="text-[12px]">
                          • Added GST + TDS payment functionality, simplifying
                          tax-related payments.
                        </div>
                        <div className="text-[12px]">
                          • Began offering our end-to-end customisable
                          reconciliation solution, enhancing accuracy and
                          reducing manual effort.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        ) : (
          <div>
            <Navbar />

            <div className="bg-[#102C5D] w-full" style={{ marginTop: "60px" }}>
              <div className="flex flex-col text-white py-[30px] px-20 mx-auto max-w-[1024px]">
                <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                  <span className="text-[#31E5E5]">Leadership</span> team
                </div>
                <ExpandingCards data={aboutSections} />
              </div>
            </div>

            <div className="bg-[#031F4F] w-full">
              <div
                className="flex flex-row text-white py-[30px] mx-auto"
                style={{ maxWidth: "1024px" }}
              >
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                  <div>
                    <img
                      src={Mission}
                      alt="mission"
                      className="w-[50] h-[50px] my-5"
                    />
                  </div>
                  <div className="title-div text-base font-semibold">
                    Our <span className="text-[#31E5E5]">mission</span>
                  </div>
                  <div className="text-white text-xs p-4 pt-[10px]">
                    At Xpentra, our mission is to empower businesses by
                    providing seamless, integrated solutions that optimise
                    financial management and treasury operations. We aim to give
                    companies complete visibility and control over their
                    finances through a unified platform that simplifies complex
                    processes, improves decision-making, and drives efficiency.
                    By bridging the gap between multiple banking systems and
                    financial workflows, we help businesses stay agile,
                    efficient, and ready for the future.
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    src={ConnectArrowLeft}
                    alt=""
                    className="w-[80px] h-[80px]"
                  />
                </div>
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                  <div>
                    <img
                      src={Vision}
                      alt="vission"
                      className="w-[50] h-[50px] my-5"
                    />
                  </div>
                  <div className="title-div text-base font-semibold">
                    Our <span className="text-[#31E5E5]">vision</span>
                  </div>
                  <div className="text-white text-xs p-4 pt-[10px]">
                    Our vision is to transform how businesses make decisions by
                    providing a platform that simplifies and automates financial
                    operations, giving CXOs the real-time insights they need to
                    act quickly and confidently. We aim to empower organisations
                    with smarter tools that streamline processes and enhance
                    decision-making across all areas of the business. We
                    envision a future where businesses operate with greater
                    agility, efficiency, and clarity, enabling them to achieve
                    lasting success.
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    src={ConnectArrowRight}
                    alt=""
                    className="w-[80px] h-[80px]"
                  />
                </div>
                <div className="bg-[#244277] border border-[#31E5E5] rounded-[15px] flex flex-col items-center w-full">
                  <div>
                    <img
                      src={Values}
                      alt="values"
                      className="w-[50] h-[50px] my-5"
                    />
                  </div>
                  <div className="title-div text-base font-semibold">
                    Our <span className="text-[#31E5E5]">values</span>
                  </div>
                  <div className="text-white text-xs p-4 pt-[10px]">
                    <div className="mb-2.5">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Transparency
                      </span>
                      : We believe in open and clear communication, offering
                      complete visibility into every transaction and financial
                      process.
                    </div>
                    <div className="mb-2.5">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Reliability
                      </span>
                      : Our platform is built to deliver consistent, secure, and
                      dependable performance for businesses managing their
                      financial operations.
                    </div>
                    <div className="mb-2.5">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        User-Centric
                      </span>
                      : We prioritise intuitive, easy-to-use solutions that are
                      tailored to meet the specific needs of each business,
                      ensuring a smooth experience.
                    </div>
                    <div className="mb-2.5">
                      •{" "}
                      <span
                        className="font-medium"
                        style={{
                          WebkitTextStroke: "0.3px white",
                          letterSpacing: "0.2px",
                        }}
                      >
                        Security
                      </span>
                      : Xpentra places a strong emphasis on safeguarding all
                      data and transactions with industry- leading protocols and
                      fraud protection.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-[#f9f9f9]">
              <div className="py-[30px] mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                  Our <span className="text-[#31E5E5]">journey</span>
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="border-[#31E5E5] border-b relative top-[40px]"
                    style={{ width: "80%", marginLeft: "40px" }}
                  ></div>
                  <div className="flex flex-row justify-between gap-[50px]">
                    <div>
                      <div>
                        <img
                          src={FinancialManagement}
                          alt=""
                          className="w-[80px] h-[80px] mb-5 relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-sm mb-2.5">
                          Identifying gaps in corporate financial management
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • At Quest2Pay, we noticed that corporate cards only
                          worked for vendors with POS terminals or payment
                          gateways.
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • We introduced virtual cards to enable payments for
                          vendors without card acceptance infrastructure.
                        </div>
                        <div className="text-[10px]">
                          • Despite this, businesses with multiple bank accounts
                          struggled with fragmented systems and lacked
                          visibility over their finances.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <img
                          src={Consolidation}
                          alt=""
                          className="w-[80px] h-[80px] mb-5 relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-sm mb-2.5">
                          The need for
                          <br /> consolidation
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • Businesses required a unified platform to manage
                          multiple bank accounts and financial activities.
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • They needed real-time visibility, control, and
                          optimisation across all accounts.
                        </div>
                        <div className="text-[10px]">
                          • This realisation laid the foundation for Xpentra.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <img
                          src={BirthOfXpentra}
                          alt=""
                          className="w-[80px] h-[80px] mb-5 relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-sm mb-2.5">
                          The birth of
                          <br /> Xpentra
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • Xpentra was developed to provide a single portal for
                          managing bank accounts and streamlining financial
                          operations.
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • It offers a bird’s-eye view of financial activities,
                          enabling smarter and faster decisions.
                        </div>
                        <div className="text-[10px]">
                          • Our mission: To empower businesses with better
                          control, real-time insights, and solutions for modern
                          financial challenges.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <img
                          src={FinancialOperations}
                          alt=""
                          className="w-[80px] h-[80px] mb-5 relative z-10"
                        />
                      </div>
                      <div>
                        <div className="title-section font-semibold text-sm mb-2.5">
                          Xpentra today: Empowering financial operations
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • Xpentra offers real-time transaction visibility,
                          multi-bank integration, and advanced analytics.
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • The platform is designed for scalability,
                          flexibility, and security, catering to modern business
                          needs.
                        </div>
                        <div className="text-[10px]">
                          • It helps businesses optimise resources, reduce
                          inefficiencies, and drive growth.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-[#f9f9f9] bg-[#031F4F] py-[30px]">
              <div
                className="bg-[#102C5D] rounded-[15px] mx-auto flex flex-row w-full"
                style={{ maxWidth: "1024px" }}
              >
                <div className="p-[20px] w-[275px]">
                  <div
                    className="text-[28px] font-semibold mb-3"
                    style={{ lineHeight: "1.3" }}
                  >
                    About <br />
                    <span className="text-[#31E5E5]">Xpentra</span>
                  </div>
                  <div className="text-[10px] mb-2.5">
                    Xpentra is an innovative fintech venture founded by the
                    Rangnekar family. They bring a legacy of industry success,
                    drawing on their significant contributions to Quest2Travel—a
                    Corporate Travel Management Company acquired by
                    NASDAQ-listed MakeMyTrip in 2018.
                  </div>
                  <div className="text-[10px] mb-2.5">
                    With over two decades of experience, including extensive
                    work in the payments space with numerous corporate clients
                    at Quest2Travel, they now bring a deep understanding of
                    industry complexities to the fintech sector. Their expertise
                    is focused on delivering solutions that simplify and
                    optimize financial operations for enterprises.
                  </div>
                </div>
                <div className="py-[20px]">
                  <img
                    className="w-[467px] h-[278px] rounded-[8px] my-2.5"
                    src={TeamPicture}
                    alt="TeamPicture"
                  />
                </div>
                <div className="px-[20px] py-[44px] w-[275px] my-auto">
                  <div className="text-[10px] mb-5">
                    Founded in 2018, Xpentra is built to deliver a comprehensive
                    banking solution for enterprises, offering superior
                    transaction and account visibility and streamlining complex
                    financial processes like payments, collections, reporting,
                    and reconciliation.
                  </div>
                  <div className="text-[10px]">
                    Xpentra prioritizes usability and protection, featuring
                    automated transactions, With Xpentra, businesses can reduce
                    operational errors, providing CFOs and CXOs with a powerful
                    enabler that eliminates time consuming administrative tasks
                    and allowing leaders to focus on strategic initiatives and
                    high-impact decision making.
                  </div>
                </div>
              </div>
            </div>

            <div className="text-[#f9f9f9] bg-custom-gradient py-[20px] bg-custom-gradient">
              <div className="mx-auto" style={{ maxWidth: "1024px" }}>
                <div className="text-[28px] font-semibold text-[#f9f9f9] text-center pb-[20px]">
                  <span className="text-[#31E5E5]">Our evolution:</span> Key
                  feature additions over the years
                </div>
                <div className="relative" style={{ width: "100%" }}>
                  <div
                    className="absolute border-[#31E5E5] border-l left-1/2"
                    style={{ height: "95%" }}
                  ></div>
                  <div className="flex flex-col justify-between gap-[40px]">
                    <div className="flex flex-row items-center justify-between">
                      <div className="w-[45%] text-sm mb-1"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={VisionBegins}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2016</span> – The
                          vision begins
                        </div>
                        <div className="text-[10px]">
                          • Xpentra was conceptualised to address the growing
                          need for an all-in-one financial management platform.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2017</span> –
                          Expanding functionality
                        </div>
                        <div className="text-[10px] mb-2.5">
                          • Added refunds and reimbursements features for
                          smoother financial operations.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={ExpandFunctionality}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={EnhanceConnectivity}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2018</span> –
                          Enhancing connectivity
                        </div>
                        <div className="text-[10px] mb-1">
                          • Integrated API connectivity for seamless data
                          exchange with third-party platforms.
                        </div>
                        <div className="text-[10px]">
                          • Introduced virtual accounts for more efficient
                          transaction management.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2019</span> –
                          Reliable verifications
                        </div>
                        <div className="text-[10px]">
                          • Introduced real-time verifications to ensure secure
                          and accurate transactions.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={ReliableVerifications}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={NACHCollections}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2020</span> NACH
                          collections
                        </div>
                        <div className="text-[10px]">
                          • Added functionality for NACH collections,
                          simplifying automated payment collections.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2021</span> – Secure
                          management
                        </div>
                        <div className="text-[10px]">
                          • Introduced easy management and addition of
                          beneficiaries to streamline payments.
                        </div>
                        <div className="text-[10px]">
                          • Added real-time balances and statement view for
                          faster financial insights.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={SecureManagement}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={OptimisingBusiness}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2022</span> –
                          Optimising for business
                        </div>
                        <div className="text-[10px]">
                          • Introduced a customisable authorisation matrix for
                          secure, flexible user permissions.
                        </div>
                        <div className="text-[10px]">
                          • Added transaction categories to help businesses
                          manage and classify financial activities more
                          effectively.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2023</span> –
                          Expanding with Bharat Connect
                        </div>
                        <div className="text-[10px]">
                          • Integrated with the Bharat Bill Payment System (now
                          Bharat Connect) to offer broader payment solutions.
                        </div>
                        <div className="text-[10px]">
                          • Launched clean-up of bank narration, improving
                          financial clarity for businesses.
                        </div>
                      </div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={BharatConnect}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]"></div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="text-right w-[45%]"></div>
                      <div className="flex items-center justify-center m-auto w-[10%]">
                        <img
                          src={SpecialisedSolutions}
                          alt=""
                          className="w-[80px] h-[80px] relative z-10 bg-[#102C5D] rounded-[50%]"
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="title-section font-semibold text-sm mb-1">
                          <span className="text-[#31E5E5]">2024</span> –
                          Specialised solutions
                        </div>
                        <div className="text-[10px]">
                          • Delivered BFSI-specific solutions, tailoring our
                          platform for the banking, financial services, and
                          insurance industries.
                        </div>
                        <div className="text-[10px]">
                          • Added GST + TDS payment functionality, simplifying
                          tax-related payments.
                        </div>
                        <div className="text-[10px]">
                          • Began offering our end-to-end customisable
                          reconciliation solution, enhancing accuracy and
                          reducing manual effort.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
