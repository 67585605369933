import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";
import axios from "axios";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneLookUp = createAsyncThunk("beneLookUp", async (values) => {
  const { pan_number, page } = values ? values : { pan_number: null };

  try {
    const response = await axios.get(`${baseUrl}/accounts/beneficiary/lookup`, {
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      params: {
        pan: pan_number,
        page_no: page,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const viewAccountDetail = createAsyncThunk(
  "viewAccountDetail",
  async (values) => {
    const { id } = values ? values : { id: null };

    try {
      const response = await axios.get(
        `${baseUrl}/accounts/beneficiary/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const unachivedAccount = createAsyncThunk(
  "unachivedAccount",
  async (values) => {
    const { id } = values ? values : { id: null };
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/unarchive`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const achivedAccountPan = createAsyncThunk(
  "achivedAccountPan",
  async (values) => {
    const { id } = values ? values : { id: null };
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/archive`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const unachivedAccountPan = createAsyncThunk(
  "unachivedAccountPan",
  async (values) => {
    const { id } = values ? values : { id: null };
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/unarchive`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const achivedAccountDetail = createAsyncThunk(
  "achivedAccountDetail",
  async ({ id, Navigate }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("q2p_token");

      const response = await axios.put(
        `${baseUrl}/accounts/beneficiary/${id}/archive`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": token,
          },
        }
      );   

      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors?.user_unauthorized)
      {
        Navigate("/feature_not_assigned", {
        state: {
          unavailableService: "BAAccountArchive",
        },
      });
      return;
      }
      
      if (error?.response?.data?.errors?.beneficiary_account) {
        let errorMessage =
          error?.response?.data?.errors?.beneficiary_account?.[0];
        toast.error(errorMessage, toast_position);
      } else if (
        error?.response?.data?.errors?.bank_account_number ||
        error?.response?.data?.errors?.permanent_account_number
      ) {
        let errorMessage =
          error?.response?.data?.errors?.bank_account_number[0] ||
          error?.response?.data?.errors?.permanent_account_number[0] ||
          toast.error(errorMessage, toast_position);
      }          

      let errorMessage =
        error?.response?.data?.errors?.bank_account_number[0] ||
        error?.response?.data?.errors?.beneficiary_account?.[0] ||
        error?.response?.data?.errors?.permanent_account_number[0] ||
        error?.response?.data?.errors[0]?.gstin_detail?.base ||
        error?.response?.data?.errors?.base[0];

      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);
export const unachivedAccountDetail = createAsyncThunk(
  "unachivedAccountDetail",
  async ({ id, Navigate}, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("q2p_token");

      const response = await axios.put(
        `${baseUrl}/accounts/beneficiary/${id}/unarchive`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": token,
          },
        }
      );
      return response.data;
    } catch (error) {

      if (error?.response?.data?.errors?.user_unauthorized)
      {
        Navigate("/feature_not_assigned", {
        state: {
          unavailableService: "BAAccountArchive",
        },
      });
      return;
      }
      const errData = error?.response?.data?.errors;

      if (!errData.base) {
        errData.beneficiary_account.forEach((error3) => {
          if (error?.response?.data.status === false) {
            setTimeout(() => {
              toast.error(error3, toast_position);
            }, 1000);
          }
        });
      } else {
        toast.error(errData.base[0], toast_position);
      }

      return rejectWithValue(errData);
    }
  }
);

export const achivedAccount = createAsyncThunk(
  "achivedAccount",
  async (values) => {
    const { id } = values ? values : { id: null };
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/archive`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const verifyThisPAN = createAsyncThunk(
  "verifyThisPAN",
  async (values) => {
    const { pan_number } = values || { pan_number: null };
    const searchString = pan_number || values?.search_string;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      data: {
        pan: searchString,
      },
      url: `${baseUrl}/verification/pan/verify`,
    };

    try {
      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response?.data?.error, toast_position);
      }
      return error?.response?.data;
    }
  }
);

export const verifyThisPANGet = createAsyncThunk(
  "verifyThisPANGet",
  async (values, { rejectWithValue }) => {
    const { pan_number } = values ? values : { pan_number: null };
    const token = sessionStorage.getItem("q2p_token");
    const pan = pan_number || values?.search_string;

    try {
      const response = await axios.get(`${baseUrl}/verification/pan/${pan}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": token,
          // try {
          //   const response = await axios.post(
          //     `${baseUrl}/verification/pan/verify`,
          //     {
          //       pan: values?.search_string,
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);

export const verifyThisPANPost = createAsyncThunk(
  "verifyThisPANPost",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/verification/pan/verify`,
        {
          pan: values?.search_string,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);

export const verifyThisPAN_New = createAsyncThunk(
  "verifyThisPAN_New",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        pan: values?.search_string,
      }),
    };

    return await fetch(`${baseUrl}/verification/pan/verify`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const verifyThisBank = createAsyncThunk(
  "verifyThisBank",
  async (values) => {
    const { bank_acc_no, ifsc_code } = values
      ? values
      : { bank_acc_no: null, ifsc_code: null };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        bank_acc_no: bank_acc_no,
        ifsc_code: ifsc_code,
      }),
    };

    return await fetch(`${baseUrl}/accounts/verify`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const verifyThisCIN = createAsyncThunk(
  "verifyThisCIN",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/verification/cin/verify`,
        {
          cin: values?.search_string,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage, toast_position);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyThisDIN = createAsyncThunk(
  "verifyThisDIN",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/verification/din/verify`,
        {
          din: values?.search_string,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage, toast_position);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyThisGST = createAsyncThunk(
  "verifyThisGST",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: {
        gst: values?.search_string,
      },
    };

    return await fetch(`${baseUrl}/verification/gstin/verify`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

export const verifyThisIFSC = createAsyncThunk(
  "verifyThisIFSC",
  async (values) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/verification/ifsc/${values?.search_string}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
);

const verify = createSlice({
  name: "verify",
  initialState: {
    verifyPAN: null,
    verifyBank: null,
    isError: null,
    verifyCIN: null,
    verifyCinError: null,
    verifyCINisLoading: null,
    verifyDIN: null,
    verifyDINisLoading: null,
    verifyDINError: null,
    verifyGST: null,
    verifyPAN_New: null,
    verifyIFSC: null,
    beneLookData: null,
    beneLookLoading: null,
    beneLookError: null,
    verifyPANisLoading: null,
    verifyPANisError: null,
    unachivedData: null,
    unachivedLoading: null,
    unachivedError: null,
    achivedData: null,
    achivedLoading: null,
    achivedError: null,
    viewAccountDetailData: null,
    viewAccountDetailLoading: null,
    viewAccountDetailError: null,
    unachivedDataPan: null,
    unachivedLoadingPan: null,
    unachivedErrorPan: null,
    achivedDataPan: null,
    achivedLoadingPan: null,
    achivedErrorPan: null,
    unachivedDataDetail: null,
    unachivedLoadingDetail: null,
    unachivedErrorDetail: null,
    achivedDataDetail: null,
    achivedLoadingDetail: null,
    achivedErrorDetail: null,
    getVerifyPanData: null,
    getVerifyPanLoading: null,
    getVerifyPanError: null,
    selectedCinData: null,
    selectedDinData: null,
    selectedPanData: null,
    selectedGstData: null,
    resetSelectedPan: null,
  },
  reducers: {
    resetVerification(state) {
      state.verifyPAN = null;
      state.verifyBank = null;
    },
    resetBeneData(state) {
      state.beneLookData = null;
    },
    resetPANVerification(state) {
      state.verifyPAN = null;
    },
    resetBankVerification(state) {
      state.verifyBank = null;
    },
    resetPanArchived(state) {
      state.achivedDataPan = null;
    },
    resetPanUnarchived(state) {
      state.unachivedDataPan = null;
    },
    resetDetailachived(state) {
      state.achivedDataDetail = null;
    },
    resetDetailUnachived(state) {
      state.unachivedDataDetail = null;
    },
    resetInEdit(state) {
      state.verifyPAN = null;
      state.beneLookData = null;
      state.viewAccountDetailData = null;
      state.beneLookError = null;
    },
    resetAchivedAccount(state) {
      state.achivedData = null;
      state.achivedLoading = null;
    },
    resetUnAchivedAccount(state) {
      state.unachivedData = null;
      state.unachivedLoading = null;
    },

    resetGetPanData(state) {
      state.getVerifyPanData = null;
      state.getVerifyPanError = null;
      state.getVerifyPanLoading = null;
    },
    resetGetCinData(state) {
      state.verifyCIN = null;
      state.verifyCinError = null;
      state.verifyCINisLoading = null;
    },
    resetGetCinError(state) {
      state.verifyCIN = null;
      state.verifyCinError = true;
    },
    resetGetDinData(state) {
      state.verifyDIN = null;
      state.verifyDINisLoading = null;
      state.verifyDINError = null;
    },
    resetGetDinError(state) {
      state.verifyDIN = null;
      state.verifyDINError = true;
    },
    resetGetPanError(state) {
      state.getVerifyPanData = null;
      state.getVerifyPanError = true;
    },
    selectedCin(state, action) {
      state.selectedCinData = action.payload;
    },
    selectedDin(state, action) {
      state.selectedDinData = action.payload;
    },
    selectedPan(state, action) {
      state.selectedPanData = action.payload;
    },
    selectedGst(state, action) {
      state.selectedGstData = action.payload;
    },
    resetSelectedPan(state) {
      state.selectedPanData = null;
    },
    resetSelectedGst(state) {
      state.selectedGstData = null;
    },
    resetSelectedCin(state) {
      state.selectedCinData = null;
    },
    resetSelectedDin(state) {
      state.selectedDinData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(viewAccountDetail.pending, (state, action) => {
      state.viewAccountDetailLoading = true;
    });
    builder.addCase(viewAccountDetail.fulfilled, (state, action) => {
      state.viewAccountDetailLoading = false;
      state.viewAccountDetailData = action.payload;
    });
    builder.addCase(viewAccountDetail.rejected, (state, action) => {
      state.viewAccountDetailError = true;
      state.viewAccountDetailData = null;
    });
    builder.addCase(beneLookUp.pending, (state, action) => {
      state.beneLookLoading = true;
      state.beneLookError = false;
    });
    builder.addCase(beneLookUp.fulfilled, (state, action) => {
      state.beneLookLoading = false;
      state.beneLookData = action.payload;
    });
    builder.addCase(beneLookUp.rejected, (state, action) => {
      state.beneLookError = true;
      state.beneLookLoading = false;
      state.beneLookData = null;
    });
    builder.addCase(unachivedAccount.pending, (state, action) => {
      state.unachivedLoading = true;
    });
    builder.addCase(unachivedAccount.fulfilled, (state, action) => {
      state.unachivedLoading = false;
      state.unachivedData = action.payload;
    });
    builder.addCase(unachivedAccount.rejected, (state, action) => {
      state.unachivedError = true;
    });
    builder.addCase(achivedAccount.pending, (state, action) => {
      state.achivedLoading = true;
    });
    builder.addCase(achivedAccount.fulfilled, (state, action) => {
      state.achivedLoading = false;
      state.achivedData = action.payload;
    });
    builder.addCase(achivedAccount.rejected, (state, action) => {
      state.achivedError = true;
    });
    builder.addCase(unachivedAccountPan.pending, (state, action) => {
      state.unachivedLoadingPan = true;
    });
    builder.addCase(unachivedAccountPan.fulfilled, (state, action) => {
      state.unachivedLoadingPan = false;
      state.unachivedDataPan = action.payload;
    });
    builder.addCase(unachivedAccountPan.rejected, (state, action) => {
      state.unachivedErrorPan = true;
    });
    builder.addCase(achivedAccountPan.pending, (state, action) => {
      state.achivedLoadingPan = true;
    });
    builder.addCase(achivedAccountPan.fulfilled, (state, action) => {
      state.achivedLoadingPan = false;
      state.achivedDataPan = action.payload;
    });
    builder.addCase(achivedAccountPan.rejected, (state, action) => {
      state.achivedErrorPan = true;
    });
    builder.addCase(verifyThisPAN.pending, (state, action) => {
      state.verifyPANisLoading = true;
    });
    builder.addCase(verifyThisPAN.fulfilled, (state, action) => {
      state.verifyPANisLoading = false;
      state.verifyPANisError = false;
      state.verifyPAN = action.payload;
    });
    builder.addCase(verifyThisPAN.rejected, (state, action) => {
      state.verifyPANisError = true;
      state.verifyPAN = null;
      state.verifyPANisLoading = false;
    });
    builder.addCase(verifyThisPANGet.pending, (state, action) => {
      state.getVerifyPanLoading = true;
    });
    builder.addCase(verifyThisPANGet.fulfilled, (state, action) => {
      state.getVerifyPanLoading = false;
      state.getVerifyPanError = false;
      state.getVerifyPanData = action.payload;
    });
    builder.addCase(verifyThisPANGet.rejected, (state, action) => {
      state.getVerifyPanError = true;
      state.getVerifyPanData = null;
      state.getVerifyPanLoading = false;
    });
    builder.addCase(verifyThisBank.pending, (state, action) => {
      state.verifyBankisLoading = true;
    });
    builder.addCase(verifyThisBank.fulfilled, (state, action) => {
      state.verifyBankisLoading = false;
      state.verifyBank = action.payload;
    });
    builder.addCase(verifyThisBank.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(verifyThisCIN.pending, (state, action) => {
      state.verifyCINisLoading = true;
    });
    builder.addCase(verifyThisCIN.fulfilled, (state, action) => {
      state.verifyCinError = null;
      state.verifyCINisLoading = false;
      state.verifyCIN = action.payload;
    });
    builder.addCase(verifyThisCIN.rejected, (state, action) => {
      state.verifyCINisLoading = false;
      state.verifyCinError = true;
      state.verifyCIN = null;
    });
    builder.addCase(verifyThisDIN.pending, (state, action) => {
      state.verifyDINisLoading = true;
    });
    builder.addCase(verifyThisDIN.fulfilled, (state, action) => {
      state.verifyDINError = null;
      state.verifyDINisLoading = false;
      state.verifyDIN = action.payload;
    });
    builder.addCase(verifyThisDIN.rejected, (state, action) => {
      state.verifyDINError = true;
      state.verifyDINisLoading = false;
      state.verifyDIN = null;
    });
    builder.addCase(verifyThisPAN_New.pending, (state, action) => {
      state.verifyPAN_NewisLoading = true;
    });
    builder.addCase(verifyThisPAN_New.fulfilled, (state, action) => {
      state.verifyPAN_NewisLoading = false;
      state.verifyPAN_New = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(verifyThisPAN_New.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(verifyThisGST.pending, (state, action) => {
      state.verifyGSTisLoading = true;
    });
    builder.addCase(verifyThisGST.fulfilled, (state, action) => {
      state.verifyGSTisLoading = false;
      state.verifyGST = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(verifyThisGST.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(verifyThisIFSC.pending, (state, action) => {
      state.verifyIFSCisLoading = true;
    });
    builder.addCase(verifyThisIFSC.fulfilled, (state, action) => {
      state.verifyIFSCisLoading = false;
      state.verifyIFSC = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(verifyThisIFSC.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(unachivedAccountDetail.pending, (state, action) => {
      state.unachivedLoadingDetail = true;
    });
    builder.addCase(unachivedAccountDetail.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        toast.success("Account archived successfully", toast_position);        
      } 
      state.unachivedLoadingDetail = false;
      state.unachivedDataDetail = action.payload;
    });
    builder.addCase(unachivedAccountDetail.rejected, (state, action) => {
      state.unachivedErrorDetail = true;
    });
    builder.addCase(achivedAccountDetail.pending, (state, action) => {
      state.achivedLoadingDetail = true;
    });
    builder.addCase(achivedAccountDetail.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        toast.success("Account archived successfully", toast_position);
        
      }      
      state.achivedLoadingDetail = false;
      state.achivedDataDetail = action.payload;
    });
    builder.addCase(achivedAccountDetail.rejected, (state, action) => {            
      state.achivedErrorDetail = true;
    });
  },
});

export const {
  resetVerification,
  resetPANVerification,
  resetBankVerification,
  resetBeneData,
  resetPanArchived,
  resetPanUnarchived,
  resetDetailUnachived,
  resetDetailachived,
  resetAchivedAccount,
  resetUnAchivedAccount,
  resetInEdit,
  resetGetPanData,
  resetGetCinData,
  resetGetDinData,
  resetSelectedCin,
  resetSelectedDin,
  selectedCin,
  selectedDin,
  selectedPan,
  resetSelectedPan,
  resetGetCinError,
  resetGetDinError,
  resetGetPanError,
  selectedGst,
  resetSelectedGst,
} = verify.actions;

export default verify.reducer;
