import React, { useState, useEffect } from "react";
import NavXpentraLogo from "../../../assets/landingPage/NavXpentraLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import NavXpentraLogoMob from "../../../assets/landingPage/NavXpentraLogoMob.svg";
import HamBurgerMobile from "../../../assets/landingPage/HamBurgerMobile.svg";
import CloseWithGreyCircle from "../../../assets/landingPage/CloseWithGreyCircle.svg";

const Navbar = ({ onToggleDarkSection }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (route) => location.pathname === route;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const [isOpen, setIsOpen] = useState(false);

  const toggleHeight = () => {
    setIsOpen(!isOpen);
    onToggleDarkSection(!isOpen);
  };

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div>
        {isMobile ? (
          <div>
            <div className="fixed top-0 z-50">
              <div className="bg-[#3A5686] h-[60px] w-screen flex justify-between px-[20px] py-[14px]">
                <div
                  className="my-auto cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <img src={NavXpentraLogoMob} alt="NavXpentraLogoMob" />
                </div>
                <div className="flex gap-2.5">
                  <div
                    className="rounded-[5px] w-[91px] h-[32px] py-[8px] px-[30px] text-[12px] bg-[#f9f9f9] text-[#1D3A6D] hover:bg-[#667CA3] hover:text-[#F9F9F9] hover:font-semibold"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </div>
                  <div className="my-auto">
                    <img
                      src={HamBurgerMobile}
                      alt="HamBurgerMobile"
                      onClick={() => toggleHeight()}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`bg-custom-backdrop opacity-80 z-40 fixed top-0 left-0 w-screen h-screen ${
                isOpen ? "block" : "hidden"
              }`}
              onClick={() => toggleHeight()}
            ></div>

            <div
              className={`fixed bottom-0 w-screen z-50 transition-all duration-300 ${
                isOpen ? "h-[332px]" : "h-0"
              }`}
            >
              <div className="h-[80px]" onClick={() => toggleHeight()}>
                <img
                  className="mx-auto"
                  src={CloseWithGreyCircle}
                  alt="CloseWithGreyCircle"
                />
              </div>
              <div className="bg-[#244277] rounded-t-[20px] py-[30px]">
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => navigate("/")}
                >
                  Home
                </div>
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold  ${
                    isActive("/product") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => navigate("/product")}
                >
                  Product
                </div>
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/aboutus") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => navigate("/aboutus")}
                >
                  About Us
                </div>
                {/* <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/pricing") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => navigate("/pricing")}
                >
                  Pricing
                </div> */}
                <div
                  className={`py-[16px] pl-[30px] text-[12px] font-semibold text-[#F9F9F9] ${
                    isActive("/contactus") ? "text-[#31E5E5]" : "text-[#F9F9F9]"
                  }`}
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-[#244277] fixed top-0 w-screen h-[60px] z-50">
            <div className="flex justify-between py-[15px] w-full max-w-[1024px] mx-auto">
              <div onClick={() => navigate("/")}>
                <img
                  src={NavXpentraLogo}
                  alt="NavXpentraLogo"
                  className="cursor-pointer"
                />
              </div>
              <div className="flex gap-4 text-[#F9F9F9] text-[12px]">
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/") ? "bg-[#4A689D]" : "hover:bg-[#37558A]"
                  }`}
                  onClick={() => navigate("/")}
                >
                  Home
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/product") ? "bg-[#4A689D]" : "hover:bg-[#37558A]"
                  }`}
                  onClick={() => navigate("/product")}
                >
                  Product
                </div>
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/aboutus") ? "bg-[#4A689D]" : "hover:bg-[#37558A]"
                  }`}
                  onClick={() => navigate("/aboutus")}
                >
                  About Us
                </div>
                {/* <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/pricing") ? "bg-[#4A689D]" : "hover:bg-[#37558A]"
                  }`}
                  onClick={() => navigate("/pricing")}
                >
                  Pricing
                </div> */}
                <div
                  className={`justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer ${
                    isActive("/contactus")
                      ? "bg-[#4A689D]"
                      : "hover:bg-[#37558A]"
                  }`}
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </div>
              </div>
              <div
                className="justify-center content-center rounded-[17px] px-[25px] py-[5px] cursor-pointer bg-[#f9f9f9] text-[#1D3A6D] hover:bg-[#667CA3] hover:text-[#F9F9F9] text-[12px]"
                onClick={() => navigate("/login")}
              >
                Login
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
