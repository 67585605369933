import React from "react";
import "./index.css";
import merchant from "../../../assets/Beneficiary Icons/merchant.png";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";

const BeneTxnConfirm = (props) => {
  const renderAdditionalDetails = () => {
    if (props.txnPayLoad?.mergeCategoryDisplay) {
      return props.txnPayLoad?.mergeCategoryDisplay.map((field, index) => (
        <div key={index}>
          <Tooltip
            sx={{ zIndex: "2147483647 !important" }}
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                  zIndex: "2147483647 !important",
                },
              },
            }}
            title={field.value}
          >
            <p
              className="text-sm text-[#FFFFFF] font-sans mb-1 mt-3"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {field.value}
            </p>
          </Tooltip>

          <div className="text-xs text-[#C9C9C9] font-sans mb-3">
            {field.name}
          </div>
          {index !== props.txnPayLoad?.mergeCategory.length - 1 && (
            <hr style={{ width: "100%", border: "1px solid #1D3A6D" }} />
          )}
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="beneTxnConfirm_Main">
      <div className="divideTxnconfirm">
        <div className="w-1/2 h-full confirmright">
          <div className="bene_detail">
            <div className="bene_inside">
              <p>Beneficiary Account Details</p>
              <div className="bene_name items-center font-bold capitalize px-5 cursor-default">
                <Tooltip
                  title={props?.txnPayLoad?.bankAccountName}
                  arrow
                  placement="bottom"
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: "black",
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                          color: "black",
                        },
                      },
                    },
                  }}
                >
                  <span className="truncate">
                    {props?.txnPayLoad?.bankAccountName?.toLowerCase()}
                  </span>
                </Tooltip>
              </div>
              <div className="ass_num text-sm font-sans text-[#FFFFFF]">
                {formatAccountNumber(props.txnPayLoad?.bankAccountNo)}
              </div>
              <div className="ass_name text-xs font-sans text-[#DADADA]">
                Account number
              </div>
              <hr
                style={{
                  width: "93%",
                  marginTop: "10px",
                  border: "1px solid #1D3A6D",
                }}
              />
              <div className="ifsc_num text-sm font-sans text-[#FFFFFF]">
                {props?.txnPayLoad?.bankAccountIFSC}
              </div>
              <div className="ifsc_name">IFSC code</div>
              <div className="flex items-center justify-between ml-[314px] w-32 h-[41px] mt-[-28px]">
                <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full mt-12">
                  {
                    <img
                      src={require("../../../assets/IfscLogo/" +
                        IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                      alt="bank"
                      className="w-[70px] h-[18px]"
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="additional_detail w-full h-auto my-4 px-8 py-5 rounded-[30px] bg-[#314C7F]">
            <span className="additional_detail">Additional Details</span>
            <div className="detail_info">
              {props.txnPayLoad?.mergeCategory ? (
                renderAdditionalDetails()
              ) : (
                <div className="detail_info">
                  {props.txnPayLoad?.mergeCategory ? (
                    renderAdditionalDetails()
                  ) : (
                    <div
                      className="text-left text-white text-base antialiased"
                      style={{
                        marginTop: props.txnPayLoad?.mergeCategory
                          ? "0"
                          : "20px",
                      }}
                    >
                      Additional details are not supported for the selected
                      category.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="divideconfirm">
        <div className="w-1/2 h-fit confirmleft">
          <div className="amount items-center mb-5 flex flex-col">
            ₹ <FormatAmount price={props.txnPayLoad?.amount} />
            <hr
              style={{
                width: "85%",
                marginTop: "10px",
                border: "1px solid #1D3A6D",
              }}
            />
            <div
              className="text-sm confirmleft px-8 pt-2"
              style={{ textAlign: "left" }}
            >
              {props.txnPayLoad.amountWords}
              <br />
              <span className="text-[#DADADA] text-xs mt-[5px]">In Words</span>
            </div>
          </div>
          <div className="settle_method my-6 h-auto bg-[#314C7F] rounded-[30px]">
            <p className="settle_method_p">Source Account Details</p>

            <div className="selected_set_show flex-col">
              <div className="division">
                <div className="accNumber w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans  text-[#FFFFFF] ">
                    {formatAccountNumber(props?.txnPayLoad?.ownerBankAccountNo)}
                  </p>
                  <span className="text-xs font-sans text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className="ifsc w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans  text-[#FFFFFF] ">
                      {props?.txnPayLoad?.ownerBankIFSC}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      IFSC code
                    </span>
                  </div>
                </div>
              </div>
              <hr className="w-full mt-2.5 border border-solid border-[#1D3A6D]" />
              <div className="division mt-2.5">
                <div className="balance w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans font-semibold ">
                    <span className="amount-color">₹ </span>
                    {Number(props?.txnPayLoad?.ownerBankBalance).toFixed(2)}
                  </p>
                  <span className="text-xs font-sans">
                    {`Balance updated ${props?.txnPayLoad?.ownerLastUpdated}`}
                  </span>
                </div>
                <div className="payment_method w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans text-[#FFFFFF] ">
                      {props?.txnPayLoad?.settleType?.toUpperCase()}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      Payment method
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between ml-[314px] w-32 h-[41px] ">
              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full mt-9">
                {
                  <img
                    src={require("../../../assets/IfscLogo/" +
                      IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                    alt="bank"
                    className="w-[70px] h-[18px]"
                  />
                }
              </div>
            </div>
          </div>
          <div className="select_cat ">
            <p className="select_cat_p "> Transaction Category</p>
            <div className="selected_cat_show flex items-center">
              <img src={merchant} alt="Merchant Logo" />
              <Tooltip
                title={props?.selectedCategory?.name}
                arrow
                placement="bottom"
                PopperProps={{
                  style: {
                    maxWidth: "none",
                  },
                }}
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      [`& .${tooltipClasses.arrow}`]: {
                        color: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
              >
                <p className="truncate" style={{ width: "325px" }}>
                  {props?.selectedCategory?.name}
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnConfirm;
