import React, { useEffect, useState } from "react";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";

import arrowRight from "../../../../assets/Accounts/Create/arrowright.png";
import {
  verifyThisPANGet,
  selectedPan,
} from "../../../../redux/features/verifyPAN/verifyPAN";

import { checkUsertoken } from "../../../../redux/features/login/login";
import formatGSTDate from "../../../../utils/formatGSTDate";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import gst_verification_blue from "../../../../assets/Settings/Verification/gst_verification_blue.svg";

const SettingGSTDetails = ({ setGstNotFound }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z0-9]{1}$/;

  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  return (
    <div className="gst-main-div">
      {loading && !beneVerifyGST ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <>
          <div className="div-fixed-setting-gst">
            <div className="profile-setting">
              <div className="gst-container" id="container">
                <div className="gst-acc-section">
                  <div className="gst-major-div">
                    <div className="gst-individual-person-detail">
                      <div className="gst-individual-name">
                        <div className="gst-account-name">
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                            }}
                          >
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={beneVerifyGST?.gstin_details?.business_name.toLowerCase()}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {beneVerifyGST?.gstin_details?.business_name.toLowerCase()}
                            </Tooltip>
                          </div>
                        </div>
                        <div className="gst-acc-name-label">Company name</div>
                      </div>
                      <div className="gst-acc-detail">
                        <div className="gst-acc-content">
                          <div className={`text-white text-sm`}>
                            {beneVerifyGST?.gstin_details?.gstin_no}
                          </div>
                          <div className="gst-acc-name-label">GST No.</div>
                        </div>
                      </div>
                    </div>
                    <div className="gst-account-holder-details">
                      <div className="gst-individual-name">
                        <span className="text-white text-sm">
                          {formatGSTDate(
                            beneVerifyGST?.gstin_details?.date_of_registration
                          )}
                        </span>
                        <span className="gst-acc-number-label text-xs">
                          Estd. on
                        </span>
                      </div>
                      <div className="gst-div-main">
                        <div
                          className="gst-div-content"
                          onClick={() => {
                            dispatch(checkUsertoken());
                            dispatch(
                              selectedPan(beneVerifyGST?.gstin_details?.pan_no)
                            );
                            dispatch(
                              verifyThisPANGet({
                                search_string:
                                  beneVerifyGST?.gstin_details?.pan_no,
                              })
                            );

                            navigate(`/settings/PAN_Verification`);
                          }}
                        >
                          <div className="hover:bg-[#667CA3] cursor-pointer rounded-lg flex flex-row h-full w-full p-[2px] ">
                            <div className="ifsc-response ">
                              {beneVerifyGST?.gstin_details?.pan_no}
                            </div>
                            <div className="grid place-content-center w-5 h-5 ml-[10px] bg-[#1D3A6D] rounded-md ">
                              <img
                                src={arrowRight}
                                alt="document"
                                className="w-2.5 h-2.5"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="gst-code-element text-xs p-[2px]">
                          PAN number
                        </div>
                      </div>
                    </div>
                    <div className="gst-account-holder-details-no-bot">
                      <div className="gst-individual-name">
                        <span className="text-white text-sm">
                          {
                            beneVerifyGST?.gstin_details
                              ?.constitution_of_business
                          }
                        </span>
                        <span className="gst-acc-number-label text-xs">
                          Listing
                        </span>
                      </div>
                      <div className="gst-bank-code">
                        <div className="gst-acc-content">
                          <div className="text-white text-sm">
                            <div
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "block",
                              }}
                            >
                              {beneVerifyGST?.gstin_details?.status}
                            </div>
                          </div>
                          <span className="gst-acc-number-label text-xs">
                            Company status
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="gst-minor-div">
                    <div className="gst-recipient flex flex-row">
                      <div className="w-1/2 flex-grow bg-[#3a5686]"></div>
                      <div className="flex-grow w-1/2 gst-verification">
                        <Box className=" bg-[#F9F9F9] opacity-100 text-center h-full flex flex-col justify-center gap-1  p-2">
                          <div className="gst-recipient-image-div flex justify-center">
                            <Box className="flex justify-center w-10">
                              <img
                                src={gst_verification_blue}
                                alt="document"
                                className="gst-recipient-img"
                              />
                            </Box>
                          </div>
                        </Box>
                      </div>
                    </div>
                    <div className="gst-recipient flex flex-row bg-[#506994]">
                      <div className="w-1/2 flex-grow bg-[#3a5686]"></div>
                      <div className="w-1/2 flex-grow bg-[#3a5686]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-auto-setting-gst">
            <div className="location-div">
              <div className="parent-div gst-location">
                <div className="gst-corp-divide-one">
                  <div className="gst-title-label-block">
                    <div className="gst-corp-sub-name gst-address-corp">
                      Corporate Address
                    </div>
                  </div>
                  <div className="gst-title-label-block">
                    <div className="gst-corp-sub-name capitalize">
                      {beneVerifyGST?.gstin_details?.address?.toLowerCase()}
                    </div>
                  </div>
                </div>
                <div className="gst-corp-divide-two">
                  <div className="gst-geo-map-pad">
                    <>
                      <GeoMapLeaflet
                        address={beneVerifyGST?.gstin_details?.address}
                        owner={"gstDetails"}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingGSTDetails;
