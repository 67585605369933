import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import NoAccountFound from "../../../../assets/Beneficiary Icons/noaccfound.png";
import "./index.css";
import { formatAccountNumber } from "../../../_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";

const BeneAssociateAccount = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmButton, setConfirmButton] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (!beneLookLoading && !verifyPANisLoading) {
      setLoading(false);
    }
  }, [beneLookLoading, verifyPANisLoading]);

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBankApproved = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let haveBankPending = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "denied":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const handleButtonClick = (dataItem, index) => {
    if (
      dataItem.ifsc_code === props.IFSC &&
      dataItem.bank_acc_no === props.accountNumber
    ) {
      setSelectedAccount(dataItem);
      setConfirmButton(true);
      setSelectedIndex(null);
    } else {
      setSelectedAccount(dataItem);
      setSelectedIndex(index);
      setConfirmButton(true);
    }
  };

  const handleClick = () => {
    if (isActive) {
      props.onAccountSelect(
        selectedAccount.name,
        selectedAccount.bank_acc_no,
        selectedAccount.ifsc_code,
        selectedAccount.bank_name,
        selectedAccount.category,
        selectedAccount.pan_no,
        selectedAccount.id
      );
      setConfirmButton(true);
      props.onAsscociateClose();
    } else {
      props.onAccountSelect(
        props.accountName,
        selectedAccount.bank_acc_no,
        selectedAccount.ifsc_code,
        selectedAccount.bank_name,
        selectedAccount.category,
        selectedAccount.pan_no,
        selectedAccount.id
      );
      setConfirmButton(true);
      props.onAsscociateClose();
    }
  };

  const toggleSelectionPan = (dataItem, index) => {
    if (
      dataItem.ifsc_code !== props.IFSC &&
      dataItem.bank_acc_no !== props.accountNumber
    ) {
      if (selectedIndex === index) {
        setSelectedIndex(null);
        setConfirmButton(true);
      } else {
        setSelectedIndex(index);
        setConfirmButton(false);
      }
    }
  };
  const toggleSelectionBank = (dataItem, index) => {
    if (
      dataItem.id !== props.beneAccountId &&
      dataItem.pan_no !== props.panNumber
    ) {
      if (selectedIndex === index) {
        setSelectedIndex(null);
        setConfirmButton(true);
      } else {
        setSelectedIndex(index);
        setConfirmButton(false);
      }
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 z-[9998]"></div>
      <div
        className="flex justify-center items-center fixed top-1/2 left-1/2 z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-5"
          style={{ width: "50vw", maxWidth: "600px" }}
        >
          <div className="flex justify-between">
            <h6 className="text-[#FF7F33] text-[20px] text-center pr-6 pl-9 pt-5 ">
              Active associated accounts
            </h6>
          </div>
          <div className="flex justify-between">
            <span className="text-[#DADADA] text-[14px] text-start pr-6 pl-9 pb-3 pt-3">
              The accounts listed below are linked to the selected account. If
              you want to
              <br /> change the beneficiary, select another account.
            </span>
          </div>

          <div className="associatebutton pr-6 pl-2">
            <Button
              className="associatebuttonPan"
              onClick={() => {
                setIsActive(false);
                setSelectedAccount(null);
                setSelectedIndex(null);
                setConfirmButton(true);
              }}
              style={{
                backgroundColor: !isActive ? "#ffffff" : "#373F4B",
                color: !isActive ? "#1d1d1d" : "#f9f9f9",
              }}
            >
              Associated to PAN
            </Button>
            <Button
              className="associatebuttonBank"
              onClick={() => {
                setIsActive(true);

                setSelectedAccount(null);
                setSelectedIndex(null);
                setConfirmButton(true);
              }}
              style={{
                backgroundColor: isActive ? "#ffffff" : "#373F4B",
                color: isActive ? "#1d1d1d" : "#f9f9f9",
              }}
            >
              Associated to Bank
            </Button>
          </div>

          <div className="w-full overflow-y-auto h-[445px] pr-7 pl-8 custom-scroll-container">
            {isActive ? (
              <div>
                {loading ? (
                  <div className="loader-container flex justify-center items-center mt-6">
                    <img src={dataLoading} alt="Loading" />
                  </div>
                ) : (
                  <>
                    <div className="">
                      {haveBankApproved || haveBankPending ? (
                        <>
                          {haveBankApproved && (
                            <>
                              <p className="text-xs text-white m-2">
                                Approved accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {bankData?.beneficiary_accounts.map(
                                      (val, index) => {
                                        if (
                                          val?.approval_status === "approved"
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                    props.IFSC &&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, index);
                                                toggleSelectionBank(val, index);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === index
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {val.pan_no}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Pan number
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {haveBankPending && (
                            <div className="">
                              <p className="text-xs text-white m-2">
                                Pending accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {bankData?.beneficiary_accounts.map(
                                      (val, index) => {
                                        if (
                                          val?.approval_status === "pending"
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                    props.IFSC &&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, index);
                                                toggleSelectionBank(val, index);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === index
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {val.pan_no}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Pan number
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                          <div className="w-[40%] p-4 flex flex-row justify-center">
                            <img
                              className="max-w-[145%] pt-5"
                              src={NoAccountFound}
                              alt="NoDataImg"
                            />
                          </div>
                          <div className="text-white text-lg antialiased">
                            <p>
                              No associated accounts found for the Bank details
                              provided
                              <br />
                              <span className="text-xs">
                                There are no existing accounts that are
                                associated with the Bank details provided.
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                {loading ? (
                  <div className="loader-container flex justify-center items-center h-full mt-6">
                    <img src={dataLoading} alt="Loading" />
                  </div>
                ) : (
                  <>
                    {haveApproved || havePending ? (
                      <>
                        {haveApproved && (
                          <>
                            <p className="text-xs text-white m-2">
                              Approved accounts
                            </p>
                            <div className="achieved_main">
                              <div className="achieved_scroll">
                                <div className="flex flex-col gap-5 pb-[5px] py-2">
                                  {beneLookData?.beneficiary_accounts.map(
                                    (val, index) => {
                                      if (val?.approval_status === "approved") {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              border:
                                                val.ifsc_code === props.IFSC &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",
                                            }}
                                            onClick={() => {
                                              handleButtonClick(val, index);
                                              toggleSelectionPan(val, index);
                                            }}
                                            className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                              selectedIndex === index
                                                ? "bg-[#667CA3]"
                                                : "bg-[#3A5686]"
                                            }`}
                                          >
                                            <div className="flex flex-row -mr-4">
                                              <div
                                                className="w-4/5 antialiased truncate"
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: " nowrap",
                                                  textTransform: "capitalize",
                                                  color: getStatusColor(
                                                    val.approval_status
                                                  ),
                                                }}
                                              >
                                                {val.name.toLowerCase()}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Account
                                                  </span>
                                                  &nbsp;
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >
                                                    name
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                <button className="group">
                                                  <div
                                                    className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                    style={{
                                                      position: "relative",
                                                      borderRadius: "0px 20px",
                                                    }}
                                                  >
                                                    <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                      <img
                                                        src={require("../../../../assets/IfscLogo/" +
                                                          IfscCodeMapper(
                                                            val?.ifsc_code
                                                          ))}
                                                        alt="bank"
                                                        className="view-all-bank-image"
                                                      />
                                                    </div>
                                                  </div>
                                                </button>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-between">
                                              <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  Account number
                                                </div>
                                              </div>

                                              <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                {val.ifsc_code}
                                                {val.ifsc_code && (
                                                  <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                    IFSC Code
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {havePending && (
                          <div className="">
                            <p className="text-xs text-white m-2">
                              Pending accounts
                            </p>
                            <div className="achieved_main">
                              <div className="achieved_scroll">
                                <div className="flex flex-col gap-5 pb-[5px] py-2">
                                  {beneLookData?.beneficiary_accounts.map(
                                    (val, index) => {
                                      if (val?.approval_status === "pending") {
                                        return (
                                          <div
                                            key={index}
                                            onClick={() => {
                                              handleButtonClick(val, index);
                                              toggleSelectionPan(val, index);
                                            }}
                                            className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                              selectedIndex === index
                                                ? "bg-[#667CA3]"
                                                : "bg-[#3A5686]"
                                            }`}
                                            style={{
                                              border:
                                                val.ifsc_code === props.IFSC &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",
                                            }}
                                          >
                                            <div className="flex flex-row -mr-4">
                                              <div
                                                className="w-4/5 antialiased truncate"
                                                style={{
                                                  color: getStatusColor(
                                                    val.approval_status
                                                  ),

                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {val.name.toLowerCase()}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Account
                                                  </span>
                                                  &nbsp;
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >
                                                    name
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                <button className="group">
                                                  <div
                                                    className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                    style={{
                                                      position: "relative",
                                                      borderRadius: "0px 20px",
                                                    }}
                                                  >
                                                    <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                      <img
                                                        src={require("../../../../assets/IfscLogo/" +
                                                          IfscCodeMapper(
                                                            val?.ifsc_code
                                                          ))}
                                                        alt="bank"
                                                        className="view-all-bank-image"
                                                      />
                                                    </div>
                                                  </div>
                                                </button>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-between">
                                              <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  Account number
                                                </div>
                                              </div>

                                              <div className="text-[#FAFAFA] visible self-center text-base antialiased ">
                                                {val.ifsc_code}
                                                {val.ifsc_code && (
                                                  <div className="text-[#D5D5D5] text-xs antialiased ml-11">
                                                    IFSC Code
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] p-4 flex flex-row justify-center">
                          <img
                            className="max-w-[145%] pt-5"
                            src={NoAccountFound}
                            alt="NoDataImg"
                          />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6%",
              margin: "0px 50px 0px 40px",
              width: "100%",
            }}
            className="pr-8 pt-4 m-4"
          >
            <Button
              sx={{
                borderColor: "none",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
                backgroundColor: "transparent",
                fontFamily: "Open Sans",
                fontSize: "14px",
              }}
              onClick={props.onAsscociateClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                borderRadius: "20px",
                "&.Mui-disabled": {
                  color: confirmButton ? "#D5D5D5" : "#fff",
                },
                "&.MuiButton-root": {
                  color: confirmButton ? "#D5D5D5" : "#fff",
                },
                boxShadow: "0px 3px 6px #00000033",
                textTransform: "capitalize",
                fontFamily: "Open Sans",
                fontSize: "14px",
                backgroundColor: confirmButton ? "transparent" : "#69B76F",
                cursor: confirmButton ? "not-allowed" : "pointer",
                border: confirmButton ? "1px solid #CCCCCC" : "transparent",
                marginRight: "40px",
                width: "110px",
                "&:hover": {
                  backgroundColor: confirmButton ? "transparent" : "#69B76F",
                  boxShadow: "none",
                },
              }}
              onClick={handleClick}
              disabled={confirmButton}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneAssociateAccount;
