import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import termsimage from "../../assets/Landing/terms.png";
import pci from "../../assets/Landing/pci.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";
import "../../assets/Landing/css/owl.carousel.css";

const PrivacyPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const workSectionRef = useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text ">
                  <h2 className="privacy mt-[-40px]">Terms and Conditions</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-section boxshadow spad">
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer">
            <div className="vertical-center-inner1">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      Using the functionality and services offered on this
                      website means that you accept all of the terms and
                      conditions contained in the User Agreement signed between
                      the Corporation and Xpentra technologies Pvt Ltd
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <img className="landingimg" src={termsimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>
    </div>
  );
};

export default PrivacyPage;
