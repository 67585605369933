import { Tooltip, tooltipClasses } from "@mui/material";
import React from "react";

const Datacard = (props) => {
  const numberTransformation = (number) => {
    if (number > 99) {
      return "99+";
    } else if (number < 10 && number > 0) {
      return "0" + Number(number);
    } else if (number <= 0) {
      return "00";
    } else {
      return number;
    }
  };
  const colorProp = props.color;
  const numberProp = numberTransformation(props.number);
  const textProp = props.text;
  const imgProp = props.imgName;
  // const pathProp = props.path;
  const handleDatacardClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.handleStatus) {
      props.handleStatus("approved");
    }
  };

  return (
    <div
      className={`datacard  ${
        numberProp !== "00" ? "cursor-pointer hover:bg-custom-cardHover" : ""
      } ${numberProp === "00" ? "opacity-40 cursor-default" : ""}`}
      onClick={numberProp !== "00" ? handleDatacardClick : undefined}
      style={{
        opacity: numberProp === "00" ? "0.5" : "1",
      }}
    >
      <div className="colorpage" style={{ backgroundColor: colorProp }}></div>

      <div className="cardpage pt-2">
        {imgProp ? (
          <div className="image-container">
            <img
              className="image"
              src={require(`../../assets/TransactionDashboardIcons/${imgProp}`)}
              alt=""
            />
          </div>
        ) : (
          <div className="word1" style={{ color: colorProp }}>
            {numberProp}
          </div>
        )}
        <Tooltip
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                },
              },
            },
          }}
          title={textProp}
        >
          <div className="w-[90px] antialiased break-words text-sm text-white capitalize overflow-hidden line-clamp-2">
            {textProp}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Datacard;
