import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import Refreshbalancecard from "../../../../assets/OwnerAccount/Refresh balance - card.svg";
import BalanceErrorIcon from "../../../../assets/OwnerAccount/balance_error_icon.png";
import Info from "../../../../assets/OwnerAccount/view statement - card.svg";
import BalanceLoader from "../../../_utils/BalanceLoader/index";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OwnerViewStatementModal from "../OwnerViewStatementModal";
import { setStoredPage } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import AnimationUpAndDown from "../../../BeneTransaction/OwnerPopup/AnimationUpAndDown/index";
import { formatAccountNumber } from "../../../_utils/FormatAccountNumber";

const ListView = ({
  data,
  handleRefresh,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  splitView,
  postiveBalanceData,
  negativeBalanceData,
  refreshAll,
  typepop,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  BenEnableNextButton,
  setOpenOwner,
  flow,
}) => {
  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const [accId, setAccId] = useState("");
  const [statementId, setStatementId] = useState("");
  const [accData, setAccData] = useState("");
  const [prevId, setPrevId] = useState("");
  const [prevBalance, setPrevBalance] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listdata = data;
  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  const handleCloseModal = () => {
    setViewstatementOpenPop(false);
  };

  const handleAmount = (num) => {
    const formattedPrice = Intl.NumberFormat("en-IN", {
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(num));

    const [integerPart, decimalPart] = formattedPrice.split(".");
    const paddedIntegerPart = integerPart.padStart(2, "0");
    return `${paddedIntegerPart}.${decimalPart}`;
  };

  return (
    <>
      {viewStatementOpenPop && (
        <div className="fixed left-0 w-full top-0 h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[99999]">
          {
            <OwnerViewStatementModal
              statementId={statementId}
              allData={accData}
              onClose={handleCloseModal}
            />
          }
        </div>
      )}
      {splitView ? (
        <div className="w-[1100px] mx-auto flex flex-col px-[40px]">
          <div className="split_view_header">
            <div style={{ marginLeft: "-15px" }} className="w-[50%]">
              <p>Positive Balance</p>
            </div>
            <div className="w-[50%] pl-[47px]">
              <p>Negative Balance</p>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-[50%] flex flex-wrap animation_test pb-[100px]">
              {postiveBalanceData.length > 0 ? (
                <div>
                  {postiveBalanceData.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          maxWidth: "425px",
                          height: "49px",
                          margin: "3px",
                        }}
                      >
                        <div
                          onClick={async () => {
                            if (typepop === "popup") {
                              if (flow === "createTxn") {
                                setTxnPayLoad({
                                  ...txnPayLoad,
                                  ownerId: item?.id,
                                  ownerBankIFSC: item?.ifsc_code,
                                  ownerBankBalance: item?.owner_account_balance,
                                  ownerBankLogo: item?.bank_name,
                                  ownerLastUpdated:
                                    item?.owner_account_balance_last_fetched_at_in_words,
                                  ownerBankAccountNo: item?.bank_acc_no,
                                  settleType: "neft",
                                });
                                setIsNeft(true);
                                setIsImps(false);
                                setIsRtgs(false);
                                BenEnableNextButton(false);
                              }
                              setOpenOwner(false);
                            } else {
                              let selection = window.getSelection();
                              if (selection.toString().length === 0) {
                                if (!isopen) {
                                  await dispatch(setStoredPage(page));
                                  navigate(
                                    `/accounts/owner/owner_account_details/${item.id}`
                                  );
                                }
                              }
                            }
                          }}
                          key={item.id}
                          className="list-section"
                        >
                          <div className="bank-div-img">
                            {
                              <img
                                style={{ backgroundColor: "#fff" }}
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(item.ifsc_code))}
                                alt="bank"
                              />
                            }
                            <div className="onhover">
                              <div
                                style={{
                                  marginTop: "4px",
                                  display: "flex",
                                  width: "422px",
                                  height: "43px",
                                  borderRadius: "0px 0px 10px 10px",
                                  backgroundColor:
                                    item.increaseOrDecrease == "increased"
                                      ? "#105023"
                                      : item.increaseOrDecrease == "decreased"
                                      ? "#4F1616"
                                      : "#0C2858",
                                }}
                              >
                                <div
                                  style={{ width: "69px", minWidth: "69px" }}
                                ></div>
                                {successRefreshId.includes(item.id) ? (
                                  <div
                                    style={{
                                      width: "178px",
                                      paddingTop: "5px",
                                      borderLeft: "2px solid #3D5379",
                                      borderRight: "2px solid #3D5379",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 12px/17px Open Sans",
                                        color:
                                          item.increaseOrDecrease ===
                                          "increased"
                                            ? "#4DE659"
                                            : item.increaseOrDecrease ===
                                              "decreased"
                                            ? "#FF5860"
                                            : "#F9F9F9",
                                      }}
                                    >
                                      {
                                        formatTotalBalance(
                                          item.difference,
                                          currency
                                        ).amount
                                      }
                                    </p>

                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 10px/14px Open Sans",
                                        color: "#DADADA",
                                      }}
                                    >
                                      Balance{" "}
                                      {item.increaseOrDecrease == ""
                                        ? "increased"
                                        : item.increaseOrDecrease}{" "}
                                      by{" "}
                                      {formatTotalBalance(
                                        item.difference,
                                        currency
                                      ).prefix == ""
                                        ? ""
                                        : "- in " +
                                          formatTotalBalance(
                                            item.difference,
                                            currency
                                          ).prefix}
                                    </p>
                                  </div>
                                ) : failedRefreshId.includes(item.id) ? (
                                  <p
                                    style={{
                                      paddingTop: "10px",
                                      paddingLeft: "50px",
                                      position: "absolute",
                                      width: "422px",
                                      left: "0px",
                                      backgroundColor: "#0C2858",
                                      height: "43px",
                                      marginTop: "0px",
                                      color: "#DADADA",
                                      borderRadius: "0px 0px 10px 10px ",
                                      boxShadow: "0px 0px -9px 0px #00000029",
                                      font: "normal normal normal 12px/17px Open Sans",
                                    }}
                                  >
                                    Currently unable to fetch balance details.
                                    Please try again later.
                                  </p>
                                ) : (
                                  <div
                                    style={{
                                      width: "178px",
                                      borderLeft: "2px solid #3D5379",
                                      borderRight: "2px solid #3D5379",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 12px/17px Open Sans",
                                        color: "#F9F9F9",
                                      }}
                                    >
                                      {item.ifsc_code}
                                    </p>

                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 10px/14px Open Sans",
                                        color: "#DADADA",
                                      }}
                                    >
                                      IFSC code
                                    </p>
                                  </div>
                                )}
                                <div
                                  style={{
                                    height: "43px",
                                    width: "175px",
                                    marginTop: "0px",
                                    borderRadius: "0px 0px 10px 0px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "0px 30px 0px 0px",
                                      font: "normal normal normal 12px/17px Open Sans",
                                      color: "#F9F9F9",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item.owner_account_balance_last_fetched_at_in_words
                                      ? item.owner_account_balance_last_fetched_at_in_words
                                      : "Not applicable"}
                                  </p>

                                  <p
                                    style={{
                                      padding: "0px 30px 0px 0px",
                                      textAlign: "right",
                                      font: "normal normal normal 10px/14px Open Sans",
                                      color: "#DADADA",
                                    }}
                                  >
                                    Balance updated
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accounts-div">
                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 12px/17px Open Sans",
                              }}
                            >
                              {formatAccountNumber(item.bank_acc_no)}
                            </p>

                            <p
                              className="w-[180px] marquee overflow-hidden whitespace-nowrap overflow-ellipsis font-normal pt-1  text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 10px/14px Open Sans",
                              }}
                            >
                              <p>{item.name}</p>
                            </p>
                          </div>
                          <div>
                            <div className="balance-data-div">
                              {failedRefreshId.includes(item.id) ? (
                                <div
                                  className="arrow_animate"
                                  style={{
                                    position: "relative",
                                    width: "20px",
                                    height: "20px",
                                    padding: "2px 0px 0px 2px",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "19px",
                                      height: "19px",
                                      objectFit: "cover",
                                    }}
                                    src={BalanceErrorIcon}
                                    alt="Error"
                                  />
                                </div>
                              ) : (
                                <div className="arrow_animate">
                                  <AnimationUpAndDown
                                    loader={ownerAccBalanceLoading}
                                    currentId={item?.id}
                                    previousId={
                                      refreshType == false
                                        ? false
                                        : refreshType == "single"
                                        ? prevId
                                        : true
                                    }
                                    previousBalance={prevBalance}
                                    currentBalance={item?.owner_account_balance}
                                    refreshType={refreshType}
                                    allPrevData={
                                      refreshAllData.find(
                                        (acc) => acc.id == item.id
                                      )?.prevbalance
                                    }
                                  />
                                </div>
                              )}
                              <p
                                style={{
                                  font: "normal normal normal 12px/17px Open Sans",
                                  textAlign: "right",
                                  color:
                                    item.owner_account_balance < 0
                                      ? "#FF5860"
                                      : "#4DE659",
                                }}
                              >
                                {item?.owner_account_balance ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                            position: "absolute",
                                            top: "-8px",
                                            left: "-20px",
                                          },
                                          position: "absolute",
                                          top: "-8px",
                                          left: "-20px",
                                        },
                                      },
                                    }}
                                    title={
                                      "₹" +
                                      handleAmount(
                                        Number(item.owner_account_balance)
                                      )
                                    }
                                  >
                                    {"₹ " +
                                      formatTotalBalance(
                                        Number(item.owner_account_balance),
                                        currency
                                      ).amount}
                                    {item.owner_account_balance &&
                                    formatTotalBalance(
                                      Number(item.owner_account_balance),
                                      currency
                                    ).prefix == "" ? (
                                      <p
                                        style={{
                                          font: "normal normal normal 12px/17px Open Sans",
                                          color: "#dadada",
                                        }}
                                      >
                                        Balance
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          font: "normal normal normal 12px/17px Open Sans",
                                          color: "#dadada",
                                        }}
                                      >
                                        Balance - in{" "}
                                        {
                                          formatTotalBalance(
                                            Number(item.owner_account_balance),
                                            currency
                                          ).prefix
                                        }
                                      </p>
                                    )}
                                  </Tooltip>
                                ) : (
                                  <>
                                    <p>Not Available</p>
                                    <p style={{ color: "#dadada" }}>Balance</p>
                                  </>
                                )}
                              </p>

                              <div className="balance_last_div">
                                <div
                                  onClick={(e) => {
                                    if (
                                      !isopen &&
                                      !refreshAll &&
                                      item.connected_banking === "Y"
                                    ) {
                                      e.stopPropagation();
                                      setFailedRefreshId([]);
                                      setSuccessRefreshId([]);
                                      setAccId(item.id);
                                      setPrevId(item.id);
                                      setRefreshtype("single");
                                      setPrevBalance(
                                        item.owner_account_balance
                                      );
                                      handleRefresh(item.id, true);
                                    } else {
                                      e.stopPropagation();
                                    }
                                  }}
                                  className="refresh-balance"
                                >
                                  {ownerAccBalanceLoading &&
                                  accId == item.id ? (
                                    <BalanceLoader
                                      loader={ownerAccBalanceLoading}
                                    />
                                  ) : !ownerAccBalanceLoading &&
                                    !ownerAccBalanceError &&
                                    accId == item.id ? (
                                    <BalanceLoader
                                      loader={ownerAccBalanceLoading}
                                      setAccId={setAccId}
                                    />
                                  ) : (
                                    item.connected_banking === "Y" && (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                  position: "absolute",
                                                  top: "-8px",
                                                  left: "-10px",
                                                  zIndex: "214379",
                                                },
                                              position: "absolute",
                                              top: "-8px",
                                              left: "-10px",
                                              zIndex: "214379",
                                            },
                                          },
                                        }}
                                        title="Refresh"
                                      >
                                        <img
                                          src={Refreshbalancecard}
                                          alt="Refresh"
                                        />
                                      </Tooltip>
                                    )
                                  )}
                                </div>
                                <div
                                  onClick={(e) => {
                                    if (
                                      !isopen &&
                                      item.connected_banking === "Y"
                                    ) {
                                      e.stopPropagation();
                                      setAccData(item);
                                      setStatementId(item.id);
                                      handleViewStatement();
                                    }
                                  }}
                                  className="info-div"
                                >
                                  {item.connected_banking === "Y" && (
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                              position: "absolute",
                                              top: "-8px",
                                              left: "-10px",
                                              zIndex: "214379",
                                            },
                                            position: "absolute",
                                            top: "-8px",
                                            zIndex: "214379",
                                            left: "-10px",
                                          },
                                        },
                                      }}
                                      title="View statement"
                                    >
                                      <img src={Info} alt="Refresh" />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p style={{ padding: "0" }} className="noaccountfound">
                  No accounts were found with a positive balance.
                </p>
              )}
            </div>
            <div className="owner_split_div"></div>
            <div className="w-[50%] pl-[30px] animation_test2 pb-[100px]">
              {negativeBalanceData.length > 0 ? (
                <div>
                  {negativeBalanceData.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        style={{
                          maxWidth: "425px",
                          height: "49px",
                          margin: "3px",
                        }}
                      >
                        <div
                          onClick={async () => {
                            let selection = window.getSelection();
                            if (selection.toString().length === 0) {
                              if (!isopen) {
                                await dispatch(setStoredPage(page));
                                navigate(
                                  `/accounts/owner/owner_account_details/${item.id}`
                                );
                              }
                            }
                          }}
                          key={item.id}
                          className="list-section"
                        >
                          <div className="bank-div-img">
                            {
                              <img
                                style={{ backgroundColor: "#fff" }}
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(item.ifsc_code))}
                                alt="bank"
                              />
                            }
                            <div className="onhover">
                              <div
                                style={{
                                  marginTop: "4px",
                                  display: "flex",
                                  width: "422px",
                                  height: "43px",
                                  borderRadius: "0px 0px 10px 10px",
                                  backgroundColor:
                                    item.increaseOrDecrease == "increased"
                                      ? "#105023"
                                      : item.increaseOrDecrease == "decreased"
                                      ? "#4F1616"
                                      : "#0C2858",
                                }}
                              >
                                <div
                                  style={{ width: "69px", minWidth: "69px" }}
                                ></div>
                                {successRefreshId.includes(item.id) ? (
                                  <div
                                    style={{
                                      width: "178px",
                                      paddingTop: "5px",
                                      borderLeft: "2px solid #3D5379",
                                      borderRight: "2px solid #3D5379",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 12px/17px Open Sans",
                                        color:
                                          item.increaseOrDecrease ===
                                          "increased"
                                            ? "#4DE659"
                                            : item.increaseOrDecrease ===
                                              "decreased"
                                            ? "#FF5860"
                                            : "#F9F9F9",
                                      }}
                                    >
                                      {
                                        formatTotalBalance(
                                          item.difference,
                                          currency
                                        ).amount
                                      }
                                    </p>

                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 10px/14px Open Sans",
                                        color: "#DADADA",
                                      }}
                                    >
                                      Balance{" "}
                                      {item.increaseOrDecrease == ""
                                        ? "increased"
                                        : item.increaseOrDecrease}{" "}
                                      by{" "}
                                      {formatTotalBalance(
                                        item.difference,
                                        currency
                                      ).prefix == ""
                                        ? ""
                                        : "- in " +
                                          formatTotalBalance(
                                            item.difference,
                                            currency
                                          ).prefix}
                                    </p>
                                  </div>
                                ) : failedRefreshId.includes(item.id) ? (
                                  <p
                                    style={{
                                      paddingTop: "10px",
                                      paddingLeft: "50px",
                                      position: "absolute",
                                      width: "422px",
                                      left: "0px",
                                      backgroundColor: "#0C2858",
                                      height: "43px",
                                      marginTop: "0px",
                                      color: "#DADADA",
                                      borderRadius: "0px 0px 10px 10px ",
                                      boxShadow: "0px 0px -9px 0px #00000029",
                                      font: "normal normal normal 12px/17px Open Sans",
                                    }}
                                  >
                                    Currently unable to fetch balance details.
                                    Please try again later.
                                  </p>
                                ) : (
                                  <div
                                    style={{
                                      width: "178px",
                                      borderLeft: "2px solid #3D5379",
                                      borderRight: "2px solid #3D5379",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 12px/17px Open Sans",
                                        color: "#F9F9F9",
                                      }}
                                    >
                                      {item.ifsc_code}
                                    </p>

                                    <p
                                      style={{
                                        padding: "0px 8px",
                                        font: "normal normal normal 10px/14px Open Sans",
                                        color: "#DADADA",
                                      }}
                                    >
                                      IFSC code
                                    </p>
                                  </div>
                                )}
                                <div
                                  style={{
                                    height: "43px",
                                    width: "175px",
                                    marginTop: "0px",
                                    borderRadius: "0px 0px 10px 0px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "0px 30px 0px 0px",
                                      font: "normal normal normal 12px/17px Open Sans",
                                      color: "#F9F9F9",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item.owner_account_balance_last_fetched_at_in_words
                                      ? item.owner_account_balance_last_fetched_at_in_words
                                      : "Not applicable"}
                                  </p>

                                  <p
                                    style={{
                                      padding: "0px 30px 0px 0px",
                                      textAlign: "right",
                                      font: "normal normal normal 10px/14px Open Sans",
                                      color: "#DADADA",
                                    }}
                                  >
                                    Balance updated
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accounts-div">
                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 12px/17px Open Sans",
                              }}
                            >
                              {formatAccountNumber(item.bank_acc_no)}
                            </p>

                            <p
                              className="w-[180px] marquee overflow-hidden whitespace-nowrap overflow-ellipsis font-normal pt-1  text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 10px/14px Open Sans",
                              }}
                            >
                              <p>{item.name}</p>
                            </p>
                          </div>
                          <div>
                            <div className="balance-data-div">
                              {failedRefreshId.includes(item.id) ? (
                                <div
                                  className="arrow_animate"
                                  style={{
                                    position: "relative",
                                    width: "20px",
                                    height: "20px",
                                    padding: "2px 0px 0px 2px",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "19px",
                                      height: "19px",
                                      objectFit: "cover",
                                    }}
                                    src={BalanceErrorIcon}
                                    alt="Error"
                                  />
                                </div>
                              ) : (
                                <div className="arrow_animate">
                                  <AnimationUpAndDown
                                    loader={ownerAccBalanceLoading}
                                    currentId={item?.id}
                                    previousId={
                                      refreshType == false
                                        ? false
                                        : refreshType == "single"
                                        ? prevId
                                        : true
                                    }
                                    previousBalance={prevBalance}
                                    currentBalance={item?.owner_account_balance}
                                    refreshType={refreshType}
                                    allPrevData={
                                      refreshAllData.find(
                                        (acc) => acc.id == item.id
                                      )?.prevbalance
                                    }
                                  />
                                </div>
                              )}
                              <p
                                style={{
                                  font: "normal normal normal 12px/17px Open Sans",
                                  textAlign: "right",
                                  color:
                                    item.owner_account_balance < 0
                                      ? "#FF5860"
                                      : "#4DE659",
                                }}
                              >
                                {item?.owner_account_balance ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                            position: "absolute",
                                            top: "-8px",
                                            left: "-20px",
                                          },
                                          position: "absolute",
                                          top: "-8px",
                                          left: "-20px",
                                        },
                                      },
                                    }}
                                    title={
                                      "₹" +
                                      handleAmount(
                                        Number(item.owner_account_balance)
                                      )
                                    }
                                  >
                                    {"₹ " +
                                      formatTotalBalance(
                                        Number(item.owner_account_balance),
                                        currency
                                      ).amount}
                                    {item.owner_account_balance &&
                                    formatTotalBalance(
                                      Number(item.owner_account_balance),
                                      currency
                                    ).prefix == "" ? (
                                      <p
                                        style={{
                                          font: "normal normal normal 12px/17px Open Sans",
                                          color: "#dadada",
                                        }}
                                      >
                                        Balance
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          font: "normal normal normal 12px/17px Open Sans",
                                          color: "#dadada",
                                        }}
                                      >
                                        Balance - in{" "}
                                        {
                                          formatTotalBalance(
                                            Number(item.owner_account_balance),
                                            currency
                                          ).prefix
                                        }
                                      </p>
                                    )}
                                  </Tooltip>
                                ) : (
                                  <>
                                    <p>Not Available</p>
                                    <p style={{ color: "#dadada" }}>Balance</p>
                                  </>
                                )}
                              </p>

                              <div className="balance_last_div">
                                <div
                                  onClick={(e) => {
                                    if (
                                      !isopen &&
                                      !refreshAll &&
                                      item.connected_banking === "Y"
                                    ) {
                                      e.stopPropagation();
                                      setFailedRefreshId([]);
                                      setSuccessRefreshId([]);
                                      setAccId(item.id);
                                      setPrevId(item.id);
                                      setRefreshtype("single");
                                      setPrevBalance(
                                        item.owner_account_balance
                                      );
                                      handleRefresh(item.id, true);
                                    } else {
                                      e.stopPropagation();
                                    }
                                  }}
                                  className="refresh-balance"
                                >
                                  {ownerAccBalanceLoading &&
                                  accId == item.id ? (
                                    <BalanceLoader
                                      loader={ownerAccBalanceLoading}
                                    />
                                  ) : !ownerAccBalanceLoading &&
                                    !ownerAccBalanceError &&
                                    accId == item.id ? (
                                    <BalanceLoader
                                      loader={ownerAccBalanceLoading}
                                      setAccId={setAccId}
                                    />
                                  ) : (
                                    item.connected_banking === "Y" && (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                  position: "absolute",
                                                  top: "-8px",
                                                  left: "-10px",
                                                  zIndex: "214379",
                                                },
                                              position: "absolute",
                                              top: "-8px",
                                              left: "-10px",
                                              zIndex: "214379",
                                            },
                                          },
                                        }}
                                        title="Refresh"
                                      >
                                        <img
                                          src={Refreshbalancecard}
                                          alt="Refresh"
                                        />
                                      </Tooltip>
                                    )
                                  )}
                                </div>
                                <div
                                  onClick={(e) => {
                                    if (
                                      !isopen &&
                                      item.connected_banking === "Y"
                                    ) {
                                      e.stopPropagation();
                                      setAccData(item);
                                      setStatementId(item.id);
                                      handleViewStatement();
                                    }
                                  }}
                                  className="info-div"
                                >
                                  {item.connected_banking === "Y" && (
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                              position: "absolute",
                                              top: "-8px",
                                              left: "-10px",
                                              zIndex: "214379",
                                            },
                                            position: "absolute",
                                            top: "-8px",
                                            zIndex: "214379",
                                            left: "-10px",
                                          },
                                        },
                                      }}
                                      title="View statement"
                                    >
                                      <img src={Info} alt="Refresh" />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p style={{ paddingLeft: "10px" }} className="noaccountfound">
                  No accounts were found with a negative balance.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ paddingBottom: "100px" }}
          className="ownerCard gap-x-[20px] 2.6xl:gap-x-[20px] 3.1xl:gap-x-[15px] 3.6xl:gap-x-[15px] 4.6xl:gap-x-[15px] 5.6xl:gap-x-[15px] gap-y-[10px] 2.6xl:gap-y-[10px] 3.1xl:gap-y-[15px] 3.6xl:gap-y-[15px] 4.6xl:gap-y-[15px] 5.6xl:gap-y-[15px]"
        >
          {listdata.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{
                  position: "relative",
                  width: "425px",
                  maxWidth: "425px",
                  height: "49px",
                  margin: "3px",
                }}
              >
                <div
                  style={{
                    opacity:
                      typepop === "popup" && item?.connected_banking === "N"
                        ? "0.4"
                        : "1",
                    cursor:
                      typepop === "popup" && item?.connected_banking === "N"
                        ? "default"
                        : "pointer",
                    pointerEvents:
                      typepop === "popup" && item?.connected_banking === "N"
                        ? "none"
                        : "auto",
                  }}
                  onClick={async () => {
                    if (typepop === "popup") {
                      if (flow === "createTxn") {
                        setTxnPayLoad({
                          ...txnPayLoad,
                          ownerId: item?.id,
                          ownerBankIFSC: item?.ifsc_code,
                          ownerBankBalance: item?.owner_account_balance,
                          ownerBankLogo: item?.bank_name,
                          ownerLastUpdated:
                            item?.owner_account_balance_last_fetched_at_in_words,
                          ownerBankAccountNo: item?.bank_acc_no,
                          settleType: "neft",
                        });
                        setIsNeft(true);
                        setIsImps(false);
                        setIsRtgs(false);
                        BenEnableNextButton(false);
                      }
                      setOpenOwner(false);
                    } else {
                      let selection = window.getSelection();
                      if (selection.toString().length === 0) {
                        if (!isopen) {
                          await dispatch(setStoredPage(page));
                          navigate(
                            `/accounts/owner/owner_account_details/${item.id}`
                          );
                        }
                      }
                    }
                  }}
                  key={item.id}
                  className="list-section"
                >
                  <div className="bank-div-img">
                    {
                      <img
                        style={{ backgroundColor: "#fff" }}
                        src={require("../../../../assets/IfscLogo/" +
                          IfscCodeMapper(item.ifsc_code))}
                        alt="bank"
                      />
                    }
                    <div className="onhover">
                      <div
                        style={{
                          marginTop: "4px",
                          display: "flex",
                          width: "422px",
                          height: "43px",
                          borderRadius: "0px 0px 10px 10px",
                          backgroundColor:
                            item.increaseOrDecrease == "increased"
                              ? "#105023"
                              : item.increaseOrDecrease == "decreased"
                              ? "#4F1616"
                              : "#0C2858",
                        }}
                      >
                        <div style={{ width: "69px", minWidth: "69px" }}></div>
                        {successRefreshId.includes(item.id) ? (
                          <div
                            style={{
                              width: "178px",
                              paddingTop: "5px",
                              borderLeft: "2px solid #3D5379",
                              borderRight: "2px solid #3D5379",
                            }}
                          >
                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 12px/17px Open Sans",
                                color:
                                  item.increaseOrDecrease === "increased"
                                    ? "#4DE659"
                                    : item.increaseOrDecrease === "decreased"
                                    ? "#FF5860"
                                    : "#F9F9F9",
                              }}
                            >
                              {
                                formatTotalBalance(item.difference, currency)
                                  .amount
                              }
                            </p>

                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 10px/14px Open Sans",
                                color: "#DADADA",
                              }}
                            >
                              Balance{" "}
                              {item.increaseOrDecrease == ""
                                ? "increased"
                                : item.increaseOrDecrease}{" "}
                              by{" "}
                              {formatTotalBalance(item.difference, currency)
                                .prefix == ""
                                ? ""
                                : "- in " +
                                  formatTotalBalance(item.difference, currency)
                                    .prefix}
                            </p>
                          </div>
                        ) : failedRefreshId.includes(item.id) ? (
                          <p
                            style={{
                              paddingTop: "10px",
                              paddingLeft: "50px",
                              position: "absolute",
                              width: "422px",
                              left: "0px",
                              backgroundColor: "#0C2858",
                              height: "43px",
                              marginTop: "0px",
                              color: "#DADADA",
                              borderRadius: "0px 0px 10px 10px ",
                              boxShadow: "0px 0px -9px 0px #00000029",
                              font: "normal normal normal 12px/17px Open Sans",
                            }}
                          >
                            Currently unable to fetch balance details. Please
                            try again later.
                          </p>
                        ) : (
                          <div
                            style={{
                              width: "178px",
                              borderLeft: "2px solid #3D5379",
                              borderRight: "2px solid #3D5379",
                              paddingTop: "5px",
                            }}
                          >
                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 12px/17px Open Sans",
                                color: "#F9F9F9",
                              }}
                            >
                              {item.ifsc_code}
                            </p>

                            <p
                              style={{
                                padding: "0px 8px",
                                font: "normal normal normal 10px/14px Open Sans",
                                color: "#DADADA",
                              }}
                            >
                              IFSC code
                            </p>
                          </div>
                        )}
                        <div
                          style={{
                            height: "43px",
                            width: "175px",
                            marginTop: "0px",
                            borderRadius: "0px 0px 10px 0px",
                            paddingTop: "5px",
                          }}
                        >
                          <p
                            style={{
                              padding: "0px 30px 0px 0px",
                              font: "normal normal normal 12px/17px Open Sans",
                              color: "#F9F9F9",
                              textAlign: "right",
                            }}
                          >
                            {item.owner_account_balance_last_fetched_at_in_words
                              ? item.owner_account_balance_last_fetched_at_in_words
                              : "Not applicable"}
                          </p>

                          <p
                            style={{
                              padding: "0px 30px 0px 0px",
                              textAlign: "right",
                              font: "normal normal normal 10px/14px Open Sans",
                              color: "#DADADA",
                            }}
                          >
                            Balance updated
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accounts-div">
                    <p
                      style={{
                        padding: "0px 8px",
                        font: "normal normal normal 12px/17px Open Sans",
                      }}
                    >
                      {formatAccountNumber(item.bank_acc_no)}
                    </p>

                    <p
                      className="w-[180px] marquee overflow-hidden whitespace-nowrap overflow-ellipsis font-normal pt-1  text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                      style={{
                        padding: "0px 8px",
                        font: "normal normal normal 10px/14px Open Sans",
                      }}
                    >
                      <p>{item.name}</p>
                    </p>
                  </div>
                  <div>
                    <div className="balance-data-div">
                      {failedRefreshId.includes(item.id) ? (
                        <div
                          className="arrow_animate"
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                            padding: "2px 0px 0px 2px",
                          }}
                        >
                          <img
                            style={{
                              width: "19px",
                              height: "19px",
                              objectFit: "cover",
                            }}
                            src={BalanceErrorIcon}
                            alt="Error"
                          />
                        </div>
                      ) : (
                        <div className="arrow_animate">
                          <AnimationUpAndDown
                            loader={ownerAccBalanceLoading}
                            currentId={item?.id}
                            previousId={
                              refreshType == false
                                ? false
                                : refreshType == "single"
                                ? prevId
                                : true
                            }
                            previousBalance={prevBalance}
                            currentBalance={item?.owner_account_balance}
                            refreshType={refreshType}
                            allPrevData={refreshAllData[index]?.prevbalance}
                          />
                        </div>
                      )}
                      <p
                        style={{
                          font: "normal normal normal 12px/17px Open Sans",
                          textAlign: "right",
                          color:
                            item.owner_account_balance < 0
                              ? "#FF5860"
                              : "#4DE659",
                        }}
                      >
                        {item.owner_account_balance ? (
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                    position: "absolute",
                                    top: "-8px",
                                    left: "-20px",
                                  },
                                  position: "absolute",
                                  top: "-8px",
                                  left: "-20px",
                                },
                              },
                            }}
                            title={
                              "₹" +
                              handleAmount(Number(item.owner_account_balance))
                            }
                          >
                            {"₹ " +
                              formatTotalBalance(
                                Number(item.owner_account_balance),
                                currency
                              ).amount}
                            {item.owner_account_balance &&
                            formatTotalBalance(
                              Number(item.owner_account_balance),
                              currency
                            ).prefix == "" ? (
                              <p
                                style={{
                                  font: "normal normal normal 12px/17px Open Sans",
                                  color: "#dadada",
                                }}
                              >
                                Balance
                              </p>
                            ) : (
                              <p
                                style={{
                                  font: "normal normal normal 12px/17px Open Sans",
                                  color: "#dadada",
                                }}
                              >
                                Balance - in{" "}
                                {
                                  formatTotalBalance(
                                    Number(item.owner_account_balance),
                                    currency
                                  ).prefix
                                }
                              </p>
                            )}
                          </Tooltip>
                        ) : (
                          <>
                            <p>Not Available</p>
                            <p style={{ color: "#dadada" }}>Balance</p>
                          </>
                        )}
                      </p>

                      <div className="balance_last_div">
                        <div
                          onClick={(e) => {
                            if (
                              !isopen &&
                              !refreshAll &&
                              item.connected_banking === "Y"
                            ) {
                              e.stopPropagation();
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              setAccId(item.id);
                              setPrevId(item.id);
                              setRefreshtype("single");
                              setPrevBalance(item.owner_account_balance);
                              handleRefresh(item.id, true);
                            } else {
                              e.stopPropagation();
                            }
                          }}
                          className="refresh-balance"
                        >
                          {ownerAccBalanceLoading && accId == item.id ? (
                            <BalanceLoader loader={ownerAccBalanceLoading} />
                          ) : !ownerAccBalanceLoading &&
                            !ownerAccBalanceError &&
                            accId == item.id ? (
                            <BalanceLoader
                              loader={ownerAccBalanceLoading}
                              setAccId={setAccId}
                            />
                          ) : (
                            item.connected_banking === "Y" && (
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                        position: "absolute",
                                        top: "-8px",
                                        left: "-10px",
                                        zIndex: "214379",
                                      },
                                      position: "absolute",
                                      top: "-8px",
                                      left: "-10px",
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title="Refresh"
                              >
                                <img src={Refreshbalancecard} alt="Refresh" />
                              </Tooltip>
                            )
                          )}
                        </div>
                        <div
                          onClick={(e) => {
                            if (!isopen && item.connected_banking === "Y") {
                              e.stopPropagation();
                              setAccData(item);
                              setStatementId(item.id);
                              handleViewStatement();
                            }
                          }}
                          className="info-div"
                        >
                          {item.connected_banking === "Y" && (
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                      position: "absolute",
                                      top: "-8px",
                                      left: "-10px",
                                      zIndex: "214379",
                                    },
                                    position: "absolute",
                                    top: "-8px",
                                    zIndex: "2147483647 !important",
                                    left: "-10px",
                                  },
                                },
                              }}
                              title="View statement"
                            >
                              <img src={Info} alt="Refresh" />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ListView;
