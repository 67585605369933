import React, { useEffect, useState } from "react";
import DeclinedImage from "../../../../assets/Approve transactions/DeclinedTxn.png";
import DeclinedRed from "../../../../assets/Approve transactions/DeclinedRed_Img.png";
import WhitedashBoard from "../../../../assets/Approve transactions/WhiteDashboard.png";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { Link } from "react-router-dom";

const BeneficiaryMultiApprovalDeclined = () => {
  const [declinedPageData, setdeclinedPageData] = useState([]);
  const [declinedPageID, setdeclinedPageID] = useState([]);

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setdeclinedPageData(storedSelectedApproval?.selectedSendTransactions);
      setdeclinedPageID(storedSelectedApproval.selectedTransactions);
    } else {
      setdeclinedPageData([]);
      setdeclinedPageID([]);
    }

    // return () => localStorage.removeItem("selectedData");
  }, []);
  return (
    <div className="min-h-screen bg-[#754545]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 pt-48">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#754545]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={DeclinedImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">Transactions have been rejected</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-[132px] pb-2">
            <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF]  rounded-[30px] items-center justify-between">
              <div className="h-[90px] w-[472px] bg-[#985555] rounded-tl-[27px] rounded-tr-[27px]">
                <div className="flex justify-center mt-[22px] gap-[1px]">
                  <p className="w-[181px] text-left whitespace-nowrap font-semibold text-[16px] leading-[22px] tracking-[0px] text-[#F9F9F9] opacity-100">
                    {" "}
                    Rejected transactions - {declinedPageID.length}
                  </p>
                </div>
                <div className="flex justify-center mt-[2px]">
                  <p className="w-[284px]  text-center font-normal text-[12px] leading-[17px] tracking-[0px] text-[#DADADA] opacity-100 ">
                    Transactions have been rejected
                  </p>
                </div>
              </div>
              {/* ----------------------- */}
              <div
                className="h-[341px] w-[472px]  flex  justify-center  rounded-tl-[27px] rounded-tr-[27px]"
                style={{
                  overflowY: declinedPageData.length > 5 ? "scroll" : "hidden",
                }}
              >
                <div className="w-[411px] h-[421px]  mt-[10px] ">
                  {declinedPageData?.map((account, i) => {
                    return (
                      <>
                        <div className="flex   mt-[10px]  justify-between">
                          <div className="flex justify-center items-center gap-[10px]">
                            <img
                              className="w-[30px] h-[30px]"
                              src={DeclinedRed}
                              alt=""
                            />
                            <div>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account?.beneficiary_account_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(function (word) {
                                    return (
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                    );
                                  })
                                  .join(" ")}
                              >
                                <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black pb-5">
                                  {account.beneficiary_account_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(function (word) {
                                      return (
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                      );
                                    })
                                    .join(" ")}
                                </p>
                              </Tooltip>
                              <span className="flex items-start  w-[110px] h-[25px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                Beneficiary name
                              </span>
                            </div>
                          </div>
                          <div>
                            <p className="w-[99px] h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#FF5860] opacity-100  min-w-0 whitespace-nowrap">
                              ₹ {<FormatAmount price={account?.amount} />}
                            </p>
                            {/* w-[45px] */}
                            <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                              Amount
                            </span>
                          </div>
                        </div>
                        {i < declinedPageData.length - 1 && (
                          <div
                            style={{
                              marginTop: "10px",
                              border: "0.5px solid #4A664D",
                              height: "0px",
                              width: "411px",
                              opacity: "100",
                            }}
                          ></div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-center items-center h-[136px] w-[472px] bg-[#985555] rounded-bl-[27px] rounded-br-[27px]">
                <Link to={"/beneficiary_transactions"}>
                  <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                    <img
                      className="w-[40px]  h-[40px] "
                      src={WhitedashBoard}
                      alt=""
                    />
                    <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-[#FFFFFF] opacity-100">
                      Go to dashboard
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryMultiApprovalDeclined;
