import { configureStore } from "@reduxjs/toolkit";
import refreshTokenReducer from "./features/refreshToken/refreshToken";
import txnDashBoardReducer from "./features/txnDashboard/txnDashboard";
import txnViewAllReducer from "./features/txnViewAll/txnViewAll";
import loginReducer from "./features/login/login";
import accountsReducer from "./features/accounts/accounts";
import bufferStateReducer from "./features/bufferState/bufferState";
import verifyPANReducer from "./features/verifyPAN/verifyPAN";
import createVendorAccReducer from "./features/createVendorAcc/createVendorAcc";
import createTxnReducer from "./features/createTxn/createTxn";
import pendingTxnReducer from "./features/pendingTxn/pendingTxn";
import approveTxnReducer from "./features/approveTxn/approveTxn";
import viewAccountDetailsReducer from "./features/viewAccountDetails/viewAccountDetails";
import instantPayBalanceReducer from "./features/instantPayBalance/instantPayBalance";
import settleTxnReducer from "./features/settleTxn/settleTxn";
import txnDetailsReducer from "./features/txnDetails/txnDetails";
import txnTimelineReducer from "./features/txnTimeline/txnTimeline";
import createClientAccReducer from "./features/createClientAcc/createClientAcc";
import createReimbusrementAccReducer from "./features/createReimbusrementAcc/createReimbusrementAcc";
import ffLimitsReducer from "./features/ffLimits/ffLimits";
import clientRefundsReducer from "./features/clientRefunds/clientRefunds";
import dateReducer from "./features/date/date";
import hideBreadCrumbReducer from "./features/hideBreadCrumb/hideBreadCrumb";
import initiateEmailReducer from "./features/initiateEmail/initiateEmail";
import addStatusReducer from "./features/addStatus/addStatus";
import convertToInstantPayReducer from "./features/convertToInstantPay/convertToInstantPay";
import deleteTxnReducer from "./features/deleteTxn/deleteTxn";
import verification from "./features/verification/verification";
import AuthMatrix from "./features/Settings/Authorization/index";
import entitiesReducer from "./features/Settings/Management/entities/index";
import initiateRefundReducer from "./features/initiateRefund/initiateRefund";
import panVerifyReducer from "./features/accounts/verification/panVerify";
import homeDashboard from "./features/HomeDashboard/homeDashboard";
import userFeatures from "./features/UserFeatures/userFeatures";
import utilitiesReducer from "./features/Settings/Utilies/index";
import pendingAccountReducer from "./features/accounts/Vendor/Approval/pendingAccount";
import approveAccountReducer from "./features/accounts/Vendor/Approval/approveAccount";
import dashboardReducer from "./features/accounts/dashboard";
import accountViewAllReducer from "./features/accounts/viewAll";
import cinReducer from "./features/Settings/CIN/index";
import rolesReducer from "./features/Settings/Management/Roles/index";
import featuresReducer from "./features/Settings/Management/Features/index";
import servicesReducer from "./features/Settings/Management/Services/index";
import usersReducer from "./features/Settings/Management/Users/index";
import balanceDetailsReducer from "./features/accounts/Owner/fetchBalance";
import beneCreateAccountReducer from "./features/accounts/BeneficiaryAccount/createAccount";
import beneViewAllAccReducer from "./features/beneficiary/beneAllVerification/beneAllVerification";
import beneDetailDenyAccReducer from "./features/beneficiary/beneAllVerification/beneAllVerification";
import beneAllVerificationReducer from "./features/beneficiary/beneAllVerification/beneAllVerification";
import ownerAllVerificationReducer from "./features/owner/ownerAllVerification/ownerAllVerification";
import beneDashboardReducer from "./features/beneficiary/beneAllVerification/beneAllVerification";
import beneRequiredApprovalReducer from "./features/beneficiary/beneAllVerification/beneAllVerification";
import beneViewAccDataReducer from "./features/beneficiary/beneViewAccData/beneViewAccData";
import beneTxnViewAccDataReducer from "./features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import beneTransactionAccountsReducer from "./features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import userSliceReducer from "./features/users/index";
import devicesSlice from "./features/devices/devices";
import getUserDetailsState from "./features/users/userDetails";
import bbpsAccountSlice from "./features/BBPS/index";

export const store = configureStore({
  reducer: {
    refreshToken: refreshTokenReducer,
    txnDashBoard: txnDashBoardReducer,
    txnViewAll: txnViewAllReducer,
    login: loginReducer,
    accounts: accountsReducer,
    bufferState: bufferStateReducer,
    verify: verifyPANReducer,
    createAcc: createVendorAccReducer,
    createTxn: createTxnReducer,
    pendingTxn: pendingTxnReducer,
    approveTxn: approveTxnReducer,
    viewAccountDetails: viewAccountDetailsReducer,
    instantPayBalance: instantPayBalanceReducer,
    settleTxn: settleTxnReducer,
    txnDetails: txnDetailsReducer,
    txnTimeline: txnTimelineReducer,
    createAllAcc: createClientAccReducer,
    createReimbusrementAcc: createReimbusrementAccReducer,
    ffLimits: ffLimitsReducer,
    clientRefunds: clientRefundsReducer,
    date: dateReducer,
    hideBreadCrumb: hideBreadCrumbReducer,
    initiateEmailState: initiateEmailReducer,
    addStatusState: addStatusReducer,
    toInstantPayState: convertToInstantPayReducer,
    deleteTxn: deleteTxnReducer,
    verification: verification,
    pendingAccount: pendingAccountReducer,
    approveAccount: approveAccountReducer,
    panVerify: panVerifyReducer,
    AuthMatrix: AuthMatrix,
    entities: entitiesReducer,
    initiateRefund: initiateRefundReducer,
    homeDashboard: homeDashboard,
    userFeatures: userFeatures,
    accountDashboard: dashboardReducer,
    accountViewAll: accountViewAllReducer,
    utilities: utilitiesReducer,
    cin: cinReducer,
    users: usersReducer,
    roles: rolesReducer,
    features: featuresReducer,
    services: servicesReducer,
    ownerBalance: balanceDetailsReducer,
    beneCreateAccount: beneCreateAccountReducer,
    beneAllVerification: beneAllVerificationReducer,
    beneViewAllAcc: beneViewAllAccReducer,
    beneDashboardAcc: beneDashboardReducer,
    beneRequiredApprovalAcc: beneRequiredApprovalReducer,
    beneViewAccData: beneViewAccDataReducer,
    beneTxnViewAccData: beneTxnViewAccDataReducer,
    beneDetailDenyAcc: beneDetailDenyAccReducer,
    beneTransactionAccounts: beneTransactionAccountsReducer,
    ownerAllVerification: ownerAllVerificationReducer,
    user: userSliceReducer,
    getUserDetailsinfo: getUserDetailsState,
    devices: devicesSlice,
    bbpsAccountSlice: bbpsAccountSlice,
  },
});
