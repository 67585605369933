import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../../../components/LandingPage/Navbar";
import Footer from "../../../../components/LandingPage/Footer";
import { toast_position } from "../../../../redux/features/Settings/Authorization";

const ViewAllBalances = () => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDarkSectionActive, setIsDarkSectionActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(calc(50% - ${
      currentIndex * (280 + 20) + 140
    }px))`;
    setPausedTransform(currentTransform);
    // alert("hii");
  };
  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
    // alert("hello");
  };

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: isDarkSectionActive ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <Navbar onToggleDarkSection={setIsDarkSectionActive} />

              <Footer />
            </div>
          </div>
        ) : (
          <>
            <Navbar />
            <div className="flex relative mt-[60px] bg-[#1f3969]"></div>

            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewAllBalances;
