import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/ViewAllTransactions/Clear filter.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { get_categories } from "../../../redux/features/HomeDashboard/homeDashboard";
import { checkUsertoken } from "../../../redux/features/login/login";
import { toast } from "react-toastify";
import { Tooltip, tooltipClasses } from "@mui/material";
import { setCurrentMenu } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import defaultCategory from "../../../assets/CategoryFilterIcons/defaultCategory.png";

const PopUpComponent = ({ setShowPopup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoriesList = useSelector(
    (state) => state.homeDashboard.categories_Data
  );

  const getCategories = () => {
    try {
      dispatch(checkUsertoken());
      dispatch(get_categories());
    } catch (error) {
      toast.error("Failed To fetch", toast_position);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="w-full h-full top-0 bg-black bg-opacity-80 fixed z-[9999] flex justify-center items-center ">
      <div
        style={{
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "20px",
        }}
        className="bg-[#153164]  w-[551px] h-auto p-4"
      >
        <div className="flex justify-between items-center p-1">
          <p
            style={{
              color: "#FF7F33",
              font: "normal normal 600 16px/22px Open Sans",
            }}
          >
            Select Beneficiary Category:
          </p>
          <div
            style={{
              background: "#314D7E 0% 0% no-repeat padding-box",
              borderRadius: "8px",
              width: "30px",
              height: "30px",
            }}
            className="cursor-pointer flex justify-center items-center"
            onClick={() => setShowPopup(false)}
          >
            <img
              style={{ color: "#dadada", height: "14px", width: "14px" }}
              src={CloseIcon}
            />
          </div>
        </div>
        <div className="flex flex-wrap mt-2 max-h-[290px] overflow-y-scroll">
          {categoriesList?.categories.map((category) => {
            return (
              <div
                onClick={() => {
                  setShowPopup(false);
                  navigate(
                    "/transaction/beneficiary_transaction/transaction_single_approval",
                    {
                      state: {
                        category: category.name,
                      },
                    }
                  );
                  dispatch(setCurrentMenu("Transactions"));
                }}
                className="hover:bg-[#3A5686] flex flex-col justify-center items-center hover:cursor-pointer"
                style={{
                  borderRadius: "20px",
                  width: "125px",
                  height: "142px",
                }}
              >
                {category.logo_url?.length > 0 ? (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                    src={category.logo_url}
                    alt="Rent"
                  />
                ) : (
                  <img
                    style={{
                      objectFit: "cover",
                      width: "80px",
                      height: "80px",
                    }}
                    src={defaultCategory}
                    alt="Rent"
                  />
                )}

                <p
                  style={{
                    height: "22px",
                    paddingTop: "5px",
                    font: "normal normal normal 16px/26px Open Sans",
                    color: "#fff",
                  }}
                >
                  {category.name.length < 12 ? (
                    category.name
                  ) : (
                    <Tooltip
                      sx={{ zIndex: "2147483647 !important" }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title={category.name}
                    >
                      {category.name.substring(0, 10) + "..."}
                    </Tooltip>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopUpComponent;
