import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import Arrow from "../../../assets/OwnerAccount/arrowdown1.png";
import CheckIcon from "../../../assets/Beneficiary Icons/whitecheckmark.png";

const PaginationComponent = ({
  selectedValue,
  handleSelect,
  data,
  dropdownOptions,
  handlePagination,
  currentPage,
}) => {
  const [isPerPageOpen, setIsPerPageOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleChange = (e, page) => {
    handlePagination(page);
  };

  useEffect(() => {
    const handleDropDown = (e) => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      } else {
        setIsPerPageOpen(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [dropdownRef]);

  return (
    <div className="w-[1024px] max-w-[1024px] mx-auto flex justify-between h-[50px]">
      <div className="flex ">
        <p
          style={{
            height: "16px",
            width: "76px",
            margin: "auto 0",
            color: "#f9f9f9",
            fontSize: "12px",
          }}
        >
          Per page :
        </p>
        <div
          ref={dropdownRef}
          style={{
            height: "26px",
            width: "56px",
            borderRadius: "5px",
            margin: "auto 0",
            position: "relative",
            cursor: "pointer",
          }}
          className="statement-type"
        >
          <div
            className="owner-main-btn"
            onClick={() => {
              setIsPerPageOpen(!isPerPageOpen);
            }}
            style={{
              position: isPerPageOpen ? "absolute" : "relative",
              bottom: "0",
              borderRadius: "5px",
              height: isPerPageOpen ? "95px" : "26px",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "56px",
                top: isPerPageOpen ? "0px" : "36px",
              }}
            ></div>
            <div
              style={{
                padding: "5px",
                top: isPerPageOpen ? "0px" : "30px",
                width: "56px",
              }}
              className="bank_high_to_low_select_scroll"
            >
              {dropdownOptions.map((value) => (
                <div
                  key={value}
                  style={{
                    fontWeight: "600",
                    width: "47px",
                    backgroundColor: selectedValue == value && "#244277",
                  }}
                  className="owner_select_scroll_com"
                  onClick={() => handleSelect(value)}
                >
                  {value}
                  <div
                    style={{
                      width: "56px",
                      display: selectedValue == value ? "flex" : "none",
                      textAlign: "right",
                      justifyContent: "end",
                      paddingRight: "1px",
                    }}
                  >
                    <img
                      style={{
                        height: "13px",
                        width: "11px",
                      }}
                      src={CheckIcon}
                      alt="check"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                height: "26px",
                width: "56px",
                backgroundColor: "#667CA3",
                bottom: "0px",
                top: "auto",
              }}
              className="bank_high_to_low_select_select"
            >
              <span
                style={{
                  color: "#f9f9f9",
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                }}
              >
                {selectedValue}
              </span>
              <img
                src={Arrow}
                alt="Status Icon"
                style={{
                  height: "12px",
                  width: "12px",
                  transform: isPerPageOpen ? "rotate(0deg)" : "rotate(180deg)",
                }}
                className="status_icon-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-auto">
        <Pagination
          sx={{ margin: "0 2px" }}
          size="small"
          color="primary"
          count={data?.title?.total_pages || 1}
          shape="rounded"
          page={Number(currentPage) || 1}
          onChange={handleChange}
        />
      </div>
      <div className="w-auto my-auto">
        <p
          style={{ fontSize: "12px", fontWeight: "600" }}
          className="DataCount_text"
        >
          {data?.title?.page_item_count * data?.title?.current_page -
            data?.title?.page_item_count +
            1 || 0}{" "}
          -{" "}
          {Math.min(
            data?.total_count,
            data?.title?.page_item_count * data?.title?.current_page
          ) || 0}{" "}
          of {data?.total_count?.toLocaleString() || 0}
        </p>
      </div>
    </div>
  );
};

export default PaginationComponent;
