import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/Settings/Dashboard/cancel.png";
import search from "../../../assets/Settings/Dashboard/search.png";
import boxright from "../../../assets/Settings/Dashboard/box_right.png";
import enter_ifsc from "../../../assets/Settings/Verification/enter_ifsc.svg";
import loader from "../../../assets/Settings/Dashboard/loader.gif";
import notFound from "../../../assets/Settings/Dashboard/not_found.png";
import transifsc from "../../../assets/Settings/Dashboard/trans_ifsc.png";
import din from "../../../assets/Settings/Dashboard/din_blue.png";
import {
  verifyThisDIN,
  resetSelectedDin,
  selectedCin,
  verifyThisCIN,
  resetGetDinData,
  resetGetDinError,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { countryDemonyms } from "./countryCodes";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DINVerification = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(0);
  const services = useSelector((state) => state.refreshToken.services);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${day} ${month} ${year}`;
  };

  const DateConverter = ({ date }) => {
    const formattedDate = convertDate(date);
    return <div>{formattedDate}</div>;
  };
  const dispatch = useDispatch();
  const [dinNo, setDinNo] = useState("");
  const specialRegex = /^[a-zA-Z0-9]*$/;
  const handleDin = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetGetDinData());
      dispatch(resetSelectedDin());
    }
    if (e.target.value.length > 8 || isNaN(e.target.value)) {
      return;
    }
    if (!specialRegex.test(e.target.value)) {
      return;
    }
    setDinNo(e.target.value.toUpperCase());
  };

  const dinRegex = /^\d{8}$/;
  const inputRef = useRef("");
  const { verifyDIN, verifyDINisLoading, verifyDINError, selectedDinData } =
    useSelector((state) => state.verify);

  useEffect(() => {
    if (services && !services?.includes("VDIN")) {
      navigate("/feature_not_assigned");
    }

    if (selectedDinData) {
      setDinNo(selectedDinData);
    }

    return () => {
      dispatch(resetSelectedDin());
      dispatch(resetGetDinData());
    };
  }, []);

  const handleDinClick = () => {
    if ((!dinRegex.test(dinNo) && dinNo.length === 8) || dinNo.length < 8) {
      dispatch(resetGetDinError());
      toast.error("Invalid DIN", toast_position);
    } else if (dinNo.length === 8) {
      dispatch(checkUsertoken());
      dispatch(resetGetDinData());
      dispatch(resetSelectedDin());
      dispatch(verifyThisDIN({ search_string: dinNo }));
    }
    setActiveButton(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && dinNo.length === 8) {
      handleDinClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dinNo]);

  return (
    <div className="cin_main_div">
      <div className="cin_search">
        <div className="cin_search_1024">
          <div className="cin_input_div">
            <input
              ref={inputRef}
              value={dinNo}
              onChange={handleDin}
              placeholder="Enter DIN number"
            />
            {dinNo.length === 0 ? (
              <img
                alt="search"
                src={search}
                onClick={() => inputRef.current.focus()}
              />
            ) : (
              <img
                alt="cancel"
                src={cancel}
                onClick={() => {
                  setDinNo("");
                  dispatch(resetGetDinData());
                  dispatch(resetSelectedDin());
                }}
              />
            )}
          </div>
          <Button
            className="cin_fetch_button"
            disabled={dinNo.length !== 8}
            onClick={handleDinClick}
            style={{
              color: dinNo.length === 8 ? "#1d3a6d" : "#F9F9F9",
              background: dinNo.length === 8 ? "#f9f9f9" : "#707070",
            }}
          >
            Fetch Details
          </Button>
        </div>
      </div>
      {verifyDINisLoading ? (
        <div className="cin_loading_loader">
          <img
            src={loader}
            alt="inputLoader"
            style={{
              width: "60px",
              height: "42px",
              maxWidth: "60px",
              maxHeight: "42px",
            }}
          />
        </div>
      ) : !verifyDINisLoading && verifyDIN?.status ? (
        <>
          <div className="cin_details_din">
            <div className="cin_details_1024">
              <div className="cin_details_1">
                <div className="cin_details_1_right_cin_no">
                  <h4 className="lowercase first-line:capitalize">
                    {verifyDIN?.din_details?.full_name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </h4>
                  <h6>Director name</h6>
                </div>
                <div className="cin_details_1_right">
                  <div className="cin_details_1_right_cin_no">
                    <h4>{verifyDIN?.din_details?.din_number}</h4>
                    <h6>DIN number</h6>
                  </div>
                  <div className="cin_img_div">
                    <img src={din} alt="cin" />
                  </div>
                </div>
              </div>
              <div className="cin_details_1" style={{ borderBottom: "none" }}>
                <div className="cin_details_1_right_din">
                  <div className="cin_details_1_right_cin_no_din">
                    <h4>
                      {countryDemonyms[verifyDIN?.din_details?.nationality]}
                    </h4>
                    <h6>Nationality</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cin_tabs">
            <div className="cin_tabs_1024">
              <div className="cin_tabs_button">
                <Button
                  className="cin_tabs_button_1"
                  style={{
                    background: activeButton === 0 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 0 ? "#3A3649" : "#F9F9F9",
                    font:
                      activeButton === 0
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  onClick={() => {
                    setActiveButton(0);
                  }}
                >
                  Details
                </Button>
                <Button
                  style={{
                    background: activeButton === 1 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 1 ? "#3A3649" : "#F9F9F9",
                    font:
                      activeButton === 1
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  className="cin_tabs_button_1"
                  onClick={() => {
                    setActiveButton(1);
                  }}
                >
                  Corporate Profile
                </Button>
              </div>

              {activeButton === 0 ? (
                <div className="cin_details_slide">
                  <div className="cin_details_slide_con_cap_din">
                    <h4>More Info</h4>
                    <div className="cin_details_slide_con_cap_customer_no">
                      <h5 id="email-id-firstLetter">
                        {verifyDIN?.din_details?.email}
                      </h5>
                      <h6>Email ID</h6>
                    </div>
                    <div
                      className="cin_details_slide_con_cap_email"
                      style={{ borderBottom: "1px solid #1d3a6d" }}
                    >
                      <h5 className="lowercase first-line:capitalize">
                        {verifyDIN?.din_details?.father_name
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}{" "}
                      </h5>
                      <h6>Father name</h6>
                    </div>
                    <div className="cin_details_slide_con_cap_date">
                      <h5>
                        <DateConverter date={verifyDIN?.din_details?.dob} />
                      </h5>
                      <h6>Date of birth</h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="cin_directors_slide"
                  style={{
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent:
                      verifyDIN?.din_details?.cin_details?.length === 0
                        ? "center"
                        : "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {verifyDIN?.din_details?.cin_details?.length === 0 ? (
                    <h3>CIN not found</h3>
                  ) : (
                    <>
                      {verifyDIN?.din_details?.cin_details?.map((data, i) => {
                        return (
                          <div
                            className="cin_directors_box"
                            key={i}
                            onClick={() => {
                              dispatch(selectedCin(data?.cin_number));
                              dispatch(
                                verifyThisCIN({
                                  search_string: data?.cin_number,
                                })
                              );
                              dispatch(resetGetDinData());
                              navigate("/settings/CIN_Verification");
                              dispatch(checkUsertoken());
                            }}
                          >
                            <div className="cin_directors_box_img">
                              <img src={transifsc} alt="user" />
                              <h4 className="lowercase first-line:capitalize">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          textTransform: "capitalize",
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={data?.company_name}
                                >
                                  {data?.company_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </Tooltip>
                              </h4>
                            </div>
                            <div className="cin_directors_box_details">
                              <div className="cin_directors_box_details_left">
                                <h5>{data?.cin_number}</h5>
                                <h6>CIN number</h6>
                              </div>
                              <img alt="right" src={boxright} />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : !verifyDINisLoading && verifyDINError === true ? (
        <div className="cin_not_found">
          <img src={notFound} alt="notfound" />
          <p>
            No directors found.
            <br /> Please check the DIN number entered above.
          </p>
        </div>
      ) : (
        <div className="cin_default_img">
          <img src={enter_ifsc} alt="bgDefault" />
          <p>
            Enter DIN number in the search bar, to fetch the verified corporate
            profile details.
          </p>
        </div>
      )}
    </div>
  );
};

export default DINVerification;
